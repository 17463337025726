import { Rocket, Building2, Mic2, Pencil, Lightbulb, Coffee } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

const partners = [
  {
    title: "Startups",
    icon: Rocket,
    color: "from-pink-500 to-rose-500",
    description: "Launch your journey with us",
    image: "https://images.unsplash.com/photo-1519389950473-47ba0277781c?auto=format&fit=crop&w=800&q=80",
    link: "/partner/startup"
  },
  {
    title: "VCs",
    icon: Building2,
    color: "from-purple-500 to-indigo-500",
    description: "Discover promising opportunities",
    image: "https://images.unsplash.com/photo-1605810230434-7631ac76ec81?auto=format&fit=crop&w=800&q=80",
    link: "/partner/vc"
  },
  {
    title: "Artists",
    icon: Mic2,
    color: "from-blue-500 to-cyan-500",
    description: "Share your creative voice",
    image: "https://images.unsplash.com/photo-1511379938547-c1f69419868d?auto=format&fit=crop&w=800&q=80",
    link: "/partner/artist"
  },
  {
    title: "Bloggers",
    icon: Pencil,
    color: "from-green-500 to-emerald-500",
    description: "Tell compelling stories",
    image: "https://images.unsplash.com/photo-1486312338219-ce68d2c6f44d?auto=format&fit=crop&w=800&q=80",
    link: "/partner/blogger"
  },
  {
    title: "Accelerators",
    icon: Lightbulb,
    color: "from-yellow-500 to-amber-500",
    description: "Fuel innovation and growth",
    image: "https://images.unsplash.com/photo-1487058792275-0ad4aaf24ca7?auto=format&fit=crop&w=800&q=80",
    link: "/partner/accelerator"
  },
  {
    title: "Incubators",
    icon: Coffee,
    color: "from-orange-500 to-red-500",
    description: "Nurture great ideas",
    image: "https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?auto=format&fit=crop&w=800&q=80",
    link: "/partner/incubator"
  }
];

const Partners = () => {
  return (
    <section className="mt-32 space-y-12">
      <div className="text-center space-y-4">
        <h2 className="text-4xl font-bold text-hover-espresso">
          Partner With Us!
        </h2>
        <p className="text-hover-coffee text-lg max-w-2xl mx-auto">
          We empower communities and bring their vision to a larger audience!
        </p>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 sm:gap-6 relative">
        {partners.map((partner, index) => {
          const Icon = partner.icon;
          return (
            <div
              key={partner.title}
              className={`group relative p-6 rounded-2xl overflow-hidden transition-all duration-300 hover:-translate-y-2 hover:shadow-xl sm:${
                index % 2 === 0 ? 'transform translate-y-4' : ''
              }`}
              style={{
                background: '#4A3023',
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
              }}
            >
              <div className="absolute inset-0">
                <img 
                  src={partner.image} 
                  alt={partner.title}
                  className="w-full h-full object-cover opacity-40 group-hover:opacity-50 transition-opacity"
                />
                <div className={`absolute inset-0 bg-gradient-to-br ${partner.color} opacity-10 group-hover:opacity-20 transition-opacity`} />
              </div>
              <div className="relative z-10 space-y-4">
                <div className="w-12 h-12 rounded-xl bg-white/90 flex items-center justify-center shadow-lg">
                  <Icon className="w-6 h-6 text-hover-coffee" />
                </div>
                <h3 className="text-xl font-semibold text-white">
                  {partner.title}
                </h3>
                <p className="text-white/80">
                  {partner.description}
                </p>
                <Button
                  variant="outline"
                  className="w-full bg-white/10 border-white/20 text-white hover:bg-white hover:text-hover-coffee transition-colors"
                  asChild
                >
                  <Link to={partner.link}>Learn More</Link>
                </Button>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Partners;