import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Avatar } from '@/components/ui/avatar';
import { UserPlus, Linkedin } from 'lucide-react';

interface SuggestionCardProps {
  id: string;
  name: string;
  company_name: string;
  profile_image_url: string | null;
  linkedin_url: string | null;
  onConnect: (userId: string) => void;
}

export const SuggestionCard = ({
  id,
  name,
  company_name,
  profile_image_url,
  linkedin_url,
  onConnect,
}: SuggestionCardProps) => {
  return (
    <Card className="p-4 transition-all duration-300 hover:shadow-md mb-3">
      <div className="flex flex-col sm:flex-row sm:items-center gap-4">
        <div className="flex items-center gap-4 min-w-0">
          <Avatar className="h-12 w-12 shrink-0">
            <img
              src={profile_image_url || '/placeholder.svg'}
              alt={name}
              className="object-cover"
            />
          </Avatar>
          <div className="min-w-0 flex-1">
            <h4 className="font-medium text-base truncate">{name}</h4>
            <p className="text-sm text-muted-foreground truncate">
              {company_name}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-2 justify-end sm:justify-start shrink-0">
          {linkedin_url && (
            <Button
              size="icon"
              variant="ghost"
              className="hover:text-[#0A66C2] h-9 w-9"
              asChild
            >
              <a href={linkedin_url} target="_blank" rel="noopener noreferrer">
                <Linkedin className="h-4 w-4" />
              </a>
            </Button>
          )}
          <Button
            size="sm"
            onClick={() => onConnect(id)}
            className="whitespace-nowrap"
          >
            <UserPlus className="h-4 w-4 mr-2" />
            Connect
          </Button>
        </div>
      </div>
    </Card>
  );
};