import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ScrollArea } from "@/components/ui/scroll-area";
import { MessagesSquare, ChevronUp, ChevronDown } from "lucide-react";
import { useUserRole } from "@/hooks/useUserRole";
import UserSearch from "@/components/myspace/coffee-beans/UserSearchDialog";
import DirectChat from "@/components/myspace/chat/DirectChat";
import { CoffeeBeanUser } from "@/types/coffee-beans";
import { useUserSearch } from "@/components/myspace/coffee-beans/hooks/useUserSearch";

const ChatMonitoringCard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [fromUser, setFromUser] = useState<CoffeeBeanUser[]>([]);
  const [toUser, setToUser] = useState<CoffeeBeanUser[]>([]);
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const { isAdmin } = useUserRole();
  const { users, isLoading } = useUserSearch();

  if (!isAdmin) return null;

  const handleBack = () => {
    setFromUser([]);
    setToUser([]);
  };

  return (
    <Card className="bg-white border border-hover-coffee/10 transition-all duration-300 hover:shadow-md">
      <Collapsible
        open={!isCollapsed}
        onOpenChange={(open) => setIsCollapsed(!open)}
      >
        <CollapsibleTrigger asChild>
          <div className="w-full flex items-center justify-between text-hover-coffee py-6 px-4 md:py-8 md:px-6 cursor-pointer hover:bg-hover-cream/50 transition-colors duration-200">
            <div className="flex items-center gap-2 md:gap-3">
              <div className="flex gap-2 md:gap-3">
                <MessagesSquare className="h-5 w-5 transition-transform duration-200 hover:scale-110" />
              </div>
              <span className="font-semibold text-base md:text-lg">Chat Monitoring</span>
            </div>
            {isCollapsed ? (
              <ChevronDown className="h-5 w-5" />
            ) : (
              <ChevronUp className="h-5 w-5" />
            )}
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent className="animate-slide-up">
          <div className="p-4 md:p-6 pt-0">
            <ScrollArea className="h-[600px] w-full rounded-md border border-hover-coffee/10 bg-white shadow-sm">
              {!fromUser.length || !toUser.length ? (
                <div className="p-4 space-y-4">
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-hover-coffee">Select First User</label>
                    <UserSearch
                      users={users}
                      selectedUser={fromUser[0] || null}
                      onUserSelect={setFromUser}
                      searchQuery={searchQuery1}
                      onSearchChange={setSearchQuery1}
                      isLoading={isLoading}
                    />
                  </div>
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-hover-coffee">Select Second User</label>
                    <UserSearch
                      users={users}
                      selectedUser={toUser[0] || null}
                      onUserSelect={setToUser}
                      searchQuery={searchQuery2}
                      onSearchChange={setSearchQuery2}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              ) : (
                <div className="h-full">
                  <DirectChat
                    recipientId={toUser[0].id}
                    onBack={handleBack}
                    overrideUserId={fromUser[0].id}
                  />
                </div>
              )}
            </ScrollArea>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
};

export default ChatMonitoringCard;