import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import type { GroupMessage } from "@/types/group";

export const useGroupMessages = (groupId: number | null) => {
  const [messages, setMessages] = useState<GroupMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const MESSAGES_PER_PAGE = 50;

  const fetchMessages = async (startIndex: number = 0) => {
    if (!groupId) return;

    try {
      const { data, error, count } = await supabase
        .from('group_messages')
        .select(`
          id,
          content,
          created_at,
          group_id,
          user_id,
          user:users(
            id,
            name,
            email,
            company_name,
            user_type,
            created_at,
            profile_image_url
          )
        `, { count: 'exact' })
        .eq('group_id', groupId)
        .order('created_at', { ascending: false })
        .range(startIndex, startIndex + MESSAGES_PER_PAGE - 1);

      if (error) throw error;

      const newMessages = data as GroupMessage[];
      
      // If we're loading more messages (not the initial load)
      if (startIndex > 0) {
        setMessages(prev => [...prev, ...newMessages.reverse()]);
      } else {
        setMessages(newMessages.reverse());
      }

      // Check if there are more messages to load
      setHasMore(count ? startIndex + MESSAGES_PER_PAGE < count : false);
    } catch (err) {
      console.error('Error fetching messages:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!groupId) {
      setMessages([]);
      setLoading(false);
      return;
    }

    // Reset state when group changes
    setMessages([]);
    setHasMore(true);
    setLoading(true);
    
    // Fetch initial messages
    fetchMessages(0);

    // Subscribe to new messages
    const channel = supabase
      .channel(`messages-${groupId}`)
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'group_messages',
        filter: `group_id=eq.${groupId}`
      }, async (payload) => {
        const { data: newMessage, error } = await supabase
          .from('group_messages')
          .select(`
            id,
            content,
            created_at,
            group_id,
            user_id,
            user:users(
              id,
              name,
              email,
              company_name,
              user_type,
              created_at,
              profile_image_url
            )
          `)
          .eq('id', payload.new.id)
          .single();

        if (!error && newMessage) {
          setMessages(prev => [...prev, newMessage as GroupMessage]);
        }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [groupId]);

  const loadMoreMessages = () => {
    if (!loading && hasMore) {
      fetchMessages(messages.length);
    }
  };

  return { messages, loading, hasMore, loadMoreMessages };
};