import { useState, useEffect } from "react";
import { useDirectMessages } from "@/hooks/useDirectMessages";
import { useMessageSender } from "./hooks/useMessageSender";
import { MessageProvider, useMessages } from "./context/MessageContext";
import { useRealtimeChat } from "./hooks/useRealtimeChat";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import ChatHeader from "./ChatHeader";
import MessageReadStatus from "./MessageReadStatus";
import { supabase } from "@/integrations/supabase/client";
import { Toaster } from "@/components/ui/toaster";

interface DirectChatProps {
  recipientId: string;
  onBack: () => void;
  overrideUserId?: string;
}

const DirectChatContent = ({ recipientId, onBack, overrideUserId }: DirectChatProps) => {
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const [recipientName, setRecipientName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const { messages: initialMessages, loading, hasMore, loadMore } = useDirectMessages(recipientId, overrideUserId);
  const { addMessage, setInitialMessages } = useMessages();

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        if (overrideUserId) {
          setCurrentUserId(overrideUserId);
          return;
        }

        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError) {
          console.error("[DirectChat] Auth error:", authError);
          return;
        }
        
        if (user) {
          const { data: userData, error: dbError } = await supabase
            .from('users')
            .select('id')
            .eq('email', user.email)
            .single();
          
          if (dbError) {
            console.error("[DirectChat] Database error:", dbError);
            return;
          }
          
          if (userData) {
            console.log("[DirectChat] Current user ID set:", userData.id);
            setCurrentUserId(userData.id);
          }
        }
      } catch (error) {
        console.error("[DirectChat] Error in getCurrentUser:", error);
      }
    };

    getCurrentUser();
  }, [overrideUserId]);

  useEffect(() => {
    if (initialMessages && initialMessages.length > 0) {
      console.log("[DirectChat] Setting initial messages:", {
        count: initialMessages.length,
        firstMessage: initialMessages[0],
        lastMessage: initialMessages[initialMessages.length - 1]
      });
      setInitialMessages(initialMessages);
    }
  }, [initialMessages, setInitialMessages]);

  // Fetch recipient details
  useEffect(() => {
    const fetchRecipientDetails = async () => {
      if (!recipientId) return;

      try {
        const { data: userData, error } = await supabase
          .from('users')
          .select('name, company_name')
          .eq('id', recipientId)
          .single();

        if (error) {
          console.error("[DirectChat] Error fetching recipient details:", error);
          return;
        }

        if (userData) {
          setRecipientName(userData.name);
          setCompanyName(userData.company_name);
        }
      } catch (err) {
        console.error("[DirectChat] Error in fetchRecipientDetails:", err);
      }
    };

    fetchRecipientDetails();
  }, [recipientId]);

  const { sendMessage } = useMessageSender(currentUserId, recipientId, addMessage);

  // Set up real-time chat subscription
  useRealtimeChat(currentUserId, recipientId, addMessage);

  if (!currentUserId) {
    return <div className="flex items-center justify-center h-full">Loading...</div>;
  }

  return (
    <div className="flex flex-col h-full">
      <ChatHeader 
        recipientId={recipientId} 
        recipientName={recipientName}
        companyName={companyName}
        onBack={onBack} 
      />
      <MessageReadStatus currentUserId={currentUserId} recipientId={recipientId} />
      <div className="flex-1 min-h-0">
        <MessageList
          loading={loading}
          hasMore={hasMore}
          onLoadMore={loadMore}
        />
      </div>
      <MessageInput onSendMessage={sendMessage} />
      <Toaster />
    </div>
  );
};

const DirectChat = (props: DirectChatProps) => {
  return (
    <MessageProvider>
      <DirectChatContent {...props} />
    </MessageProvider>
  );
};

export default DirectChat;
