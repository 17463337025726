import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Coffee, Rocket, Users, Target, ArrowRight } from "lucide-react";

const IncubatorPartner = () => {
  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <div className="space-y-16">
          {/* Hero Section */}
          <section className="text-center space-y-6">
            <h1 className="text-5xl font-bold text-hover-espresso">
              Nurture Innovation Together
            </h1>
            <p className="text-xl text-hover-coffee max-w-2xl mx-auto">
              Partner with us to enhance your incubator's impact and connect with innovative startups and entrepreneurs.
            </p>
            <div className="flex justify-center gap-4">
              <Button size="lg" className="bg-hover-coffee hover:bg-hover-espresso">
                <Link to="/register">Join Now</Link>
              </Button>
              <Button size="lg" variant="outline" className="border-hover-coffee text-hover-coffee hover:bg-hover-coffee hover:text-white">
                <Link to="/contact">Contact Us</Link>
              </Button>
            </div>
          </section>

          {/* Benefits Section */}
          <section className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Target className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Quality Startups</h3>
              <p className="text-hover-coffee">Connect with promising early-stage startups that align with your incubator's mission.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Users className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Expert Network</h3>
              <p className="text-hover-coffee">Access our network of mentors and industry experts to support your portfolio companies.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Rocket className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Program Visibility</h3>
              <p className="text-hover-coffee">Showcase your incubator programs to a wider audience of qualified entrepreneurs.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Coffee className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Resource Exchange</h3>
              <p className="text-hover-coffee">Share and access resources with other incubators to strengthen your offerings.</p>
            </div>
          </section>

          {/* CTA Section */}
          <section className="text-center space-y-6 bg-hover-coffee text-white p-12 rounded-2xl">
            <h2 className="text-3xl font-bold">Ready to Enhance Your Incubator's Reach?</h2>
            <p className="text-lg opacity-90 max-w-2xl mx-auto">
              Join our premium community today and connect with promising startups, fellow incubators, and resources to amplify your impact.
            </p>
            <Button size="lg" className="bg-white text-hover-coffee hover:bg-hover-cream">
              <Link to="/register" className="flex items-center gap-2">
                Get Started <ArrowRight className="w-4 h-4" />
              </Link>
            </Button>
          </section>
        </div>
      </main>
    </div>
  );
};

export default IncubatorPartner;