import { ScrollArea } from "@/components/ui/scroll-area";
import ChatPreviewItem from "./ChatPreviewItem";
import { useChatPreviews } from "./hooks/useChatPreviews";
import { supabase } from "@/integrations/supabase/client";

interface DirectMessageListProps {
  onUserSelect: (userId: string) => void;
  unreadMessages: Set<string>;
}

const DirectMessageList = ({ onUserSelect, unreadMessages }: DirectMessageListProps) => {
  const { chatPreviews, loading, error } = useChatPreviews();

  const handleUserSelect = async (userId: string) => {
    if (unreadMessages.has(userId)) {
      // Mark all messages from this user as read
      const { error: updateError } = await supabase
        .from('direct_messages')
        .update({ read: true })
        .eq('sender_id', userId)
        .eq('read', false);

      if (updateError) {
        console.error('Error marking messages as read:', updateError);
      }
    }
    onUserSelect(userId);
  };

  if (loading) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-muted-foreground">Loading chats...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="text-destructive">Error loading chats. Please try again.</div>
      </div>
    );
  }

  return (
    <ScrollArea className="h-full">
      <div className="px-1 py-2">
        {chatPreviews && chatPreviews.length > 0 ? (
          chatPreviews.map((preview) => (
            <ChatPreviewItem
              key={preview.connectionId}
              preview={preview}
              hasUnread={unreadMessages.has(preview.connectionId)}
              onClick={() => handleUserSelect(preview.connectionId)}
            />
          ))
        ) : (
          <div className="text-center text-muted-foreground p-4">
            No conversations yet. Connect with others to start chatting!
          </div>
        )}
      </div>
    </ScrollArea>
  );
};

export default DirectMessageList;