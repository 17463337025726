import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";

interface EditOfferDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  offer: {
    id: number;
    title: string;
    description: string;
    affiliate_link?: string;
    coffee_beans_reward: number;
  };
}

const EditOfferDialog = ({ open, onOpenChange, offer }: EditOfferDialogProps) => {
  const [title, setTitle] = useState(offer.title);
  const [description, setDescription] = useState(offer.description);
  const [affiliateLink, setAffiliateLink] = useState(offer.affiliate_link || "");
  const [reward, setReward] = useState(offer.coffee_beans_reward.toString());
  const { toast } = useToast();
  const queryClient = useQueryClient();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const { error } = await supabase
        .from('bean_mining_offers')
        .update({
          title,
          description,
          affiliate_link: affiliateLink,
          coffee_beans_reward: parseInt(reward),
        })
        .eq('id', offer.id);

      if (error) {
        console.error('Error updating offer:', error);
        toast({
          title: "Error",
          description: error.message || "Failed to update offer",
          variant: "destructive",
        });
        return;
      }

      toast({
        title: "Success",
        description: "Offer updated successfully",
      });

      // Refresh the offers list
      queryClient.invalidateQueries({ queryKey: ['bean-mining-offers'] });
      onOpenChange(false);
    } catch (err) {
      console.error('Error in handleSubmit:', err);
      toast({
        title: "Error",
        description: "An unexpected error occurred",
        variant: "destructive",
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Bean Mining Offer</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <label className="text-sm font-medium">Title</label>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Enter offer title"
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Description</label>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter offer description"
              required
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Affiliate Link</label>
            <Input
              value={affiliateLink}
              onChange={(e) => setAffiliateLink(e.target.value)}
              placeholder="Enter affiliate link (optional)"
              type="url"
            />
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium">Coffee Beans Reward</label>
            <Input
              value={reward}
              onChange={(e) => setReward(e.target.value)}
              placeholder="Enter reward amount"
              type="number"
              min="1"
              required
            />
          </div>
          <div className="flex justify-end gap-2">
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
            <Button type="submit">Update Offer</Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditOfferDialog;