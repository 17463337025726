import React, { useState } from "react";
import { Plus } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useUserRole } from "@/hooks/useUserRole";
import { Input } from "@/components/ui/input";
import UserSearchDialog from "./UserSearchDialog";
import { CoffeeBeanUser } from "@/types/coffee-beans";
import { useUserSearch } from "./hooks/useUserSearch";

interface CoffeeBeansBalanceProps {
  coffeeBeansCount: number;
}

const CoffeeBeansBalance = ({ coffeeBeansCount }: CoffeeBeansBalanceProps) => {
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<CoffeeBeanUser[]>([]);
  const [beansToAdd, setBeansToAdd] = useState("");
  const { users, isLoading } = useUserSearch();
  const { isAdmin } = useUserRole();
  const { toast } = useToast();

  const handleOpenChange = (newOpen: boolean) => {
    console.log("[CoffeeBeansBalance] Dialog open state changing to:", newOpen);
    setOpen(newOpen);
    
    if (!newOpen) {
      console.log("[CoffeeBeansBalance] Closing dialog, resetting state");
      setSelectedUsers([]);
      setBeansToAdd("");
      setSearchQuery("");
    }
  };

  const handleUpdateBeans = async () => {
    if (selectedUsers.length === 0 || !beansToAdd) {
      console.log("[CoffeeBeansBalance] Invalid input:", { selectedUsers, beansToAdd });
      toast({
        title: "Invalid input",
        description: "Please select at least one user and enter an amount",
        variant: "destructive",
      });
      return;
    }

    const beansChange = parseInt(beansToAdd);
    if (isNaN(beansChange)) {
      console.log("[CoffeeBeansBalance] Invalid beans amount:", beansToAdd);
      toast({
        title: "Invalid input",
        description: "Please enter a valid number",
        variant: "destructive",
      });
      return;
    }

    try {
      // Update beans for all selected users
      for (const user of selectedUsers) {
        console.log('[CoffeeBeansBalance] Updating beans for user:', {
          userId: user.id,
          amount: beansChange,
          currentBeans: user.coffee_beans
        });
        
        const { error } = await supabase
          .from('users')
          .update({ 
            coffee_beans: (user.coffee_beans || 0) + beansChange 
          })
          .eq('id', user.id);

        if (error) {
          console.error('[CoffeeBeansBalance] Error updating beans for user:', user.id, error);
          throw error;
        }
      }

      console.log('[CoffeeBeansBalance] Successfully updated beans for all users');
      toast({
        title: "Success",
        description: `Updated coffee beans for ${selectedUsers.length} user${selectedUsers.length > 1 ? 's' : ''}`,
      });

      setOpen(false);
      setSelectedUsers([]);
      setBeansToAdd("");
    } catch (err) {
      console.error('[CoffeeBeansBalance] Error in handleUpdateBeans:', err);
      toast({
        title: "Error",
        description: "Could not update coffee beans. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div>
      <div className="text-2xl font-bold text-hover-espresso flex items-center gap-2 mt-4">
        {coffeeBeansCount}
        <img
          src="/lovable-uploads/e82b1634-13ba-4bf6-bea1-b619790e086a.png"
          alt="Coffee Bean"
          className="h-10 w-10 text-hover-coffee rotate-12"
        />
        {isAdmin && (
          <Button
            variant="ghost"
            size="icon"
            className="ml-2"
            onClick={() => setOpen(true)}
          >
            <Plus className="h-5 w-5 text-hover-coffee" />
          </Button>
        )}
      </div>
      <p className="text-sm text-hover-coffee">Available Balance</p>
      <div className="h-2 bg-hover-foam rounded-full mt-2">
        <div
          className="h-2 bg-hover-coffee rounded-full transition-all duration-500 ease-in-out"
          style={{ width: `${(coffeeBeansCount / 1000) * 100}%` }}
        />
      </div>

      <Dialog open={open} onOpenChange={handleOpenChange}>
        <DialogContent className="sm:max-w-[425px] bg-white">
          <DialogHeader>
            <DialogTitle>Manage Coffee Beans</DialogTitle>
            <DialogDescription>
              Search for users and update their coffee beans balance.
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <UserSearchDialog
              users={users}
              selectedUser={selectedUsers[0] || null}
              onUserSelect={setSelectedUsers}
              searchQuery={searchQuery}
              onSearchChange={setSearchQuery}
              isLoading={isLoading}
            />

            {selectedUsers.length > 0 && (
              <div className="space-y-2">
                <Input
                  type="number"
                  placeholder="Enter amount (negative to reduce)"
                  value={beansToAdd}
                  onChange={(e) => setBeansToAdd(e.target.value)}
                  className="bg-white"
                />
                <Button onClick={handleUpdateBeans} className="w-full">
                  Update Beans for {selectedUsers.length} User{selectedUsers.length > 1 ? 's' : ''}
                </Button>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CoffeeBeansBalance;