import { Coffee } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogTrigger,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";

interface HowToEarnDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const HowToEarnDialog = ({ open, setOpen }: HowToEarnDialogProps) => {
  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button 
          variant="link" 
          className="text-sm text-hover-coffee p-0 h-auto font-medium hover:text-hover-espresso hover:no-underline flex items-center gap-1 mt-2"
        >
          <Coffee className="h-4 w-4" /> How to earn beans?
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className="bg-white p-6 max-w-lg">
        <h3 className="font-bold text-xl text-hover-espresso mb-6">How the Coffee Bean Wallet Works</h3>
        
        <div className="space-y-6">
          <div>
            <h4 className="font-semibold text-hover-espresso">1. Starting Balance</h4>
            <p className="text-sm text-hover-coffee">Upon signing up, your wallet is credited with <span className="font-bold">30 coffee beans</span>.</p>
          </div>

          <div>
            <h4 className="font-semibold text-hover-espresso">2. Daily Bean Deduction</h4>
            <p className="text-sm text-hover-coffee">By default, <span className="font-bold">3 coffee beans are deducted from your wallet every day</span>, ensuring a steady flow of activity.</p>
          </div>

          <div>
            <h4 className="font-semibold text-hover-espresso">3. Earning Coffee Beans</h4>
            <ul className="text-sm text-hover-coffee space-y-2 list-disc pl-4">
              <li><span className="font-bold">Listening to the Bean Stream:</span> For every <span className="font-bold">10 hours of listening per day</span>, you earn <span className="font-bold">1 coffee bean</span>.</li>
              <li><span className="font-bold">Engaging with Bean Mining Utilities:</span> Participate in our bean mining utilities to earn additional coffee beans. The number of beans earned depends on the specific utility and level of engagement.</li>
              <li>Also, you get real world discounted products / cash-back and more for each purchase of our utilities.</li>
            </ul>
          </div>

          <div>
            <h4 className="font-semibold text-hover-espresso">Why do I need beans?</h4>
            <p className="text-sm text-hover-coffee">Beans help us sustain our communities, while maintaining the quality and engagement on the platform.</p>
          </div>

          <AlertDialogAction 
            className="w-full bg-hover-coffee hover:bg-hover-espresso text-white transition-colors"
          >
            Got it
          </AlertDialogAction>
        </div>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default HowToEarnDialog;