import { Button } from "@/components/ui/button";
import CommentForm from "@/components/comments/CommentForm";
import CommentItem from "@/components/comments/CommentItem";
import { Award } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import type { MarketplaceComment } from "@/types/marketplace";
import type { Comment } from "@/types/comment";

interface CommentSectionProps {
  showComments: boolean;
  setShowComments: (show: boolean) => void;
  comments: MarketplaceComment[];
  isLoadingComments: boolean;
  requestId: number;
  onCommentSuccess: () => void;
  isOwner: boolean;
  onRewardComment: (commentId: number) => void;
  hasRewardedComment?: boolean;
}

const CommentSection = ({
  showComments,
  setShowComments,
  comments,
  isLoadingComments,
  requestId,
  onCommentSuccess,
  isOwner,
  onRewardComment,
  hasRewardedComment = false,
}: CommentSectionProps) => {
  const mapToCommentType = (marketplaceComment: MarketplaceComment): Comment => ({
    id: marketplaceComment.id,
    content: marketplaceComment.content,
    created_at: marketplaceComment.created_at,
    user_id: marketplaceComment.user_id,
    event_id: requestId,
    parent_id: marketplaceComment.parent_id,
    upvotes: marketplaceComment.upvotes || 0,
    user: marketplaceComment.user
  });

  return (
    <div className="border-t border-hover-coffee/5">
      <div className="p-5 space-y-4">
        <Button
          variant="ghost"
          onClick={() => setShowComments(!showComments)}
          className="w-full text-hover-coffee font-medium"
        >
          {showComments ? "Hide Discussion" : "Show Discussion"}
        </Button>

        {showComments && (
          <div className="space-y-6 animate-fade-in">
            <CommentForm
              eventId={requestId}
              onSuccess={onCommentSuccess}
              placeholder="Share your thoughts..."
            />

            {isLoadingComments ? (
              <div className="text-center py-4 text-muted-foreground animate-pulse">
                Loading comments...
              </div>
            ) : comments.length > 0 ? (
              <div className="space-y-5">
                {comments.map((comment) => (
                  <div key={comment.id} className="relative group">
                    <div className="flex items-start gap-4">
                      <div className="flex-1">
                        <CommentItem
                          comment={mapToCommentType(comment)}
                          onSuccess={onCommentSuccess}
                        />
                      </div>
                    </div>
                    
                    {comment.rewarded ? (
                      <div className="absolute top-2 right-2 flex items-center gap-1.5 px-2.5 py-1 rounded-full bg-amber-50 text-amber-600 border border-amber-200/50 shadow-sm">
                        <Award className="h-3.5 w-3.5" />
                        <span className="text-xs font-medium">Rewarded</span>
                      </div>
                    ) : isOwner && !hasRewardedComment ? (
                      <TooltipProvider>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() => onRewardComment(comment.id)}
                              className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 bg-white hover:bg-hover-cream text-hover-coffee border-hover-coffee/20 hover:border-hover-coffee/30"
                            >
                              <Award className="h-4 w-4 mr-1" />
                              Reward
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p>Reward this valuable comment with 5 coffee beans</p>
                            <p className="text-xs text-muted-foreground mt-1">You can only reward one comment per request</p>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    ) : null}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center py-6 text-muted-foreground bg-hover-cream/20 rounded-lg">
                No comments yet. Be the first to share your thoughts!
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentSection;