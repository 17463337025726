import { useState, useEffect } from "react";
import { useGroups } from "@/hooks/useGroups";
import { useGroupMessages } from "@/hooks/useGroupMessages";
import { useEvents } from "@/hooks/useEvents";
import { useConnectionRequests } from "@/hooks/useConnectionRequests";
import { supabase } from "@/integrations/supabase/client";
import ConversationsCard from "@/components/myspace/ConversationsCard";
import CoffeeBeansCard from "@/components/myspace/CoffeeBeansCard";
import EventsCard from "@/components/myspace/EventsCard";
import BeanMiningCard from "@/components/myspace/bean-mining/BeanMiningCard";
import UserManagementCard from "@/components/myspace/user-management/UserManagementCard";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Toaster } from "@/components/ui/toaster";
import ChatMonitoringCard from "@/components/myspace/chat-monitoring/ChatMonitoringCard";
import NetworkFeatureCards from "@/components/myspace/network/NetworkFeatureCards";
import NetworkMarketplaceCard from "@/components/myspace/network/NetworkMarketplaceCard";
import { NearbyUsers } from "@/components/myspace/NearbyUsers";

const MySpace = () => {
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [unreadGroups] = useState<Set<number>>(new Set());
  const [unreadMessages, setUnreadMessages] = useState<Set<string>>(new Set());
  const [showRequests, setShowRequests] = useState(false);
  const [showNetwork, setShowNetwork] = useState(false);
  const { groups, refetch: refetchGroups } = useGroups();
  const { messages, loading, hasMore, loadMoreMessages } = useGroupMessages(selectedGroupId);
  const events = useEvents();
  const { requests, currentIndex, nextRequest, previousRequest, handleRequestAction } = useConnectionRequests();

  const handleGroupJoined = () => {
    refetchGroups();
  };

  useEffect(() => {
    const fetchUnreadMessages = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (!userData) return;

      const { data: messages } = await supabase
        .from('direct_messages')
        .select('sender_id')
        .eq('recipient_id', userData.id)
        .eq('read', false);

      if (messages) {
        setUnreadMessages(new Set(messages.map(m => m.sender_id)));
      }
    };

    fetchUnreadMessages();

    const channel = supabase
      .channel('unread-messages')
      .on('postgres_changes', {
        event: 'INSERT',
        schema: 'public',
        table: 'direct_messages'
      }, (payload: any) => {
        if (payload.new.read === false) {
          setUnreadMessages(prev => new Set([...prev, payload.new.sender_id]));
        }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <TooltipProvider>
      <div className="min-h-screen bg-hover-cream pt-16 md:pt-24 pb-16">
        <div className="container max-w-7xl mx-auto px-4 md:px-6">
          <h1 className="text-2xl md:text-3xl font-bold text-hover-espresso mb-6 md:mb-8">
            My Space
          </h1>
          
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4 md:gap-6">
            {/* First Row */}
            <div className="col-span-1 md:col-span-4">
              <CoffeeBeansCard />
            </div>
            <div className="col-span-1 md:col-span-4">
              <EventsCard events={events} />
            </div>
            <div className="col-span-1 md:col-span-4">
              <BeanMiningCard />
            </div>

            {/* User Management and Chat Monitoring Cards (Admin Only) */}
            <div className="col-span-1 md:col-span-8">
              <UserManagementCard />
            </div>
            <div className="col-span-1 md:col-span-4">
              <ChatMonitoringCard />
            </div>

            {/* Network Features */}
            <div className="col-span-1 md:col-span-8">
              <NetworkFeatureCards
                requests={requests}
                currentIndex={currentIndex}
                showRequests={showRequests}
                setShowRequests={setShowRequests}
                previousRequest={previousRequest}
                nextRequest={nextRequest}
                handleRequestAction={handleRequestAction}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                showNetwork={showNetwork}
                setShowNetwork={setShowNetwork}
                unreadMessages={unreadMessages}
              />
            </div>

            {/* Conversations */}
            <div className="col-span-1 md:col-span-4">
              <ConversationsCard 
                groups={groups}
                selectedGroupId={selectedGroupId}
                setSelectedGroupId={setSelectedGroupId}
                unreadGroups={unreadGroups}
                messages={messages}
                loading={loading}
                hasMore={hasMore}
                loadMoreMessages={loadMoreMessages}
                onGroupJoined={handleGroupJoined}
                isNetworkCollapsed={false}
              />
            </div>

            {/* Marketplace and Nearby Users */}
            <div className="col-span-1 md:col-span-8">
              <NetworkMarketplaceCard
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                showNetwork={showNetwork}
                setShowNetwork={setShowNetwork}
              />
            </div>
            <div className="col-span-1 md:col-span-4">
              <NearbyUsers />
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </TooltipProvider>
  );
};

export default MySpace;