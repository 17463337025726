import { MarketplaceRequest } from "@/types/marketplace";
import RequestCard from "./RequestCard";

interface RequestListProps {
  requests: MarketplaceRequest[];
  openComments: number[];
  onCommentsToggle: (requestId: number) => void;
}

const RequestList = ({ requests, openComments, onCommentsToggle }: RequestListProps) => {
  if (!requests.length) {
    return (
      <div className="text-center text-muted-foreground py-8">
        No requests found
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {requests.map((request) => (
        <RequestCard
          key={request.id}
          request={request}
          isCommentsOpen={openComments.includes(request.id)}
          onCommentsToggle={onCommentsToggle}
        />
      ))}
    </div>
  );
};

export default RequestList;