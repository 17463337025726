import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ExternalLink } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import { useUserData } from "@/hooks/useUserData";
import { useClaimStatus } from "@/hooks/useClaimStatus";
import { useUserRole } from "@/hooks/useUserRole";
import { supabase } from "@/integrations/supabase/client";
import { useQueryClient } from "@tanstack/react-query";
import ConfirmationDialog from "./ConfirmationDialog";
import OfferRewardBadge from "./OfferRewardBadge";
import ClaimButton from "./ClaimButton";
import AdminActions from "./AdminActions";

interface BeanMiningOfferProps {
  offer: {
    id: number;
    title: string;
    description: string;
    affiliate_link?: string;
    coffee_beans_reward: number;
    image_url?: string;
  };
}

const BeanMiningOffer = ({ offer }: BeanMiningOfferProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { toast } = useToast();
  const { currentUserId } = useUserData();
  const { isAdmin } = useUserRole();
  const queryClient = useQueryClient();
  const claimStatus = useClaimStatus(offer.id, currentUserId);

  const handleVisitClick = () => {
    if (offer.affiliate_link) {
      window.open(offer.affiliate_link, '_blank');
    }
  };

  const handleClaimClick = async () => {
    if (!currentUserId) {
      toast({
        title: "Authentication required",
        description: "Please sign in to claim rewards",
        variant: "destructive",
      });
      return;
    }
    setShowConfirmation(true);
  };

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('bean_mining_offers')
        .delete()
        .eq('id', offer.id);

      if (error) throw error;

      toast({
        title: "Offer deleted",
        description: "The offer has been successfully removed",
      });
      
      queryClient.invalidateQueries({ queryKey: ['bean-mining-offers'] });
    } catch (error) {
      console.error('Error deleting offer:', error);
      toast({
        title: "Error deleting offer",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  return (
    <Card className="group relative overflow-hidden bg-white/80 hover:bg-white/90 transition-all duration-300 border border-amber-100/50 hover:border-amber-200/50 shadow-md hover:shadow-lg">
      {isAdmin && (
        <AdminActions
          offer={offer}
          claimStatus={claimStatus}
          onDelete={handleDelete}
        />
      )}
      
      <OfferRewardBadge reward={offer.coffee_beans_reward} />

      <div className="p-4 pt-12">
        <h3 className="text-lg font-semibold text-hover-espresso mb-2">
          {offer.title}
        </h3>
        <p className="text-sm text-hover-coffee/80 mb-4">
          {offer.description}
        </p>

        <div className="flex gap-2">
          {offer.affiliate_link && (
            <Button
              variant="outline"
              className="flex-1 bg-transparent"
              onClick={handleVisitClick}
            >
              <ExternalLink className="w-4 h-4 mr-2" />
              Visit
            </Button>
          )}
          <ClaimButton 
            claimStatus={claimStatus} 
            onClaim={handleClaimClick} 
          />
        </div>
      </div>

      <ConfirmationDialog
        open={showConfirmation}
        onOpenChange={setShowConfirmation}
        offerId={offer.id}
        reward={offer.coffee_beans_reward}
      />
    </Card>
  );
};

export default BeanMiningOffer;