import React from "react";

interface RequestCountBadgeProps {
  count: number;
}

const RequestCountBadge = ({ count }: RequestCountBadgeProps) => {
  if (count === 0) return null;
  
  return (
    <span className="absolute -top-1 -right-1 flex">
      <span className="relative flex h-5 w-5">
        <span className="animate-ping-slow absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
        <span className="relative inline-flex rounded-full h-5 w-5 bg-red-500 text-white text-xs items-center justify-center">
          {count}
        </span>
      </span>
    </span>
  );
};

export default RequestCountBadge;