import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";

interface RequestEditDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  editForm: {
    type: "service" | "product" | "connection";
    title: string;
    description: string;
  };
  setEditForm: (form: any) => void;
  onUpdate: () => void;
}

const RequestEditDialog = ({ 
  open, 
  onOpenChange, 
  editForm, 
  setEditForm, 
  onUpdate 
}: RequestEditDialogProps) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Edit Request</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <Select
            value={editForm.type}
            onValueChange={(value: "service" | "product" | "connection") => 
              setEditForm(prev => ({ ...prev, type: value }))
            }
          >
            <SelectTrigger>
              <SelectValue placeholder="Select request type" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="service">Service</SelectItem>
              <SelectItem value="product">Product</SelectItem>
              <SelectItem value="connection">Connection</SelectItem>
            </SelectContent>
          </Select>
          
          <Input
            placeholder="Title"
            value={editForm.title}
            onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
          />
          
          <Textarea
            placeholder="Description"
            value={editForm.description}
            onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
          />
          
          <Button onClick={onUpdate}>Update Request</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RequestEditDialog;