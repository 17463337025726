import { useEffect, useRef } from "react";
import Message from "./Message";
import type { GroupMessage } from "@/types/group";
import { Button } from "@/components/ui/button";

interface MessageListProps {
  messages: GroupMessage[];
  hasMore: boolean;
  loading: boolean;
  onLoadMore: () => void;
}

const MessageList = ({ messages, hasMore, loading, onLoadMore }: MessageListProps) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="flex-1 flex flex-col h-full min-h-0">
      {hasMore && (
        <div className="p-2 flex justify-center">
          <Button
            variant="outline"
            onClick={onLoadMore}
            disabled={loading}
            className="text-hover-espresso hover:text-hover-espresso/80"
          >
            {loading ? "Loading..." : "Load More Messages"}
          </Button>
        </div>
      )}
      <div
        ref={chatContainerRef}
        className="flex-1 overflow-y-auto p-4 bg-white min-h-0"
      >
        {messages.map((message) => (
          <Message key={message.id} message={message} />
        ))}
      </div>
    </div>
  );
};

export default MessageList;