import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import type { GroupMessage } from "@/types/group";
import MessageList from "./chat/MessageList";
import MessageInput from "./chat/MessageInput";

interface GroupChatProps {
  groupId: number;
  messages: GroupMessage[];
  loading: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  onNewMessage: (message: GroupMessage) => void;
}

const GroupChat = ({ 
  groupId, 
  messages, 
  loading,
  hasMore,
  onLoadMore,
  onNewMessage 
}: GroupChatProps) => {
  const { toast } = useToast();

  const handleSendMessage = async (content: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Please login to send messages",
          variant: "destructive",
        });
        return;
      }

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userError || !userData) {
        toast({
          title: "Error sending message",
          description: "Could not find user profile",
          variant: "destructive",
        });
        return;
      }

      const { error: messageError } = await supabase
        .from('group_messages')
        .insert({
          group_id: groupId,
          user_id: userData.id,
          content: content,
        });

      if (messageError) throw messageError;
    } catch (error) {
      console.error('Error sending message:', error);
      toast({
        title: "Error sending message",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="flex flex-col h-[500px]">
      <MessageList 
        messages={messages} 
        loading={loading}
        hasMore={hasMore}
        onLoadMore={onLoadMore}
      />
      <MessageInput onSendMessage={handleSendMessage} />
    </div>
  );
};

export default GroupChat;