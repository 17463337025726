import { useState, useEffect, useRef, forwardRef } from "react";
import { Search, CheckCircle, PlusCircle } from "lucide-react";
import { Input } from "@/components/ui/input";
import { supabase } from "@/integrations/supabase/client";
import type { Group } from "@/types/group";

interface GroupSearchProps {
  onSearch: (query: string) => void;
  onGroupJoined?: () => void;
}

const GroupSearch = forwardRef<HTMLInputElement, GroupSearchProps>(({ onSearch, onGroupJoined }, ref) => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState<Group[]>([]);
  const [userGroups, setUserGroups] = useState<Set<number>>(new Set());
  const [showDropdown, setShowDropdown] = useState(false);
  const searchRef = useRef<HTMLDivElement>(null);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Fetch and subscribe to user's joined groups
  useEffect(() => {
    const fetchUserGroups = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (!userData) return;

      const { data: memberGroups } = await supabase
        .from('group_members')
        .select('group_id')
        .eq('user_id', userData.id);

      if (memberGroups) {
        setUserGroups(new Set(memberGroups.map(m => m.group_id)));
      }
    };

    fetchUserGroups();

    // Subscribe to group_members changes
    const channel = supabase
      .channel('group-member-changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'group_members'
      }, () => {
        fetchUserGroups();
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  useEffect(() => {
    const searchGroups = async () => {
      if (query.trim() === '') {
        setSearchResults([]);
        return;
      }

      const { data, error } = await supabase
        .from('groups')
        .select('*')
        .ilike('name', `%${query}%`)
        .limit(5);

      if (error) {
        console.error('Error searching groups:', error);
        return;
      }

      setSearchResults(data || []);
    };

    searchGroups();
  }, [query]);

  const handleJoinGroup = async (groupId: number) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    const { data: userData } = await supabase
      .from('users')
      .select('id')
      .eq('email', user.email)
      .single();

    if (!userData) return;

    const { error } = await supabase
      .from('group_members')
      .insert({
        group_id: groupId,
        user_id: userData.id
      });

    if (!error) {
      setUserGroups(prev => new Set([...prev, groupId]));
      onGroupJoined?.(); // Notify parent component about the new group join
      setShowDropdown(false); // Close dropdown after joining
    }
  };

  return (
    <div className="relative" ref={searchRef}>
      <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        type="text"
        placeholder="Search groups..."
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          onSearch(e.target.value);
          setShowDropdown(true);
        }}
        onFocus={() => setShowDropdown(true)}
        className="pl-8"
        ref={ref}
      />
      
      {showDropdown && searchResults.length > 0 && (
        <div className="absolute z-50 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
          <ul className="py-1">
            {searchResults.map((group) => (
              <li
                key={group.id}
                className="px-4 py-2 hover:bg-gray-100 flex items-center justify-between cursor-pointer"
              >
                <span>{group.name}</span>
                {userGroups.has(group.id) ? (
                  <CheckCircle className="h-5 w-5 text-green-500" />
                ) : (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleJoinGroup(group.id);
                    }}
                    className="hover:text-green-500"
                  >
                    <PlusCircle className="h-5 w-5" />
                  </button>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
});

GroupSearch.displayName = "GroupSearch";

export default GroupSearch;