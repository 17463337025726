import { Card } from "@/components/ui/card";
import MarketplaceView from "../marketplace/MarketplaceView";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Users, Sparkles } from "lucide-react";

interface NetworkMarketplaceCardProps {
  selectedUserId: string | null;
  setSelectedUserId: (id: string | null) => void;
  showNetwork: boolean;
  setShowNetwork: (show: boolean) => void;
}

const NetworkMarketplaceCard = ({
  selectedUserId,
  setSelectedUserId,
  showNetwork,
  setShowNetwork,
}: NetworkMarketplaceCardProps) => {
  const handleChatSelect = (userId: string) => {
    console.log("[NetworkMarketplaceCard] Opening chat with user:", userId);
    setSelectedUserId(userId);
    setShowNetwork(true);
  };

  const handleConnect = (userId: string) => {
    console.log("[NetworkMarketplaceCard] Connecting with user:", userId);
    setSelectedUserId(userId);
    setShowNetwork(true);
  };

  return (
    <Card className="col-span-1 md:col-span-8 bg-gradient-to-br from-white to-hover-foam border-2 border-hover-coffee/5 shadow-lg rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-xl hover:border-hover-coffee/10">
      <div className="p-6 md:p-8 space-y-6">
        {/* Header Section */}
        <div className="flex items-center justify-between border-b border-hover-coffee/10 pb-4">
          <div className="flex items-center gap-3">
            <div className="p-2 bg-hover-coffee/5 rounded-full">
              <Users className="h-6 w-6 text-hover-coffee" />
            </div>
            <div>
              <h2 className="text-xl font-semibold text-hover-espresso">Marketplace Hub</h2>
              <p className="text-sm text-muted-foreground">Connect, collaborate, and grow together</p>
            </div>
          </div>
          <div className="hidden md:flex items-center gap-2">
            <Sparkles className="h-5 w-5 text-hover-coffee animate-pulse" />
            <span className="text-sm font-medium text-hover-coffee">Active Community</span>
          </div>
        </div>

        {/* Main Content Area */}
        <ScrollArea className="h-[calc(100vh-16rem)] pr-4">
          <div className="space-y-6 animate-fade-in">
            <MarketplaceView 
              onConnect={handleConnect}
              onChatSelect={handleChatSelect}
            />
          </div>
        </ScrollArea>
      </div>
    </Card>
  );
};

export default NetworkMarketplaceCard;