import { useState, useEffect, useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { DirectMessage } from "@/components/myspace/chat/types";
import { useToast } from "@/hooks/use-toast";

const PAGE_SIZE = 50;

export const useDirectMessages = (selectedUserId: string | null, overrideUserId?: string) => {
  const [messages, setMessages] = useState<DirectMessage[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const getCurrentUser = async () => {
      try {
        if (overrideUserId) {
          setCurrentUserId(overrideUserId);
          return;
        }

        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          console.log("[useDirectMessages] No authenticated user found");
          return;
        }

        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('email', user.email)
          .single();

        if (userError) {
          console.error('[useDirectMessages] Error fetching user data:', userError);
          return;
        }

        if (userData) {
          console.log("[useDirectMessages] Current user ID set:", userData.id);
          setCurrentUserId(userData.id);
        }
      } catch (err) {
        console.error('[useDirectMessages] Error in getCurrentUser:', err);
      }
    };

    getCurrentUser();
  }, [overrideUserId]);

  const fetchMessages = useCallback(async (pageNumber: number = 0) => {
    if (!selectedUserId || !currentUserId) {
      console.log("[useDirectMessages] Missing user IDs for fetch:", { selectedUserId, currentUserId });
      return;
    }

    try {
      setLoading(true);
      console.log("[useDirectMessages] Fetching messages between users:", { currentUserId, selectedUserId, page: pageNumber });

      // Calculate the range for pagination
      const from = pageNumber * PAGE_SIZE;

      // First, get the total count of messages
      const { count } = await supabase
        .from('direct_messages')
        .select('*', { count: 'exact', head: true })
        .or(`and(sender_id.eq.${currentUserId},recipient_id.eq.${selectedUserId}),and(sender_id.eq.${selectedUserId},recipient_id.eq.${currentUserId})`);

      // Then fetch the messages for the current page, ordered by created_at DESC
      const { data, error: fetchError } = await supabase
        .from('direct_messages')
        .select(`
          *,
          sender:users!direct_messages_sender_id_fkey(
            id,
            name,
            email,
            company_name,
            user_type,
            profile_image_url,
            created_at
          )
        `)
        .or(`and(sender_id.eq.${currentUserId},recipient_id.eq.${selectedUserId}),and(sender_id.eq.${selectedUserId},recipient_id.eq.${currentUserId})`)
        .order('created_at', { ascending: false })
        .range(from, from + PAGE_SIZE - 1);

      if (fetchError) {
        console.error('[useDirectMessages] Error loading messages:', fetchError);
        setError("Error loading messages");
        toast({
          title: "Error",
          description: "Failed to load messages. Please try again.",
          variant: "destructive",
        });
        return;
      }

      if (data) {
        const reversedData = [...data].reverse(); // Reverse the messages to show oldest first
        console.log("[useDirectMessages] Messages loaded:", {
          count: data.length,
          totalCount: count,
          hasMore: count ? from + data.length < count : false,
          firstMessage: reversedData[0],
          lastMessage: reversedData[reversedData.length - 1]
        });
        
        if (pageNumber === 0) {
          setMessages(reversedData);
        } else {
          setMessages(prev => [...reversedData, ...prev]); // Add older messages at the beginning
        }
        
        setHasMore(count ? from + data.length < count : false);
      }
    } catch (err) {
      console.error('[useDirectMessages] Error in fetchMessages:', err);
      setError("Error loading messages");
    } finally {
      setLoading(false);
    }
  }, [selectedUserId, currentUserId, toast]);

  const loadMore = useCallback(() => {
    const nextPage = page + 1;
    setPage(nextPage);
    fetchMessages(nextPage);
  }, [page, fetchMessages]);

  useEffect(() => {
    if (selectedUserId && currentUserId) {
      setPage(0);
      console.log("[useDirectMessages] Triggering initial message fetch");
      fetchMessages(0);
    }
  }, [selectedUserId, currentUserId, fetchMessages]);

  return { messages, loading, hasMore, error, loadMore };
};
