import { useEffect, useRef, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { LogOut } from "lucide-react";
import { cn } from "@/lib/utils";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import type { Group } from "@/types/group";

interface GroupListProps {
  groups: Group[];
  selectedGroupId: number | null;
  onGroupSelect: (groupId: number) => void;
  unreadGroups: Set<number>;
}

const GroupList = ({ groups, selectedGroupId, onGroupSelect, unreadGroups }: GroupListProps) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const { toast } = useToast();
  const [groupToLeave, setGroupToLeave] = useState<number | null>(null);

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (!container) return;

    const handleWheel = (e: WheelEvent) => {
      e.preventDefault();
      container.scrollLeft += e.deltaY;
    };

    container.addEventListener("wheel", handleWheel);
    return () => container.removeEventListener("wheel", handleWheel);
  }, []);

  const handleLeaveGroup = async (groupId: number) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Error",
          description: "You must be logged in to leave a group",
          variant: "destructive",
        });
        return;
      }

      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (!userData) {
        toast({
          title: "Error",
          description: "User profile not found",
          variant: "destructive",
        });
        return;
      }

      const { error } = await supabase
        .from('group_members')
        .delete()
        .eq('group_id', groupId)
        .eq('user_id', userData.id);

      if (error) throw error;

      toast({
        title: "Success",
        description: "You have left the group",
      });

      if (selectedGroupId === groupId) {
        onGroupSelect(0);
      }
    } catch (error) {
      console.error('Error leaving group:', error);
      toast({
        title: "Error",
        description: "Failed to leave the group. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <>
      <div
        ref={scrollContainerRef}
        className="flex gap-2 overflow-x-auto pb-2 scrollbar-hide"
      >
        {groups.map((group) => (
          <button
            key={group.id}
            onClick={() => onGroupSelect(group.id)}
            className={cn(
              "flex items-center gap-2 whitespace-nowrap rounded-full px-4 py-2 transition-colors text-sm group relative",
              selectedGroupId === group.id
                ? "bg-hover-coffee text-white"
                : "bg-hover-foam hover:bg-hover-coffee/20"
            )}
          >
            {group.name}
            {unreadGroups.has(group.id) && (
              <div className="relative">
                <Badge 
                  variant="destructive" 
                  className="h-2 w-2 rounded-full p-0 animate-bounce-slow"
                />
                <span className="absolute inset-0 rounded-full bg-red-500 animate-ping-slow" />
              </div>
            )}
            <Button
              variant="ghost"
              size="icon"
              className="h-6 w-6 ml-1 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                setGroupToLeave(group.id);
              }}
              title="Leave group"
            >
              <LogOut className="h-4 w-4" />
            </Button>
          </button>
        ))}
      </div>

      <AlertDialog open={!!groupToLeave} onOpenChange={() => setGroupToLeave(null)}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Leave Group</AlertDialogTitle>
            <AlertDialogDescription>
              Are you sure you want to leave this group? You will no longer be able to see the group messages.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                if (groupToLeave) {
                  handleLeaveGroup(groupToLeave);
                  setGroupToLeave(null);
                }
              }}
            >
              Leave Group
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default GroupList;