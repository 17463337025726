import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Avatar } from "@/components/ui/avatar";
import { CoffeeBeanUser } from "@/types/coffee-beans";
import { Loader2, X } from "lucide-react";
import { useState, useEffect } from "react";

interface UserSearchDialogProps {
  users: CoffeeBeanUser[];
  selectedUser: CoffeeBeanUser | null;
  onUserSelect: (user: CoffeeBeanUser[]) => void;  // Updated to handle multiple users
  searchQuery: string;
  onSearchChange: (value: string) => void;
  isLoading: boolean;
}

const UserSearchDialog = ({
  users = [],
  selectedUser,
  onUserSelect,
  searchQuery = '',
  onSearchChange,
  isLoading
}: UserSearchDialogProps) => {
  const [mounted, setMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<CoffeeBeanUser[]>([]);

  useEffect(() => {
    setMounted(true);
    return () => setMounted(false);
  }, []);

  console.log("[UserSearchDialog] Starting render with props:", {
    usersCount: users?.length,
    selectedUser: selectedUser?.id,
    searchQuery,
    isLoading,
    mounted
  });

  // Ensure users is always an array and filter out any invalid entries
  const safeUsers = Array.isArray(users) ? users.filter((user): user is CoffeeBeanUser => {
    const isValid = Boolean(user && typeof user.id === 'string');
    if (!isValid) {
      console.warn("[UserSearchDialog] Filtered out invalid user:", user);
    }
    return isValid;
  }) : [];
  
  console.log("[UserSearchDialog] Safe users array:", safeUsers);
  
  // Filter users based on search query and exclude already selected users
  const filteredUsers = safeUsers.filter(user => {
    const searchTerm = (searchQuery || '').toLowerCase().trim();
    const userName = (user.name || '').toLowerCase();
    const isAlreadySelected = selectedUsers.some(selected => selected.id === user.id);
    const matches = userName.includes(searchTerm) && !isAlreadySelected;
    console.log("[UserSearchDialog] Filtering user:", { 
      userId: user.id, 
      userName: user.name, 
      searchTerm,
      matches,
      isAlreadySelected
    });
    return matches;
  });

  console.log("[UserSearchDialog] Filtered users:", filteredUsers);

  if (!mounted) {
    return null;
  }

  const handleSelect = (userId: string) => {
    console.log("[UserSearchDialog] Handling user selection:", userId);
    const user = filteredUsers.find(u => u.id === userId);
    if (user) {
      console.log("[UserSearchDialog] User found, adding to selection:", user);
      const newSelectedUsers = [...selectedUsers, user];
      setSelectedUsers(newSelectedUsers);
      onUserSelect(newSelectedUsers);
      setIsOpen(false);
      onSearchChange('');
    }
  };

  const handleRemoveUser = (userId: string) => {
    console.log("[UserSearchDialog] Removing user from selection:", userId);
    const newSelectedUsers = selectedUsers.filter(u => u.id !== userId);
    setSelectedUsers(newSelectedUsers);
    onUserSelect(newSelectedUsers);
  };

  return (
    <div className="space-y-2">
      <div className="relative">
        <Command className="rounded-lg border shadow-md" shouldFilter={false}>
          <div className="flex flex-wrap items-center gap-2 p-2 border-b">
            {selectedUsers.map((user) => (
              <div
                key={user.id}
                className="flex items-center gap-2 bg-hover-coffee/10 rounded-full px-3 py-1"
              >
                <Avatar className="h-6 w-6">
                  <div className="bg-hover-coffee text-white rounded-full w-full h-full flex items-center justify-center text-xs font-medium">
                    {user.name?.charAt(0) || '?'}
                  </div>
                </Avatar>
                <span className="text-sm">{user.name}</span>
                <button
                  onClick={() => handleRemoveUser(user.id)}
                  className="text-hover-coffee hover:text-hover-coffee/80"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            ))}
            <CommandInput
              placeholder={selectedUsers.length === 0 ? "Search users by name..." : "Add more users..."}
              value={searchQuery}
              onValueChange={(value) => {
                console.log("[UserSearchDialog] Search query changed:", value);
                onSearchChange(value);
                setIsOpen(true);
              }}
              onFocus={() => setIsOpen(true)}
              className="border-none focus:ring-0 flex-1 min-w-[200px]"
            />
          </div>
          {isOpen && (
            <CommandList>
              {isLoading ? (
                <div className="p-4 text-center text-muted-foreground flex items-center justify-center gap-2">
                  <Loader2 className="h-4 w-4 animate-spin" />
                  Loading users...
                </div>
              ) : filteredUsers.length === 0 ? (
                <CommandEmpty className="p-4 text-center text-sm text-muted-foreground">
                  {searchQuery.trim() ? 'No users found.' : 'Start typing to search users.'}
                </CommandEmpty>
              ) : (
                <CommandGroup>
                  {filteredUsers.map((user) => {
                    console.log("[UserSearchDialog] Rendering user item:", user);
                    return (
                      <CommandItem
                        key={user.id}
                        value={user.id}
                        onSelect={() => handleSelect(user.id)}
                        className="flex items-center gap-2 p-2 cursor-pointer hover:bg-hover-coffee hover:text-white group"
                      >
                        <Avatar className="h-8 w-8">
                          <div className="bg-hover-coffee text-white rounded-full w-full h-full flex items-center justify-center text-sm font-medium">
                            {user.name?.charAt(0) || '?'}
                          </div>
                        </Avatar>
                        <div>
                          <p className="text-sm font-medium">{user.name || 'Unnamed User'}</p>
                          <p className="text-xs text-muted-foreground group-hover:text-white">
                            Current beans: {user.coffee_beans ?? 0}
                          </p>
                        </div>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              )}
            </CommandList>
          )}
        </Command>
      </div>
    </div>
  );
};

export default UserSearchDialog;