import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Loader2 } from "lucide-react";

interface Comment {
  id: number;
  content: string;
  user_id: string;
  created_at: string;
  events: {
    title: string;
  };
  user: {
    email: string;
    name: string;
  };
}

const Dashboard = () => {
  const { data: comments, isLoading } = useQuery({
    queryKey: ["comments"],
    queryFn: async () => {
      console.log("Fetching comments...");
      const { data, error } = await supabase
        .from("comments")
        .select(`
          *,
          events!comments_event_id_fkey(title),
          user:users!fk_user_id(name, email)
        `)
        .order("created_at", { ascending: false });

      if (error) {
        console.error("Error fetching comments:", error);
        throw error;
      }
      console.log("Fetched comments:", data);
      return data as Comment[];
    },
  });

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    );
  }

  return (
    <div className="container mx-auto py-10">
      <h1 className="text-3xl font-bold mb-8">Comments Dashboard</h1>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>User</TableHead>
              <TableHead>Comment</TableHead>
              <TableHead>Event</TableHead>
              <TableHead>Date</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {comments?.map((comment) => (
              <TableRow key={comment.id}>
                <TableCell>{comment.user?.name || 'Unknown User'}</TableCell>
                <TableCell>{comment.content}</TableCell>
                <TableCell>
                  {comment.events?.title || "No associated event"}
                </TableCell>
                <TableCell>
                  {new Date(comment.created_at).toLocaleDateString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Dashboard;