import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { MessageCircle } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CommentSection from "./CommentSection";
import { MarketplaceRequest } from "@/types/marketplace";

interface RequestCardProps {
  request: MarketplaceRequest;
  isCommentsOpen: boolean;
  onCommentsToggle: (requestId: number) => void;
}

const RequestCard = ({ request, isCommentsOpen, onCommentsToggle }: RequestCardProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleViewInMySpace = () => {
    navigate("/myspace", {
      state: { marketplaceRequestId: request.id }
    });
  };

  return (
    <Card className="p-6 mb-4">
      <h3 className="text-xl font-semibold text-hover-espresso mb-2">
        {request.title}
      </h3>
      <p className="text-muted-foreground">
        {request.description}
      </p>
      <Collapsible>
        <div className="flex justify-between items-center">
          <Button
            variant="outline"
            size="sm"
            className="mt-4"
            onClick={() => onCommentsToggle(request.id)}
          >
            <MessageCircle className="h-4 w-4 mr-2" />
            Comments
          </Button>
          <Button
            variant="default"
            size="sm"
            className="mt-4 bg-hover-coffee text-white"
            onClick={handleViewInMySpace}
          >
            View in MySpace
          </Button>
        </div>
        <CollapsibleContent>
          {isCommentsOpen && (
            <CommentSection requestId={request.id} />
          )}
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
};

export default RequestCard;