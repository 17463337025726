import { motion } from "framer-motion";
import { Button } from "@/components/ui/button";
import { RefreshCw, Users, MapPin } from "lucide-react";

interface EmptyNearbyStateProps {
  onRefresh: () => void;
}

export const EmptyNearbyState = ({ onRefresh }: EmptyNearbyStateProps) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="h-[300px] flex flex-col items-center justify-center text-center relative overflow-hidden"
    >
      <div className="relative mb-6">
        {/* Background ping animation */}
        <motion.div
          className="absolute inset-0 rounded-full bg-hover-accent/20"
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.5, 0, 0.5],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
        
        {/* Floating icons */}
        <motion.div
          className="absolute -left-8 -top-8"
          animate={{ 
            y: [-4, 4, -4],
            rotate: [0, 10, 0],
            scale: [1, 1.1, 1],
          }}
          transition={{ duration: 4, repeat: Infinity }}
        >
          <Users className="h-6 w-6 text-hover-coffee opacity-40" />
        </motion.div>
        
        <motion.div
          className="absolute -right-8 -top-8"
          animate={{ 
            y: [4, -4, 4],
            rotate: [0, -10, 0],
            scale: [1, 0.9, 1],
          }}
          transition={{ duration: 4, repeat: Infinity, delay: 0.5 }}
        >
          <Users className="h-6 w-6 text-hover-coffee opacity-40" />
        </motion.div>
        
        <MapPin className="h-12 w-12 text-hover-accent relative z-10" />
      </div>

      <motion.h4
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="text-xl font-semibold text-hover-espresso mb-2"
      >
        No professionals nearby yet
      </motion.h4>
      
      <motion.p
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="text-hover-coffee mb-6 max-w-sm"
      >
        Keep exploring! New connections appear all the time
      </motion.p>

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4 }}
      >
        <Button
          onClick={onRefresh}
          className="bg-hover-accent hover:bg-hover-coffee transition-all duration-300 group"
        >
          <motion.div
            animate={{ rotate: [0, 360] }}
            transition={{ duration: 2, repeat: Infinity, ease: "linear", repeatDelay: 1 }}
            className="mr-2 opacity-80 group-hover:opacity-100"
          >
            <RefreshCw className="h-4 w-4" />
          </motion.div>
          Refresh Nearby
        </Button>
      </motion.div>
    </motion.div>
  );
};