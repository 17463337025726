import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { UserTypeSelect } from "../UserTypeSelect";

interface ProfileFieldsProps {
  name: string;
  setName: (value: string) => void;
  companyName: string;
  setCompanyName: (value: string) => void;
  userType: string;
  setUserType: (value: string) => void;
  linkedinUrl: string;
  setLinkedinUrl: (value: string) => void;
  disabled?: boolean;
}

const ProfileFields = ({
  name,
  setName,
  companyName,
  setCompanyName,
  userType,
  setUserType,
  linkedinUrl,
  setLinkedinUrl,
  disabled,
}: ProfileFieldsProps) => {
  return (
    <>
      <div className="space-y-2">
        <Label htmlFor="name">Name</Label>
        <Input
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          className="bg-white"
          disabled={disabled}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="companyName">Company Name</Label>
        <Input
          id="companyName"
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
          required
          className="bg-white"
          disabled={disabled}
        />
      </div>

      <div className="space-y-2">
        <Label htmlFor="linkedinUrl">LinkedIn Profile URL</Label>
        <Input
          id="linkedinUrl"
          type="url"
          value={linkedinUrl}
          onChange={(e) => setLinkedinUrl(e.target.value)}
          placeholder="https://www.linkedin.com/in/your-profile"
          className="bg-white"
          disabled={disabled}
        />
      </div>

      <UserTypeSelect value={userType} onValueChange={setUserType} disabled={disabled} />
    </>
  );
};

export default ProfileFields;