import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { ChatPreview } from "../types";
import { useToast } from "@/hooks/use-toast";

export const useChatPreviews = () => {
  const [chatPreviews, setChatPreviews] = useState<ChatPreview[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchChatPreviews = async () => {
      try {
        setLoading(true);
        setError(null);

        const { data: { user } } = await supabase.auth.getUser();
        if (!user) {
          setError("No authenticated user found");
          return;
        }

        // Get the current user's ID from the users table
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('id')
          .eq('email', user.email)
          .single();

        if (userError) {
          console.error('Error fetching user data:', userError);
          setError("Error fetching user data");
          return;
        }

        const currentUserId = userData.id;

        // Fetch all direct messages where the user is either sender or recipient
        const { data: messages, error: messagesError } = await supabase
          .from('direct_messages')
          .select(`
            id,
            content,
            created_at,
            sender_id,
            recipient_id,
            sender:users!direct_messages_sender_id_fkey(
              id,
              name,
              company_name,
              verified
            ),
            recipient:users!direct_messages_recipient_id_fkey(
              id,
              name,
              company_name,
              verified
            )
          `)
          .or(`sender_id.eq.${currentUserId},recipient_id.eq.${currentUserId}`)
          .order('created_at', { ascending: false });

        if (messagesError) {
          console.error('Error fetching messages:', messagesError);
          setError("Error fetching messages");
          return;
        }

        // Process messages to create chat previews
        const previewsMap = new Map<string, ChatPreview>();

        messages?.forEach(message => {
          const isUserSender = message.sender_id === currentUserId;
          const connectionId = isUserSender ? message.recipient_id : message.sender_id;
          const connectionName = isUserSender ? message.recipient.name : message.sender.name;
          const companyName = isUserSender ? message.recipient.company_name : message.sender.company_name;
          const connectionVerified = isUserSender ? message.recipient.verified : message.sender.verified;

          if (!previewsMap.has(connectionId)) {
            previewsMap.set(connectionId, {
              connectionId,
              connectionName,
              connectionVerified,
              companyName,
              lastMessage: {
                content: message.content,
                timestamp: message.created_at,
                isSender: isUserSender
              }
            });
          }
        });

        setChatPreviews(Array.from(previewsMap.values()));
      } catch (err) {
        console.error('Error in fetchChatPreviews:', err);
        setError("Error fetching chat previews");
      } finally {
        setLoading(false);
      }
    };

    fetchChatPreviews();
  }, []);

  return { chatPreviews, loading, error };
};