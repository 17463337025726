import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { useToast } from "@/components/ui/use-toast";
import UserTableHeader from "./UserTableHeader";
import UserTableRow from "./UserTableRow";
import type { User } from "@/types/user";

const UserTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const { toast } = useToast();

  const { data: users, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: async () => {
      const { data, error } = await supabase
        .from("users")
        .select("*")
        .order("created_at", { ascending: false })
        .limit(50);

      if (error) throw error;
      return data as User[];
    },
  });

  const filteredUsers = users?.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.company_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedUsers(filteredUsers?.map((user) => user.id) || []);
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (userId: string, checked: boolean) => {
    if (checked) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };

  const handleVerifyUsers = async () => {
    try {
      const { error } = await supabase
        .from("users")
        .update({ verified: true })
        .in("id", selectedUsers);

      if (error) throw error;

      toast({
        title: "Users verified",
        description: `Successfully verified ${selectedUsers.length} users.`,
      });
      
      refetch();
      setSelectedUsers([]);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to verify users. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleBanUsers = async () => {
    try {
      const { error } = await supabase
        .from("users")
        .update({ banned: true })
        .in("id", selectedUsers);

      if (error) throw error;

      toast({
        title: "Users banned",
        description: `Successfully banned ${selectedUsers.length} users.`,
      });
      
      refetch();
      setSelectedUsers([]);
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to ban users. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="space-y-4">
      <UserTableHeader
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedUsers={selectedUsers}
        onVerifyUsers={handleVerifyUsers}
        onBanUsers={handleBanUsers}
      />

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-12">
              <Checkbox
                checked={selectedUsers.length === filteredUsers?.length}
                onCheckedChange={handleSelectAll}
              />
            </TableHead>
            <TableHead className="w-12"></TableHead>
            <TableHead>Name</TableHead>
            <TableHead>Email</TableHead>
            <TableHead>Company</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Joined</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredUsers?.map((user) => (
            <UserTableRow
              key={user.id}
              user={user}
              isSelected={selectedUsers.includes(user.id)}
              onSelectUser={handleSelectUser}
            />
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default UserTable;