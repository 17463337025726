import { supabase } from '@/integrations/supabase/client';
import { toast } from '@/hooks/use-toast';
import { useLocationPermission } from './useLocationPermission';
import { useLocationUpdater } from './useLocationUpdater';

export const useNearbyLocation = () => {
  const { locationEnabled, setLocationEnabled } = useLocationPermission();
  const { updateLocation, startWatchingPosition } = useLocationUpdater();

  const enableLocation = async () => {
    if (!navigator.geolocation) {
      toast({
        title: "Location not supported",
        description: "Your browser doesn't support location services",
        variant: "destructive",
      });
      return;
    }

    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      await updateLocation(position);
      setLocationEnabled(true);
      
      toast({
        title: "Location enabled",
        description: "You can now see and be seen by nearby users",
      });

      startWatchingPosition();
    } catch (error) {
      console.error('Error enabling location:', error);
      toast({
        title: "Location access denied",
        description: "Please enable location access in your browser settings",
        variant: "destructive",
      });
    }
  };

  const disableLocation = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      await supabase
        .from('user_locations')
        .update({ location_enabled: false })
        .eq('user_id', user.id);

      setLocationEnabled(false);
      toast({
        title: "Location disabled",
        description: "You will no longer see or be seen by nearby users",
      });
    } catch (error) {
      console.error('Error disabling location:', error);
      toast({
        title: "Error disabling location",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  return {
    locationEnabled,
    enableLocation,
    disableLocation,
  };
};