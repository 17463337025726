import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface BeansPurchaseSectionProps {
  coffeeBeansCount: number;
  updateBeansCount: (count: number) => void;
}

const BeansPurchaseSection = ({ coffeeBeansCount, updateBeansCount }: BeansPurchaseSectionProps) => {
  const [beansToBuy, setBeansToBuy] = useState<string>("10");
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const handleBuyBeans = async () => {
    try {
      setLoading(true);
      const beanCount = parseInt(beansToBuy);

      const { data: order, error } = await supabase.functions.invoke('create-razorpay-order', {
        body: { beanCount },
      });

      if (error) throw error;

      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      document.body.appendChild(script);

      script.onload = () => {
        const options = {
          key: import.meta.env.VITE_RAZORPAY_KEY_ID,
          amount: order.amount,
          currency: order.currency,
          name: 'Coffee Beans',
          description: `Purchase ${beanCount} Coffee Beans`,
          order_id: order.id,
          handler: async function (response: any) {
            const { data: { user } } = await supabase.auth.getUser();
            if (!user) return;

            const { error: updateError } = await supabase
              .from('users')
              .update({ coffee_beans: coffeeBeansCount + beanCount })
              .eq('email', user.email);

            if (updateError) {
              toast({
                title: "Error",
                description: "Failed to update coffee beans balance",
                variant: "destructive",
              });
              return;
            }

            toast({
              title: "Success!",
              description: `Successfully purchased ${beanCount} coffee beans!`,
            });
          },
          prefill: {
            name: 'Coffee Lover',
          },
          theme: {
            color: '#7C3AED',
          },
        };

        const razorpay = new window.Razorpay(options);
        razorpay.open();
      };
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to initiate payment",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="mt-4">
        <Select value={beansToBuy} onValueChange={setBeansToBuy}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select amount of beans" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="10">10 Beans (₹300)</SelectItem>
            <SelectItem value="20">20 Beans (₹600)</SelectItem>
            <SelectItem value="50">50 Beans (₹1500)</SelectItem>
            <SelectItem value="100">100 Beans (₹3000)</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <Button 
        className="w-full mt-2 bg-hover-coffee hover:bg-hover-espresso text-white transition-colors"
        onClick={handleBuyBeans}
        disabled={loading}
      >
        {loading ? "Processing..." : `Buy ${beansToBuy} Beans`}
      </Button>
    </div>
  );
};

export default BeansPurchaseSection;