import { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import type { DirectMessage } from '../types';

interface MessageContextType {
  messages: DirectMessage[];
  addMessage: (message: DirectMessage) => void;
  setInitialMessages: (messages: DirectMessage[]) => void;
}

const MessageContext = createContext<MessageContextType | undefined>(undefined);

export const MessageProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<DirectMessage[]>([]);

  const addMessage = useCallback((message: DirectMessage) => {
    setMessages(prev => {
      // Check for duplicates using message ID
      if (prev.some(msg => msg.id === message.id)) {
        console.log('[MessageContext] Duplicate message detected, skipping:', message.id);
        return prev;
      }

      console.log('[MessageContext] Adding new message:', message);

      // Add new message and sort by timestamp
      const newMessages = [...prev, message].sort(
        (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
      );

      console.log('[MessageContext] Messages updated:', {
        previousCount: prev.length,
        newCount: newMessages.length,
        lastMessage: newMessages[newMessages.length - 1]
      });

      return newMessages;
    });
  }, []);

  const setInitialMessages = useCallback((initialMessages: DirectMessage[]) => {
    console.log('[MessageContext] Setting initial messages:', {
      count: initialMessages.length,
      firstMessage: initialMessages[0],
      lastMessage: initialMessages[initialMessages.length - 1]
    });
    
    // Sort messages by timestamp before setting
    const sortedMessages = [...initialMessages].sort(
      (a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
    );
    setMessages(sortedMessages);
  }, []);

  return (
    <MessageContext.Provider value={{ messages, addMessage, setInitialMessages }}>
      {children}
    </MessageContext.Provider>
  );
};

export const useMessages = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessages must be used within a MessageProvider');
  }
  return context;
};