import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Send } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";

interface CommentFormProps {
  eventId: number;
  parentId?: number | null;
  onSuccess: () => void;
  placeholder?: string;
}

const CommentForm = ({ eventId, parentId = null, onSuccess, placeholder = "Share your thoughts..." }: CommentFormProps) => {
  const [content, setContent] = useState("");
  const { toast } = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    if (!content.trim()) return;
    
    try {
      setIsSubmitting(true);
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        toast({
          title: "Please login to comment",
          variant: "destructive",
        });
        return;
      }

      console.log("Attempting to post comment with data:", {
        request_id: eventId,
        user_id: user.id,
        content: content.trim(),
        parent_id: parentId,
      });

      const { data, error } = await supabase
        .from('marketplace_request_comments')
        .insert({
          request_id: eventId,
          user_id: user.id,
          content: content.trim(),
          parent_id: parentId,
        });

      if (error) {
        console.error('Error details:', error);
        throw error;
      }

      console.log("Comment posted successfully:", data);

      toast({
        title: "Comment posted successfully!",
      });
      
      setContent("");
      onSuccess();
    } catch (error) {
      console.error('Error posting comment:', error);
      toast({
        title: "Error posting comment",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex gap-2">
      <Textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        placeholder={placeholder}
        className="flex-grow"
        disabled={isSubmitting}
      />
      <Button 
        onClick={handleSubmit}
        className="bg-hover-coffee hover:bg-hover-espresso text-white"
        disabled={isSubmitting}
      >
        <Send size={16} />
      </Button>
    </div>
  );
};

export default CommentForm;