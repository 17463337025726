import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Users, UserPlus } from "lucide-react";
import RequestCountBadge from "./RequestCountBadge";

interface ConnectionTabsProps {
  showRequests: boolean;
  requestCount: number;
  onTabChange: (show: boolean) => void;
  showNewConnections: boolean;
  onNewConnectionsChange: (show: boolean) => void;
}

const ConnectionTabs = ({ 
  showRequests, 
  requestCount, 
  onTabChange,
  showNewConnections,
  onNewConnectionsChange
}: ConnectionTabsProps) => {
  return (
    <ScrollArea className="w-full">
      <div className="flex gap-2 pb-4">
        <Button
          variant="ghost"
          size="sm"
          className={`relative flex-1 sm:flex-none min-w-[120px] text-sm font-medium transition-colors ${
            showRequests && !showNewConnections ? 'bg-hover-cream text-hover-espresso' : 'text-muted-foreground hover:text-hover-espresso hover:bg-hover-cream/10'
          }`}
          onClick={() => {
            onTabChange(true);
            onNewConnectionsChange(false);
          }}
        >
          <Users className="h-4 w-4 mr-2" />
          Requests
          {requestCount > 0 && (
            <RequestCountBadge count={requestCount} />
          )}
        </Button>
        <Button
          variant="ghost"
          size="sm"
          className={`flex-1 sm:flex-none min-w-[140px] text-sm font-medium transition-colors ${
            showNewConnections ? 'bg-hover-cream text-hover-espresso' : 'text-muted-foreground hover:text-hover-espresso hover:bg-hover-cream/10'
          }`}
          onClick={() => {
            onTabChange(false);
            onNewConnectionsChange(true);
          }}
        >
          <UserPlus className="h-4 w-4 mr-2" />
          New Connections
        </Button>
      </div>
    </ScrollArea>
  );
};

export default ConnectionTabs;