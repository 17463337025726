import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import UserAvatar from "@/components/comments/UserAvatar";
import { useToast } from "@/hooks/use-toast";
import { useNavigate, useLocation } from "react-router-dom";
import { MessageCircle } from "lucide-react";

interface Connection {
  id: string;
  name: string;
  company_name: string;
  verified: boolean;
  created_at: string;
}

interface NewConnectionsListProps {
  onSelectChat: (userId: string) => void;
}

const NewConnectionsList = ({ onSelectChat }: NewConnectionsListProps) => {
  const [connections, setConnections] = useState<Connection[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const fetchRecentConnections = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        const { data, error } = await supabase
          .from('user_connections')
          .select(`
            connected_user_id,
            created_at,
            users!user_connections_connected_user_id_fkey (
              id,
              name,
              company_name,
              verified
            )
          `)
          .eq('user_id', user.id)
          .order('created_at', { ascending: false })
          .limit(10);

        if (error) throw error;

        const formattedConnections = data.map(connection => ({
          id: connection.users.id,
          name: connection.users.name,
          company_name: connection.users.company_name,
          verified: connection.users.verified,
          created_at: connection.created_at
        }));

        setConnections(formattedConnections);
      } catch (error) {
        console.error('Error fetching connections:', error);
        toast({
          title: "Error",
          description: "Failed to load recent connections",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchRecentConnections();
  }, [toast]);

  const handleChatClick = (userId: string) => {
    console.log("[NewConnectionsList] Starting chat with user:", userId);
    onSelectChat(userId);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-hover-coffee"></div>
      </div>
    );
  }

  if (connections.length === 0) {
    return (
      <div className="text-center p-8 text-muted-foreground">
        No recent connections found
      </div>
    );
  }

  return (
    <div className="space-y-4 p-4">
      {connections.map((connection) => (
        <div
          key={connection.id}
          className="flex items-center gap-4 p-4 rounded-lg bg-hover-foam hover:bg-hover-cream/20 cursor-pointer transition-colors group relative"
        >
          <div className="flex-1 flex items-center gap-4">
            <UserAvatar 
              name={connection.name} 
              size="md"
              isVerified={connection.verified}
            />
            <div className="flex-1 min-w-0">
              <h3 className="font-medium text-hover-espresso truncate">
                {connection.name}
              </h3>
              <p className="text-sm text-muted-foreground truncate">
                {connection.company_name}
              </p>
            </div>
          </div>
          <button 
            onClick={(e) => {
              e.stopPropagation();
              handleChatClick(connection.id);
            }}
            className="p-2 hover:bg-hover-cream rounded-full transition-colors"
            aria-label={`Start chat with ${connection.name}`}
          >
            <MessageCircle 
              className="w-5 h-5 text-muted-foreground opacity-50 group-hover:opacity-100 transition-opacity" 
            />
          </button>
        </div>
      ))}
    </div>
  );
};

export default NewConnectionsList;