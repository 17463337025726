import { useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import type { DirectMessage } from "../types";

export const useMessageSender = (
  currentUserId: string | null,
  recipientId: string,
  onMessageSent: (message: DirectMessage) => void
) => {
  const { toast } = useToast();

  const sendMessage = useCallback(async (content: string) => {
    if (!currentUserId || !recipientId || !content.trim()) {
      console.log("[useMessageSender] Missing data:", { currentUserId, recipientId, content });
      return;
    }

    try {
      console.log("[useMessageSender] Sending message:", { content, currentUserId, recipientId });
      
      const messageData = {
        content: content.trim(),
        sender_id: currentUserId,
        recipient_id: recipientId,
      };

      const { data: newMessage, error: insertError } = await supabase
        .from("direct_messages")
        .insert([messageData])
        .select(`
          *,
          sender:users!direct_messages_sender_id_fkey(
            id,
            name,
            email,
            company_name,
            user_type,
            profile_image_url,
            created_at
          )
        `)
        .single();

      if (insertError) {
        console.error("[useMessageSender] Error sending message:", insertError);
        toast({
          title: "Error",
          description: "Failed to send message. Please try again.",
          variant: "destructive",
        });
        return;
      }

      if (newMessage) {
        console.log("[useMessageSender] Message sent successfully:", newMessage);
        onMessageSent(newMessage);
      }
    } catch (error) {
      console.error("[useMessageSender] Unexpected error:", error);
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        variant: "destructive",
      });
    }
  }, [currentUserId, recipientId, onMessageSent, toast]);

  return { sendMessage };
};