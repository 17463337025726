import { Loader2 } from 'lucide-react';
import { useNearbyLocation } from '@/hooks/location/useNearbyLocation';
import { EmptyNearbyState } from './nearby/EmptyNearbyState';
import { NearbyUserList } from './nearby/NearbyUserList';
import { EnableLocationCard } from './nearby/EnableLocationCard';
import { useNearbyUsers } from '@/hooks/useNearbyUsers';
import { useNearbyConnections } from '@/hooks/useNearbyConnections';
import { NearbyHeader } from './nearby/NearbyHeader';

export const NearbyUsers = () => {
  const { locationEnabled, enableLocation, disableLocation } = useNearbyLocation();
  const { nearbyUsers, isLoading, fetchNearbyUsers } = useNearbyUsers(locationEnabled);
  const { handleConnect } = useNearbyConnections();

  if (!locationEnabled) {
    return <EnableLocationCard onEnable={enableLocation} />;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border 
                    border-hover-coffee/10 h-[520px] p-4 md:p-6 relative overflow-hidden 
                    flex flex-col animate-fade-in">
      <NearbyHeader onDisableLocation={disableLocation} />

      {isLoading ? (
        <div className="flex-1 flex items-center justify-center">
          <Loader2 className="h-8 w-8 animate-spin text-hover-coffee" />
        </div>
      ) : nearbyUsers.length === 0 ? (
        <div className="flex-1">
          <EmptyNearbyState onRefresh={fetchNearbyUsers} />
        </div>
      ) : (
        <div className="flex-1 overflow-y-auto">
          <NearbyUserList users={nearbyUsers} onConnect={handleConnect} />
        </div>
      )}
    </div>
  );
};