import GroupChat from "@/components/groups/GroupChat";
import EmptyConversationState from "./EmptyConversationState";

interface ChatContainerProps {
  selectedGroupId: number | null;
  messages: any[];
  loading: boolean;
  hasMore: boolean;
  onLoadMore: () => void;
  onStartExploring: () => void;
}

const ChatContainer = ({
  selectedGroupId,
  messages,
  loading,
  hasMore,
  onLoadMore,
  onStartExploring,
}: ChatContainerProps) => {
  return (
    <div className="border-t border-slate-200">
      <div className="px-6 py-4">
        {selectedGroupId ? (
          <GroupChat
            groupId={selectedGroupId}
            messages={messages}
            loading={loading}
            hasMore={hasMore}
            onLoadMore={onLoadMore}
            onNewMessage={console.log}
          />
        ) : (
          <EmptyConversationState onStartExploring={onStartExploring} />
        )}
      </div>
    </div>
  );
};

export default ChatContainer;