import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Eye, Pencil, Trash2 } from "lucide-react";
import ClaimApprovalDialog from "./ClaimApprovalDialog";
import EditOfferDialog from "./EditOfferDialog";
import ConfirmationDialog from "./ConfirmationDialog";

interface AdminActionsProps {
  offer: {
    id: number;
    title: string;
    description: string;
    affiliate_link?: string;
    coffee_beans_reward: number;
    image_url?: string;
  };
  claimStatus: string | null;
  onDelete: () => void;
}

const AdminActions = ({ offer, claimStatus, onDelete }: AdminActionsProps) => {
  const [showApprovalDialog, setShowApprovalDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  return (
    <div className="absolute top-3 left-3 flex gap-2 z-10">
      <Button
        variant="ghost"
        size="icon"
        className="opacity-70 hover:opacity-100 transition-opacity bg-hover-cream hover:bg-hover-foam"
        onClick={() => setShowEditDialog(true)}
      >
        <Pencil className="h-5 w-5 text-hover-coffee" />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        className="opacity-70 hover:opacity-100 transition-opacity bg-hover-cream hover:bg-hover-foam"
        onClick={() => setShowDeleteConfirm(true)}
      >
        <Trash2 className="h-5 w-5 text-red-500" />
      </Button>
      <Button
        variant="ghost"
        size="icon"
        className="opacity-70 hover:opacity-100 transition-opacity bg-hover-cream hover:bg-hover-foam"
        onClick={() => setShowApprovalDialog(true)}
      >
        <Eye className={`h-5 w-5 ${claimStatus === 'pending' ? 'text-blue-500' : 'text-hover-coffee/50'}`} />
      </Button>

      <ClaimApprovalDialog
        open={showApprovalDialog}
        onOpenChange={setShowApprovalDialog}
        offerId={offer.id}
        offerTitle={offer.title}
        rewardAmount={offer.coffee_beans_reward}
      />
      
      <EditOfferDialog
        open={showEditDialog}
        onOpenChange={setShowEditDialog}
        offer={offer}
      />
      
      <ConfirmationDialog
        open={showDeleteConfirm}
        onOpenChange={setShowDeleteConfirm}
        title="Delete Offer"
        description="Are you sure you want to delete this offer? This action cannot be undone."
        confirmLabel="Delete"
        cancelLabel="Cancel"
        variant="destructive"
        onConfirm={onDelete}
      />
    </div>
  );
};

export default AdminActions;