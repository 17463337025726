import { Button } from "@/components/ui/button";
import { ArrowLeft, ArrowRight } from "lucide-react";
import RequestDetails from "./RequestDetails";
import { GroupSuggestions } from "./GroupSuggestions";

interface RequestsCarouselProps {
  requests: any[];
  currentIndex: number;
  onPrevious: () => void;
  onNext: () => void;
  onAction: (requestId: number, status: 'accepted' | 'rejected') => void;
}

const RequestsCarousel = ({
  requests,
  currentIndex,
  onPrevious,
  onNext,
  onAction,
}: RequestsCarouselProps) => {
  if (requests.length === 0) {
    return <GroupSuggestions />;
  }

  return (
    <div className="relative min-h-[420px] flex items-center justify-center">
      <Button
        variant="ghost"
        size="icon"
        className="absolute left-0 z-10 hover:bg-hover-cream"
        onClick={onPrevious}
        disabled={currentIndex === 0}
      >
        <ArrowLeft className="h-4 w-4" />
      </Button>

      <div className="w-full max-w-sm mx-8">
        <RequestDetails 
          request={requests[currentIndex]}
          onAction={onAction}
        />
      </div>

      <Button
        variant="ghost"
        size="icon"
        className="absolute right-0 z-10 hover:bg-hover-cream"
        onClick={onNext}
        disabled={currentIndex === requests.length - 1}
      >
        <ArrowRight className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default RequestsCarousel;