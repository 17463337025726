import { MapPin, Calendar } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Event } from "@/types/event";

interface EventCardProps {
  event: Event;
}

const EventCard = ({ event }: EventCardProps) => {
  return (
    <div className="group h-[550px] md:h-[600px] flex flex-col bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
      <div className="aspect-[4/3] relative overflow-hidden">
        <img
          src={event.image_url}
          alt={event.title}
          className="object-cover w-full h-full transition-transform duration-300 group-hover:scale-110"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
      </div>
      
      <div className="flex flex-col flex-grow p-4 md:p-6">
        <h3 className="text-lg md:text-xl font-semibold text-hover-espresso mb-4">
          {event.title}
        </h3>
        
        <div className="space-y-2 mb-4">
          <div className="flex items-center gap-2 text-hover-coffee">
            <MapPin className="w-4 h-4" />
            <span className="text-sm">{event.location}</span>
          </div>
          <div className="flex items-center gap-2 text-hover-coffee">
            <Calendar className="w-4 h-4" />
            <span className="text-sm">{event.date} {event.time && `• ${event.time}`}</span>
          </div>
        </div>
        
        <p className="text-sm text-hover-coffee/80 flex-grow line-clamp-4 md:line-clamp-6">
          {event.description}
        </p>
        
        {event.title === "The Start-up Brewhouse" ? (
          <a href="/the-startup-brewhouse-ep1">
            <Button className="w-full mt-4 md:mt-6 bg-hover-coffee hover:bg-hover-espresso transition-colors">
              View Highlights
            </Button>
          </a>
        ) : (
          <Button className="w-full mt-4 md:mt-6 bg-hover-coffee hover:bg-hover-espresso transition-colors" disabled>
            Coming Soon
          </Button>
        )}
      </div>
    </div>
  );
};

export default EventCard;