import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useUserData } from "./useUserData";
import { useToast } from "./use-toast";

export const useUserRole = () => {
  const { currentUserId } = useUserData();
  const { toast } = useToast();

  const { data: role, error } = useQuery({
    queryKey: ['user-role', currentUserId],
    queryFn: async () => {
      if (!currentUserId) return null;
      
      try {
        const { data, error } = await supabase
          .from('user_roles')
          .select('role')
          .eq('user_id', currentUserId)
          .maybeSingle();
        
        if (error) {
          console.error('Error fetching user role:', error);
          toast({
            title: "Error fetching user role",
            description: "Please try again later",
            variant: "destructive",
          });
          return null;
        }
        
        return data?.role;
      } catch (err) {
        console.error('Error in useUserRole:', err);
        toast({
          title: "Error fetching user role",
          description: "Please try again later",
          variant: "destructive",
        });
        return null;
      }
    },
    enabled: !!currentUserId,
    retry: 1,
  });

  return {
    isAdmin: role === 'admin',
    role,
  };
};