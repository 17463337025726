import { useEffect, useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { supabase } from "@/integrations/supabase/client";
import { UserTypeSelect } from "@/components/forms/UserTypeSelect";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { ExternalLink } from "lucide-react";

const Profile = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userType, setUserType] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [biography, setBiography] = useState("");
  const [profileImage, setProfileImage] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  // Fetch user profile data
  const { data: profileData } = useQuery({
    queryKey: ['profile'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      return data;
    }
  });

  // Update form fields when profile data is loaded
  useEffect(() => {
    if (profileData) {
      setName(profileData.name || "");
      setCompanyName(profileData.company_name || "");
      setUserType(profileData.user_type || "");
      setLinkedinUrl(profileData.linkedin_url || "");
      setBiography(profileData.biography || "");
    }
  }, [profileData]);

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setProfileImage(file);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const user = (await supabase.auth.getUser()).data.user;
      if (!user) throw new Error("No user found");

      if (profileImage) {
        const fileExt = profileImage.name.split('.').pop();
        const filePath = `${user.id}/${Date.now()}.${fileExt}`;

        const { error: uploadError } = await supabase.storage
          .from('profile_images')
          .upload(filePath, profileImage);

        if (uploadError) throw uploadError;

        const { data: { publicUrl } } = supabase.storage
          .from('profile_images')
          .getPublicUrl(filePath);

        await supabase
          .from('users')
          .update({ 
            profile_image_url: publicUrl,
            name,
            company_name: companyName,
            user_type: userType,
            linkedin_url: linkedinUrl,
            biography
          })
          .eq('id', user.id);
      } else {
        await supabase
          .from('users')
          .update({ 
            name,
            company_name: companyName,
            user_type: userType,
            linkedin_url: linkedinUrl,
            biography
          })
          .eq('id', user.id);
      }

      toast({
        title: "Profile updated successfully!",
      });
    } catch (error: any) {
      console.error("Profile update error:", error);
      toast({
        title: "Failed to update profile",
        description: error.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <div className="container max-w-2xl mx-auto px-4">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-hover-espresso">Profile Settings</h1>
          {profileData && (
            <Link 
              to={`/profile/${profileData.id}`}
              className="flex items-center gap-2 text-hover-coffee hover:text-hover-espresso transition-colors"
            >
              <span>View Public Profile</span>
              <ExternalLink className="h-4 w-4" />
            </Link>
          )}
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-6 bg-white p-6 rounded-lg shadow">
          <div className="space-y-2">
            <Label htmlFor="profileImage">Profile Image</Label>
            <Input
              id="profileImage"
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              disabled={isLoading}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="name">Name</Label>
            <Input
              id="name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="companyName">Company Name</Label>
            <Input
              id="companyName"
              type="text"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              required
              disabled={isLoading}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="biography">Biography</Label>
            <Textarea
              id="biography"
              value={biography}
              onChange={(e) => setBiography(e.target.value)}
              placeholder="Tell us about yourself..."
              className="min-h-[100px]"
              disabled={isLoading}
            />
          </div>

          <div className="space-y-2">
            <Label htmlFor="linkedinUrl">LinkedIn Profile URL</Label>
            <Input
              id="linkedinUrl"
              type="url"
              value={linkedinUrl}
              onChange={(e) => setLinkedinUrl(e.target.value)}
              placeholder="https://www.linkedin.com/in/your-profile"
              disabled={isLoading}
            />
          </div>

          <UserTypeSelect 
            value={userType} 
            onValueChange={setUserType} 
            disabled={isLoading} 
          />

          <Button 
            type="submit" 
            className="w-full bg-hover-coffee hover:bg-hover-espresso text-white"
            disabled={isLoading}
          >
            {isLoading ? "Updating..." : "Update Profile"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Profile;