import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { Loader2 } from 'lucide-react';
import { SuggestionCard } from './suggestions/SuggestionCard';
import { EmptySuggestions } from './suggestions/EmptySuggestions';
import { SuggestionsHeader } from './suggestions/SuggestionsHeader';

interface GroupSuggestion {
  id: string;
  name: string;
  company_name: string;
  profile_image_url: string | null;
  linkedin_url: string | null;
}

export const GroupSuggestions = () => {
  const [suggestions, setSuggestions] = useState<GroupSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    const fetchGroupSuggestions = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        console.log('Fetching group suggestions for user:', user.id);
        
        // First, get the IDs of users who are already connected
        const { data: connections } = await supabase
          .from('user_connections')
          .select('connected_user_id')
          .eq('user_id', user.id);
        
        const connectedUserIds = connections?.map(c => c.connected_user_id) || [];

        // Then, get the IDs of users with pending connection requests
        const { data: pendingRequests } = await supabase
          .from('connection_requests')
          .select('recipient_id')
          .eq('requester_id', user.id)
          .eq('status', 'pending');

        const pendingUserIds = pendingRequests?.map(r => r.recipient_id) || [];

        // Get the groups the user is a member of
        const { data: userGroups } = await supabase
          .from('group_members')
          .select('group_id')
          .eq('user_id', user.id);

        const groupIds = userGroups?.map(g => g.group_id) || [];

        // Get all users from these groups
        const { data: groupMembers } = await supabase
          .from('group_members')
          .select('user_id')
          .in('group_id', groupIds);

        const groupMemberIds = groupMembers?.map(m => m.user_id) || [];

        // Combine all IDs to exclude
        const excludeIds = [user.id, ...connectedUserIds, ...pendingUserIds];

        // Get users from the same groups who aren't already connected or pending
        const { data, error } = await supabase
          .from('users')
          .select('id, name, company_name, profile_image_url, linkedin_url')
          .in('id', groupMemberIds)
          .not('id', 'in', `(${excludeIds.join(',')})`);

        if (error) throw error;
        
        // Shuffle the suggestions array
        const shuffledData = data ? [...data].sort(() => Math.random() - 0.5) : [];
        console.log('Group suggestions found:', shuffledData);
        setSuggestions(shuffledData);
      } catch (error) {
        console.error('Error fetching group suggestions:', error);
        toast({
          title: "Error loading suggestions",
          description: "Please try again later",
          variant: "destructive",
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchGroupSuggestions();
  }, []);

  const handleConnect = async (userId: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('connection_requests')
        .insert({
          requester_id: user.id,
          recipient_id: userId,
        });

      if (error) throw error;

      setSuggestions(prev => prev.filter(s => s.id !== userId));

      toast({
        title: "Connection request sent!",
        description: "We'll notify you when they respond",
      });
    } catch (error) {
      console.error('Error sending connection request:', error);
      toast({
        title: "Error sending request",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="h-[420px] flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-muted-foreground" />
      </div>
    );
  }

  return (
    <div className="h-[420px] space-y-4 overflow-auto pr-2">
      <div className="relative">
        <SuggestionsHeader 
          showMessage={showMessage} 
          setShowMessage={setShowMessage} 
        />
        
        <div className={`${showMessage ? 'filter blur-sm' : ''} transition-all duration-200`}>
          <AnimatePresence mode="popLayout">
            {suggestions.length === 0 ? (
              <EmptySuggestions />
            ) : (
              suggestions.map((suggestion) => (
                <motion.div
                  key={suggestion.id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 20 }}
                  className="group"
                >
                  <SuggestionCard
                    {...suggestion}
                    onConnect={handleConnect}
                  />
                </motion.div>
              ))
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};