import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { CoffeeBeanUser } from "@/types/coffee-beans";

export const useUserSearch = () => {
  const [users, setUsers] = useState<CoffeeBeanUser[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUsers = async () => {
    console.log("[useUserSearch] Starting to fetch users");
    setIsLoading(true);
    try {
      const { data: { user: currentUser } } = await supabase.auth.getUser();
      if (!currentUser) {
        console.error('[useUserSearch] No authenticated user found');
        setUsers([]);
        return;
      }

      console.log("[useUserSearch] Current user:", currentUser);

      const { data, error } = await supabase
        .from('users')
        .select('id, name, coffee_beans')
        .order('name');

      if (error) {
        console.error('[useUserSearch] Error fetching users:', error);
        setUsers([]);
      } else {
        console.log('[useUserSearch] Successfully fetched users:', data);
        // Ensure we only include valid users with required fields
        const validUsers = (data || []).filter((user): user is CoffeeBeanUser => {
          const isValid = Boolean(user && typeof user.id === 'string');
          if (!isValid) {
            console.warn('[useUserSearch] Filtered out invalid user:', user);
          }
          return isValid;
        });
        setUsers(validUsers);
      }
    } catch (err) {
      console.error('[useUserSearch] Error in fetchUsers:', err);
      setUsers([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("[useUserSearch] Initial useEffect running");
    fetchUsers();
  }, []);

  return { users, isLoading, fetchUsers };
};