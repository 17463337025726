import RegistrationForm from "@/components/forms/RegistrationForm";

interface RegistrationProps {
  onSuccess?: () => void;
}

const Registration = ({ onSuccess }: RegistrationProps) => {
  return (
    <div className="w-full max-w-md mx-auto bg-white p-6 rounded-2xl border border-hover-coffee/10">
      <h2 className="text-2xl font-semibold text-hover-espresso mb-6 text-center">
        Join our Community
      </h2>
      <RegistrationForm onSuccess={onSuccess} />
    </div>
  );
};

export default Registration;