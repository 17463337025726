import { formatDistanceToNow } from "date-fns";
import { Card, CardContent } from "@/components/ui/card";
import UserAvatar from "./UserAvatar";
import type { Comment } from "@/types/comment";
import type { MarketplaceComment } from "@/types/marketplace";

interface CommentItemProps {
  comment: Comment | MarketplaceComment;
  isReply?: boolean;
  onSuccess: () => void;
}

const CommentItem = ({ comment, isReply = false, onSuccess }: CommentItemProps) => {
  return (
    <div>
      <Card className={`${isReply ? 'bg-hover-foam' : 'bg-white'} shadow-sm hover:shadow-md transition-shadow duration-200`}>
        <CardContent className="p-4">
          <div className="flex items-start gap-3">
            <UserAvatar name={comment.user?.name} size={isReply ? 'sm' : 'md'} />
            <div className="flex-1 min-w-0">
              <div className="flex items-center gap-2 flex-wrap">
                <h4 className="font-medium text-hover-coffee">
                  {comment.user?.name || 'Unknown User'}
                </h4>
                <span className="text-xs text-muted-foreground">
                  {formatDistanceToNow(new Date(comment.created_at), { addSuffix: true })}
                </span>
              </div>
              <p className="mt-1.5 text-gray-700 text-sm leading-relaxed break-words">
                {comment.content}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CommentItem;