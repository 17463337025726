import { useState, Dispatch, SetStateAction } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ChevronDown, ChevronUp } from "lucide-react";
import Typewriter from 'typewriter-effect';
import { MarketplaceRequest } from "./types";

interface RequestFormProps {
  onRequestCreated: () => void;
  setRequests: Dispatch<SetStateAction<MarketplaceRequest[]>>;
}

const RequestForm = ({ onRequestCreated, setRequests }: RequestFormProps) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState<"service" | "product" | "connection">("service");
  const [isOpen, setIsOpen] = useState(false);
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('marketplace_requests')
        .insert({
          user_id: user.id,
          type,
          title,
          description
        });

      if (error) throw error;

      toast({
        title: "Request posted successfully",
        description: "Other users can now see your request and connect with you.",
      });

      setTitle("");
      setDescription("");
      onRequestCreated();
    } catch (error) {
      toast({
        title: "Error posting request",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="bg-hover-cream p-4 rounded-lg shadow-sm">
      <Collapsible open={isOpen} onOpenChange={setIsOpen}>
        <div className="space-y-1">
          <CollapsibleTrigger className="flex items-center justify-between w-full">
            <h2 className="text-lg font-semibold">Make A Request</h2>
            {isOpen ? (
              <ChevronUp className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-500" />
            )}
          </CollapsibleTrigger>
          
          {!isOpen && (
            <div className="h-12 overflow-hidden">
              <div className="text-xs text-muted-foreground opacity-75">
                <Typewriter
                  options={{
                    strings: [
                      'Connect and make business happen.',
                      'Request anything that you need.',
                      'Make the most, and give back!'
                    ],
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    deleteSpeed: 30,
                    cursorClassName: "text-hover-accent"
                  }}
                />
              </div>
            </div>
          )}
        </div>
        
        <CollapsibleContent className="mt-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <Select value={type} onValueChange={(value: "service" | "product" | "connection") => setType(value)}>
              <SelectTrigger>
                <SelectValue placeholder="Select request type" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="service">Service</SelectItem>
                <SelectItem value="product">Product</SelectItem>
                <SelectItem value="connection">Connection</SelectItem>
              </SelectContent>
            </Select>
            
            <Input
              placeholder="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
            />
            
            <Textarea
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
            
            <Button type="submit">Post Request</Button>
          </form>
        </CollapsibleContent>
      </Collapsible>
    </div>
  );
};

export default RequestForm;
