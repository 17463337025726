import { MessageSquare } from "lucide-react";
import UserHoverCard from "@/components/users/UserHoverCard";
import UserAvatar from "@/components/comments/UserAvatar";
import { ChatPreview } from "./types";

interface ChatPreviewItemProps {
  preview: ChatPreview;
  hasUnread: boolean;
  onClick: () => void;
}

const ChatPreviewItem = ({ preview, hasUnread, onClick }: ChatPreviewItemProps) => {
  const formatTimestamp = (timestamp: string) => {
    const date = new Date(timestamp);
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    
    if (days === 0) {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (days === 1) {
      return 'Yesterday';
    } else if (days < 7) {
      return date.toLocaleDateString([], { weekday: 'short' });
    } else {
      return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
    }
  };

  return (
    <div
      onClick={onClick}
      className={`flex items-center gap-3 p-3 rounded-lg cursor-pointer transition-colors ${
        hasUnread ? 'bg-hover-coffee/5 hover:bg-hover-coffee/10' : 'hover:bg-hover-foam'
      }`}
    >
      <UserAvatar 
        name={preview.connectionName} 
        size="md" 
        isVerified={preview.connectionVerified}
      />
      <div className="flex-1 min-w-0">
        <div className="flex justify-between items-start">
          <UserHoverCard
            userId={preview.connectionId}
            userName={preview.connectionName}
            trigger={
              <div className={`font-semibold truncate ${hasUnread ? 'text-hover-coffee' : ''}`}>
                {preview.connectionName}
              </div>
            }
          />
          {preview.lastMessage && (
            <span className="text-xs text-muted-foreground whitespace-nowrap ml-2">
              {formatTimestamp(preview.lastMessage.timestamp)}
            </span>
          )}
        </div>
        <div className="flex items-center gap-1">
          {preview.lastMessage ? (
            <>
              {preview.lastMessage.isSender && (
                <MessageSquare className="h-3 w-3 shrink-0 text-muted-foreground" />
              )}
              <span className={`text-sm truncate ${
                hasUnread ? 'text-hover-coffee font-medium' : 'text-muted-foreground'
              }`}>
                {preview.lastMessage.content}
              </span>
            </>
          ) : (
            <span className="text-sm text-muted-foreground italic">
              No messages yet
            </span>
          )}
          {hasUnread && (
            <div className="ml-auto">
              <div className="w-2.5 h-2.5 rounded-full bg-hover-coffee animate-pulse" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatPreviewItem;