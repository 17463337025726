import { Radio, Headphones } from "lucide-react";
import { Badge } from "@/components/ui/badge";

const Hero = () => {
  return (
    <>
      <div className="text-center space-y-1 animate-fade-in">
        <img 
          src="/lovable-uploads/43117a63-1d7c-40ad-8bc7-3645bfbf5871.png" 
          alt="HoverBean"
          className="mx-auto"
        />
        <div className="w-[10%] h-[1px] bg-hover-espresso/30 rounded-full mx-auto my-16" />
        <div className="mb-5" />
        <p className="text-hover-coffee text-lg max-w-2xl mx-auto mb-16">
          Your daily brew of music and inspiring podcasts, crafted
          with the same care as your favorite artisanal coffee.
        </p>
      </div>

      <div className="mt-16 grid gap-6 sm:grid-cols-2">
        <div className="p-6 rounded-2xl bg-white/50 backdrop-blur-sm border border-hover-coffee/10 transition-transform hover:scale-[1.02]">
          <div className="flex justify-between items-start mb-4">
            <div className="w-10 h-10 rounded-xl bg-hover-coffee/10 flex items-center justify-center">
              <Radio className="w-5 h-5 text-hover-coffee" />
            </div>
            <Badge variant="secondary" className="bg-hover-coffee/10 text-hover-coffee">Coming Soon</Badge>
          </div>
          <h2 className="text-xl font-semibold text-hover-espresso mb-2">
            The Bean Stream
          </h2>
          <p className="text-hover-coffee">
            Tune in to our carefully curated music stream, featuring artists
            from around the world.
          </p>
        </div>

        <div className="p-6 rounded-2xl bg-white/50 backdrop-blur-sm border border-hover-coffee/10 transition-transform hover:scale-[1.02]">
          <div className="flex justify-between items-start mb-4">
            <div className="w-10 h-10 rounded-xl bg-hover-coffee/10 flex items-center justify-center">
              <Headphones className="w-5 h-5 text-hover-coffee" />
            </div>
            <Badge variant="secondary" className="bg-hover-coffee/10 text-hover-coffee">Coming Soon</Badge>
          </div>
          <h2 className="text-xl font-semibold text-hover-espresso mb-2">
            Podcasts
          </h2>
          <p className="text-hover-coffee">
            Explore our collection of podcasts about art, business, and coffee
            culture.
          </p>
        </div>
      </div>
    </>
  );
};

export default Hero;
