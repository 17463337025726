import { Card, CardContent } from "@/components/ui/card";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { Plus, ChevronDown, ChevronUp, Pickaxe } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useUserRole } from "@/hooks/useUserRole";
import BeanMiningOffer from "./BeanMiningOffer";
import AddOfferDialog from "./AddOfferDialog";
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";

const BeanMiningCard = () => {
  const [showAddOffer, setShowAddOffer] = useState(false);
  const [currentOfferIndex, setCurrentOfferIndex] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { isAdmin } = useUserRole();
  const { toast } = useToast();

  const { data: offers, isLoading, error } = useQuery({
    queryKey: ['bean-mining-offers'],
    queryFn: async () => {
      try {
        const { data, error } = await supabase
          .from('bean_mining_offers')
          .select('*')
          .order('created_at', { ascending: false });
        
        if (error) {
          console.error('Error fetching offers:', error);
          toast({
            title: "Error loading offers",
            description: "Please try again later",
            variant: "destructive",
          });
          return [];
        }
        
        return data || [];
      } catch (err) {
        console.error('Error in bean mining offers query:', err);
        toast({
          title: "Error loading offers",
          description: "Please try again later",
          variant: "destructive",
        });
        return [];
      }
    },
    retry: 1,
  });

  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    setStartY(clientY);
  };

  const handleDragMove = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;
    const clientY = 'touches' in e ? e.touches[0].clientY : e.clientY;
    const diff = clientY - startY;

    if (Math.abs(diff) > 50) {
      if (diff > 0 && currentOfferIndex > 0) {
        setCurrentOfferIndex(prev => prev - 1);
        setIsDragging(false);
      } else if (diff < 0 && offers && currentOfferIndex < offers.length - 1) {
        setCurrentOfferIndex(prev => prev + 1);
        setIsDragging(false);
      }
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handlePreviousOffer = () => {
    if (currentOfferIndex > 0) {
      setCurrentOfferIndex(prev => prev - 1);
    }
  };

  const handleNextOffer = () => {
    if (offers && currentOfferIndex < offers.length - 1) {
      setCurrentOfferIndex(prev => prev + 1);
    }
  };

  return (
    <Card className="bg-gradient-to-br from-amber-50 to-amber-100 shadow-xl rounded-xl overflow-hidden border-0">
      <CardContent className="p-4">
        <Collapsible open={!isCollapsed} onOpenChange={(open) => setIsCollapsed(!open)}>
          <CollapsibleTrigger className="w-full">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Pickaxe className="h-5 w-5 text-hover-coffee" />
                <h2 className="text-lg font-medium text-hover-espresso">
                  {isCollapsed ? "Mine & Shine" : "Bean Mining"}
                </h2>
              </div>
              {isCollapsed ? (
                <ChevronDown className="h-4 w-4 text-hover-coffee" />
              ) : (
                <ChevronUp className="h-4 w-4 text-hover-coffee" />
              )}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="space-y-6 pt-6">
              {isAdmin && (
                <div className="mb-6">
                  <Button 
                    onClick={() => setShowAddOffer(true)}
                    className="bg-hover-coffee hover:bg-hover-espresso transition-colors w-full sm:w-auto"
                  >
                    <Plus className="w-4 h-4 mr-2" />
                    Add Offer
                  </Button>
                </div>
              )}

              {isLoading ? (
                <div className="text-center py-8 text-hover-coffee/60">Loading offers...</div>
              ) : error ? (
                <div className="text-center py-8 text-red-500">Failed to load offers. Please try again later.</div>
              ) : offers && offers.length > 0 ? (
                <div className="relative">
                  <div className="animate-fade-in">
                    <BeanMiningOffer key={offers[currentOfferIndex].id} offer={offers[currentOfferIndex]} />
                  </div>
                  <div className="flex justify-center mt-6">
                    <div className="flex flex-col items-center gap-1 text-hover-coffee/60">
                      {currentOfferIndex > 0 && (
                        <button 
                          onClick={handlePreviousOffer}
                          className="p-1 hover:text-hover-coffee transition-colors"
                          aria-label="Previous offer"
                        >
                          <ChevronUp className="w-5 h-5 animate-bounce" />
                        </button>
                      )}
                      <span className="text-xs">
                        {currentOfferIndex + 1} / {offers.length}
                      </span>
                      {currentOfferIndex < offers.length - 1 && (
                        <button 
                          onClick={handleNextOffer}
                          className="p-1 hover:text-hover-coffee transition-colors"
                          aria-label="Next offer"
                        >
                          <ChevronDown className="w-5 h-5 animate-bounce" />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="text-center py-8 text-hover-coffee/60">No offers available</div>
              )}

              {isAdmin && (
                <AddOfferDialog 
                  open={showAddOffer} 
                  onOpenChange={setShowAddOffer} 
                />
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>
      </CardContent>
    </Card>
  );
};

export default BeanMiningCard;