interface UserAvatarProps {
  name: string | undefined;
  size?: 'sm' | 'md' | 'xl' | 'lg';
  isVerified?: boolean;
  imageUrl?: string | null;
}

const UserAvatar = ({ name, size = 'md', isVerified = false, imageUrl }: UserAvatarProps) => {
  const sizeClasses = {
    'sm': 'h-8 w-8 text-sm',
    'md': 'h-10 w-10',
    'lg': 'h-16 w-16 text-xl',
    'xl': 'h-32 w-32 text-4xl'
  }[size];
  
  return (
    <div className="relative">
      {imageUrl ? (
        <img 
          src={imageUrl} 
          alt={name || 'User avatar'} 
          className={`${sizeClasses} rounded-full object-cover ${
            isVerified ? 'ring-2 ring-green-500 ring-offset-2' : ''
          }`}
        />
      ) : (
        <div className={`${sizeClasses} rounded-full bg-hover-coffee text-white flex items-center justify-center ${
          isVerified ? 'ring-2 ring-green-500 ring-offset-2' : ''
        }`}>
          {name?.[0]?.toUpperCase() || '?'}
        </div>
      )}
    </div>
  );
};

export default UserAvatar;