import { useState } from "react";
import ConnectionTabs from "./ConnectionTabs";
import RequestsCarousel from "./RequestsCarousel";
import NewConnectionsList from "./NewConnectionsList";

interface ConnectionRequestCardProps {
  requests: any[];
  currentIndex: number;
  showRequests: boolean;
  setShowRequests: (show: boolean) => void;
  previousRequest: () => void;
  nextRequest: () => void;
  handleRequestAction: (requestId: number, status: 'accepted' | 'rejected') => void;
  onSelectChat: (userId: string) => void;
}

const ConnectionRequestCard = ({
  requests = [], // Provide default empty array
  currentIndex = 0,
  showRequests = false,
  setShowRequests,
  previousRequest,
  nextRequest,
  handleRequestAction,
  onSelectChat,
}: ConnectionRequestCardProps) => {
  const [showNewConnections, setShowNewConnections] = useState(true); // Changed to true by default

  return (
    <div className="bg-white rounded-lg border border-hover-coffee/10">
      <div className="p-4">
        <ConnectionTabs
          showRequests={showRequests}
          requestCount={requests?.length || 0}
          onTabChange={setShowRequests}
          showNewConnections={showNewConnections}
          onNewConnectionsChange={setShowNewConnections}
        />
        {showRequests && !showNewConnections && requests && (
          <RequestsCarousel
            requests={requests}
            currentIndex={currentIndex}
            onPrevious={previousRequest}
            onNext={nextRequest}
            onAction={handleRequestAction}
          />
        )}
        {showNewConnections && <NewConnectionsList onSelectChat={onSelectChat} />}
      </div>
    </div>
  );
};

export default ConnectionRequestCard;