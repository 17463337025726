import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Mic2, Users, Sparkles, Globe, ArrowRight } from "lucide-react";

const ArtistPartner = () => {
  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <div className="space-y-16">
          {/* Hero Section */}
          <section className="text-center space-y-6">
            <h1 className="text-5xl font-bold text-hover-espresso">
              Amplify Your Creative Voice
            </h1>
            <p className="text-xl text-hover-coffee max-w-2xl mx-auto">
              Join our vibrant community of artists and creators to showcase your talent, connect with fans, and grow your artistic career.
            </p>
            <div className="flex justify-center gap-4">
              <Button size="lg" className="bg-hover-coffee hover:bg-hover-espresso">
                <Link to="/register">Join Now</Link>
              </Button>
              <Button size="lg" variant="outline" className="border-hover-coffee text-hover-coffee hover:bg-hover-coffee hover:text-white">
                <Link to="/contact">Contact Us</Link>
              </Button>
            </div>
          </section>

          {/* Benefits Section */}
          <section className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Globe className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Global Exposure</h3>
              <p className="text-hover-coffee">Showcase your work to a worldwide audience of art enthusiasts and potential collaborators.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Users className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Community Connection</h3>
              <p className="text-hover-coffee">Connect with fellow artists, mentors, and industry professionals who can help advance your career.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Sparkles className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Creative Opportunities</h3>
              <p className="text-hover-coffee">Access exclusive performance opportunities, collaborations, and creative projects.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Mic2 className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Artist Development</h3>
              <p className="text-hover-coffee">Get resources and support to develop your artistic skills and build your brand.</p>
            </div>
          </section>

          {/* CTA Section */}
          <section className="text-center space-y-6 bg-hover-coffee text-white p-12 rounded-2xl">
            <h2 className="text-3xl font-bold">Ready to Take Your Art to the Next Level?</h2>
            <p className="text-lg opacity-90 max-w-2xl mx-auto">
              Join our premium community today and connect with fellow artists, mentors, and opportunities that can help you grow your artistic career.
            </p>
            <Button size="lg" className="bg-white text-hover-coffee hover:bg-hover-cream">
              <Link to="/register" className="flex items-center gap-2">
                Get Started <ArrowRight className="w-4 h-4" />
              </Link>
            </Button>
          </section>
        </div>
      </main>
    </div>
  );
};

export default ArtistPartner;