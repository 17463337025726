import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import RequestForm from "./RequestForm";
import RequestCard from "./RequestCard";
import { Button } from "@/components/ui/button";
import { PlusCircle, X } from "lucide-react";
import { useToast } from "@/hooks/use-toast";
import type { MarketplaceRequest } from "./types";

interface MarketplaceViewProps {
  onConnect: (userId: string) => void;
  onChatSelect: (userId: string) => void;
}

const MarketplaceView = ({ onConnect, onChatSelect }: MarketplaceViewProps) => {
  const [requests, setRequests] = useState<MarketplaceRequest[]>([]);
  const [showForm, setShowForm] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    fetchRequests();
    subscribeToRequests();
  }, []);

  const fetchRequests = async () => {
    const { data, error } = await supabase
      .from("marketplace_requests")
      .select(`
        *,
        user:users(
          id,
          name,
          company_name
        )
      `)
      .eq("status", "active")
      .order("created_at", { ascending: false });

    if (error) {
      console.error("Error fetching requests:", error);
      return;
    }

    setRequests(data || []);
  };

  const subscribeToRequests = () => {
    const channel = supabase
      .channel("marketplace_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "marketplace_requests",
        },
        () => {
          fetchRequests();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  };

  return (
    <div className="space-y-6">
      {/* Create Request Section */}
      <div className="relative">
        {!showForm ? (
          <Button
            onClick={() => setShowForm(true)}
            className="w-full bg-hover-coffee text-white hover:bg-hover-espresso shadow-md hover:shadow-lg transition-all duration-300 group"
          >
            <PlusCircle className="h-5 w-5 mr-2 group-hover:rotate-90 transition-transform duration-300" />
            Create New Request
          </Button>
        ) : (
          <div className="relative bg-white rounded-xl shadow-lg border-2 border-hover-coffee/10 p-6 animate-fade-in">
            <Button
              variant="ghost"
              size="icon"
              className="absolute right-4 top-4 text-muted-foreground hover:text-hover-coffee"
              onClick={() => setShowForm(false)}
            >
              <X className="h-5 w-5" />
            </Button>
            <RequestForm
              onRequestCreated={() => {
                setShowForm(false);
                toast({
                  title: "Request created successfully!",
                  description: "Your request is now visible to the community.",
                });
              }}
              setRequests={setRequests}
            />
          </div>
        )}
      </div>

      {/* Requests Grid */}
      <div className="grid grid-cols-1 gap-6">
        {requests.map((request) => (
          <RequestCard
            key={request.id}
            request={request}
            onConnect={onConnect}
            onChatSelect={onChatSelect}
          />
        ))}
        {requests.length === 0 && (
          <div className="text-center py-12 bg-hover-foam/30 rounded-xl border-2 border-dashed border-hover-coffee/20">
            <h3 className="text-lg font-medium text-hover-coffee mb-2">No Active Requests</h3>
            <p className="text-muted-foreground">
              Be the first to create a request and connect with others!
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarketplaceView;