import { Button } from "@/components/ui/button";
import { CheckCircle2, Loader2 } from "lucide-react";
import { ClaimStatus } from "@/hooks/useClaimStatus";

interface ClaimButtonProps {
  claimStatus: ClaimStatus;
  onClaim: () => void;
}

const ClaimButton = ({ claimStatus, onClaim }: ClaimButtonProps) => {
  const getButtonContent = () => {
    switch (claimStatus) {
      case 'pending':
        return (
          <>
            <Loader2 className="w-4 h-4 mr-2 animate-spin" />
            Approval Pending
          </>
        );
      case 'approved':
        return (
          <>
            <CheckCircle2 className="w-4 h-4 mr-2" />
            Beans Earned!
          </>
        );
      default:
        return (
          <>
            <CheckCircle2 className="w-4 h-4 mr-2" />
            Claim Reward
          </>
        );
    }
  };

  return (
    <Button
      className="flex-1 bg-hover-coffee hover:bg-hover-espresso transition-colors"
      onClick={onClaim}
      disabled={claimStatus === 'pending' || claimStatus === 'approved'}
    >
      {getButtonContent()}
    </Button>
  );
};

export default ClaimButton;