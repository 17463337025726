import { supabase } from "@/integrations/supabase/client";

interface RegistrationData {
  email: string;
  password: string;
  name: string;
  companyName: string;
  userType: string;
  linkedinUrl?: string;  // Added this field as optional
}

export const registerUser = async ({
  email,
  password,
  name,
  companyName,
  userType,
  linkedinUrl,
}: RegistrationData) => {
  console.log("Starting registration process...");

  // Step 1: Create auth user
  const { data: authData, error: authError } = await supabase.auth.signUp({
    email,
    password,
  });

  if (authError) {
    console.error("Auth signup error:", authError);
    throw authError;
  }

  if (!authData.user) {
    throw new Error("No user data returned from auth signup");
  }

  console.log("Auth signup successful:", authData);

  // Step 2: Wait for session to be established
  const waitForSession = async (maxAttempts = 5) => {
    for (let i = 0; i < maxAttempts; i++) {
      const { data: { session } } = await supabase.auth.getSession();
      if (session) {
        return session;
      }
      await new Promise(resolve => setTimeout(resolve, 1000)); // Increased timeout
      console.log(`Waiting for session... Attempt ${i + 1}`);
    }
    throw new Error("Failed to establish session after multiple attempts");
  };

  try {
    const session = await waitForSession();
    console.log("Session established:", session);

    // Step 3: Create user profile
    console.log("Creating user profile...");
    const { error: profileError } = await supabase
      .from("users")
      .insert([
        {
          id: authData.user.id,
          email,
          name,
          company_name: companyName,
          user_type: userType,
          linkedin_url: linkedinUrl,  // Added this field
        }
      ]);

    if (profileError) {
      console.error("Profile creation error:", profileError);
      throw profileError;
    }

    console.log("Registration completed successfully");
    return authData;
  } catch (error) {
    console.error("Registration process error:", error);
    throw error;
  }
};