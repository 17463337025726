import { useState, useEffect } from "react";
import { Card } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useUserData } from "@/hooks/useUserData";
import { useToast } from "@/hooks/use-toast";
import { useUserRole } from "@/hooks/useUserRole";
import { supabase } from "@/integrations/supabase/client";
import { useConnectionStatus } from "./hooks/useConnectionStatus";
import RequestEditDialog from "./RequestEditDialog";
import RequestHeader from "./request/RequestHeader";
import RequestContent from "./request/RequestContent";
import RequestActions from "./request/RequestActions";
import CommentSection from "./request/CommentSection";
import type { MarketplaceRequest } from "./types";
import type { MarketplaceComment } from "@/types/marketplace";

interface RequestCardProps {
  request: MarketplaceRequest;
  onConnect: (userId: string) => void;
  onChatSelect: (userId: string) => void;
}

const RequestCard = ({ request, onConnect, onChatSelect }: RequestCardProps) => {
  const { connectionStatus } = useConnectionStatus(request.user_id);
  const navigate = useNavigate();
  const { currentUserId } = useUserData();
  const { toast } = useToast();
  const { isAdmin } = useUserRole();
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editForm, setEditForm] = useState({
    type: request.type,
    title: request.title,
    description: request.description,
  });

  const isOwner = currentUserId === request.user_id;
  const [showComments, setShowComments] = useState(false);
  const [comments, setComments] = useState<MarketplaceComment[]>([]);
  const [isLoadingComments, setIsLoadingComments] = useState(false);

  const handleConnect = () => {
    if (currentUserId === request.user_id) {
      toast({
        title: "Cannot connect with yourself",
        description: "You cannot connect with your own request.",
        variant: "destructive",
      });
      return;
    }
    onConnect(request.user_id);
  };

  const handleChatClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (currentUserId === request.user_id) {
      toast({
        title: "Cannot chat with yourself",
        description: "You cannot start a chat with yourself.",
        variant: "destructive",
      });
      return;
    }
    console.log("[RequestCard] Starting chat with user:", request.user_id);
    
    const messagesComponent = document.querySelector('[data-messages-component]');
    if (messagesComponent) {
      messagesComponent.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'start'
      });
    }
    
    if (typeof onChatSelect === 'function') {
      onChatSelect(request.user_id);
    } else {
      console.error('onChatSelect is not a function');
    }
  };

  const handleDelete = async () => {
    try {
      const { error } = await supabase
        .from('marketplace_requests')
        .delete()
        .eq('id', request.id);

      if (error) throw error;

      toast({
        title: "Request deleted",
        description: "The request has been successfully removed",
      });
    } catch (error) {
      console.error('Error deleting request:', error);
      toast({
        title: "Error deleting request",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  const handleUpdateRequest = async () => {
    try {
      const { error } = await supabase
        .from('marketplace_requests')
        .update({
          type: editForm.type,
          title: editForm.title,
          description: editForm.description,
        })
        .eq('id', request.id);

      if (error) throw error;

      toast({
        title: "Request updated",
        description: "The request has been successfully updated",
      });
      setIsEditDialogOpen(false);
    } catch (error) {
      console.error('Error updating request:', error);
      toast({
        title: "Error updating request",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  const fetchComments = async () => {
    setIsLoadingComments(true);
    try {
      const { data, error } = await supabase
        .from('marketplace_request_comments')
        .select(`
          *,
          user:users (
            name,
            email
          )
        `)
        .eq('request_id', request.id)
        .order('created_at', { ascending: true });

      if (error) throw error;
      console.log("Fetched comments:", data);
      setComments(data || []);
    } catch (error) {
      console.error('Error fetching comments:', error);
      toast({
        title: "Error loading comments",
        variant: "destructive",
      });
    } finally {
      setIsLoadingComments(false);
    }
  };

  const handleRewardComment = async (commentId: number) => {
    try {
      const { data, error } = await supabase
        .rpc('reward_valuable_comment', {
          comment_id: commentId,
          request_author_id: request.user_id
        });

      if (error) throw error;

      toast({
        title: "Comment rewarded!",
        description: "5 coffee beans have been awarded to the comment author.",
      });

      fetchComments();
    } catch (error) {
      console.error('Error rewarding comment:', error);
      toast({
        title: "Error rewarding comment",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    if (showComments) {
      fetchComments();
    }
  }, [showComments]);

  useEffect(() => {
    const channel = supabase
      .channel('marketplace-comments')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'marketplace_request_comments',
          filter: `request_id=eq.${request.id}`
        },
        () => {
          fetchComments();
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [request.id]);

  return (
    <>
      <Card className="overflow-hidden bg-white border-hover-coffee/10 shadow-sm hover:shadow-md transition-all duration-300 rounded-xl">
        <div className="p-5">
          <RequestHeader 
            type={request.type}
            userId={request.user_id}
            userName={request.user.name}
            connectionCount={request.connection_count}
          />
          
          <RequestContent 
            description={request.description}
            title={request.title}
          />

          <RequestActions
            connectionStatus={connectionStatus}
            currentUserId={currentUserId}
            requestUserId={request.user_id}
            onConnect={handleConnect}
            onChat={handleChatClick}
            isAdmin={isAdmin}
            isOwner={isOwner}
            onEdit={() => setIsEditDialogOpen(true)}
            onDelete={handleDelete}
          />
        </div>

        <CommentSection
          showComments={showComments}
          setShowComments={setShowComments}
          comments={comments}
          isLoadingComments={isLoadingComments}
          requestId={request.id}
          onCommentSuccess={fetchComments}
          isOwner={isOwner}
          onRewardComment={handleRewardComment}
          hasRewardedComment={request.has_rewarded_comment}
        />
      </Card>

      <RequestEditDialog
        open={isEditDialogOpen}
        onOpenChange={setIsEditDialogOpen}
        editForm={editForm}
        setEditForm={setEditForm}
        onUpdate={handleUpdateRequest}
      />
    </>
  );
};

export default RequestCard;