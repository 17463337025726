import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import Registration from "@/components/sections/Registration";
import { supabase } from "@/integrations/supabase/client";
import CommentForm from "@/components/comments/CommentForm";
import CommentList from "@/components/comments/CommentList";

const StartupBrewhouseEp1 = () => {
  const [session, setSession] = useState<any>(null);
  const { toast } = useToast();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <h1 className="text-4xl font-bold text-hover-espresso mb-8 text-center">
          The Startup Brewhouse EP 1
        </h1>

        {/* Video Section */}
        <div className="relative aspect-video mb-16 rounded-2xl overflow-hidden shadow-xl">
          <img 
            src="https://images.unsplash.com/photo-1517048676732-d65bc937f952?auto=format&fit=crop&w=1920&q=80"
            alt="Video Thumbnail"
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
            <Button 
              className="bg-hover-coffee hover:bg-hover-espresso text-white px-8 py-6 rounded-full"
              onClick={() => toast({
                title: "Coming Soon",
                description: "The video will be available shortly!",
              })}
            >
              Play Video
            </Button>
          </div>
        </div>

        {/* Forum Section */}
        <section className="bg-white rounded-2xl p-8 shadow-lg">
          <h2 className="text-2xl font-semibold text-hover-espresso mb-6">
            Community Forum
          </h2>

          {/* Comments Section */}
          <div className="space-y-6 mb-12">
            {session ? (
              <div className="space-y-4">
                <CommentForm eventId={1} onSuccess={() => {}} />
              </div>
            ) : (
              <div className="bg-hover-foam p-6 rounded-xl">
                <Registration />
              </div>
            )}

            <div className="mt-8">
              <CommentList eventId={1} />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default StartupBrewhouseEp1;