import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Comment } from "@/types/comment";

interface CommentSectionProps {
  requestId: number;
}

const CommentSection = ({ requestId }: CommentSectionProps) => {
  const [comments, setComments] = useState<Comment[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchComments = async () => {
      try {
        const { data, error } = await supabase
          .from('marketplace_request_comments')
          .select(`
            id,
            content,
            created_at,
            user_id,
            parent_id,
            upvotes,
            users:user_id (
              name,
              email
            )
          `)
          .eq('request_id', requestId)
          .order('created_at', { ascending: true });

        if (error) throw error;
        
        const formattedComments: Comment[] = (data || []).map(comment => ({
          id: comment.id,
          content: comment.content,
          created_at: comment.created_at,
          user_id: comment.user_id,
          parent_id: comment.parent_id,
          event_id: requestId,
          upvotes: comment.upvotes || 0,
          user: comment.users
        }));

        setComments(formattedComments);
      } catch (error) {
        console.error('Error fetching comments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [requestId]);

  if (loading) {
    return <div className="mt-4 text-center">Loading comments...</div>;
  }

  return (
    <div className="mt-4 space-y-4">
      {comments.length > 0 ? (
        comments.map((comment) => (
          <div key={comment.id} className="border-t pt-4">
            <div className="flex justify-between items-start">
              <div>
                <p className="font-medium">{comment.user?.name}</p>
                <p className="text-sm text-muted-foreground">
                  {new Date(comment.created_at).toLocaleDateString()}
                </p>
              </div>
            </div>
            <p className="mt-2">{comment.content}</p>
          </div>
        ))
      ) : (
        <p className="text-center text-muted-foreground">No comments yet</p>
      )}
    </div>
  );
};

export default CommentSection;