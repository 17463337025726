import { useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";

interface MessageReadStatusProps {
  currentUserId: string | null;
  recipientId: string;
}

const MessageReadStatus = ({ currentUserId, recipientId }: MessageReadStatusProps) => {
  useEffect(() => {
    const markMessagesAsRead = async () => {
      if (!currentUserId) return;
      
      try {
        const { error } = await supabase
          .from('direct_messages')
          .update({ read: true })
          .eq('recipient_id', currentUserId)
          .eq('sender_id', recipientId);

        if (error) {
          console.error('Error marking messages as read:', error);
        }
      } catch (err) {
        console.error('Error in markMessagesAsRead:', err);
      }
    };

    markMessagesAsRead();
  }, [recipientId, currentUserId]);

  return null;
};

export default MessageReadStatus;