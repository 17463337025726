import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Building2, ChartBar, Network, Target, ArrowRight } from "lucide-react";

const VCPartner = () => {
  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <div className="space-y-16">
          {/* Hero Section */}
          <section className="text-center space-y-6">
            <h1 className="text-5xl font-bold text-hover-espresso">
              Connect with Promising Startups
            </h1>
            <p className="text-xl text-hover-coffee max-w-2xl mx-auto">
              Join our exclusive network of venture capitalists and gain early access to innovative startups and investment opportunities.
            </p>
            <div className="flex justify-center gap-4">
              <Button size="lg" className="bg-hover-coffee hover:bg-hover-espresso">
                <Link to="/register">Join Now</Link>
              </Button>
              <Button size="lg" variant="outline" className="border-hover-coffee text-hover-coffee hover:bg-hover-coffee hover:text-white">
                <Link to="/contact">Contact Us</Link>
              </Button>
            </div>
          </section>

          {/* Benefits Section */}
          <section className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Target className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Deal Flow Access</h3>
              <p className="text-hover-coffee">Get early access to vetted startups and investment opportunities across various sectors.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Network className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Network Expansion</h3>
              <p className="text-hover-coffee">Connect with fellow investors, industry experts, and innovative founders.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <ChartBar className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Market Intelligence</h3>
              <p className="text-hover-coffee">Access exclusive market insights and trend analysis to make informed investment decisions.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Building2 className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Portfolio Growth</h3>
              <p className="text-hover-coffee">Support your portfolio companies with our extensive resource network and community.</p>
            </div>
          </section>

          {/* CTA Section */}
          <section className="text-center space-y-6 bg-hover-coffee text-white p-12 rounded-2xl">
            <h2 className="text-3xl font-bold">Ready to Expand Your Investment Portfolio?</h2>
            <p className="text-lg opacity-90 max-w-2xl mx-auto">
              Join our premium community today and connect with innovative startups and fellow investors who share your vision for the future.
            </p>
            <Button size="lg" className="bg-white text-hover-coffee hover:bg-hover-cream">
              <Link to="/register" className="flex items-center gap-2">
                Get Started <ArrowRight className="w-4 h-4" />
              </Link>
            </Button>
          </section>
        </div>
      </main>
    </div>
  );
};

export default VCPartner;