import { useState, useEffect } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import UserHoverCard from "@/components/users/UserHoverCard";

interface ClaimApprovalDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  offerId: number;
  offerTitle: string;
  rewardAmount: number;
}

const ClaimApprovalDialog = ({ open, onOpenChange, offerId, offerTitle, rewardAmount }: ClaimApprovalDialogProps) => {
  const [claimDetails, setClaimDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    if (open) {
      fetchClaimDetails();
    }
  }, [open, offerId]);

  const fetchClaimDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('bean_mining_confirmations')
        .select(`
          *,
          users:user_id (
            name,
            email,
            company_name,
            profile_image_url
          )
        `)
        .eq('offer_id', offerId)
        .eq('status', 'pending')
        .maybeSingle();

      if (error) throw error;
      setClaimDetails(data);
    } catch (error) {
      console.error('Error fetching claim details:', error);
      toast({
        title: "Error fetching claim details",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  const handleApproval = async (approved: boolean) => {
    setLoading(true);
    try {
      const { error } = await supabase
        .from('bean_mining_confirmations')
        .update({
          status: approved ? 'approved' : 'rejected',
          approved_at: new Date().toISOString()
        })
        .eq('offer_id', offerId)
        .eq('status', 'pending');

      if (error) throw error;

      toast({
        title: approved ? "Claim approved" : "Claim rejected",
        description: approved 
          ? `${claimDetails?.users?.name} has been awarded ${rewardAmount} coffee beans`
          : "The claim has been rejected",
      });

      onOpenChange(false);
    } catch (error) {
      console.error('Error updating claim status:', error);
      toast({
        title: "Error updating claim",
        description: "Please try again later",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Review Claim for "{offerTitle}"</DialogTitle>
          <DialogDescription>
            Review the proof submitted by the user and approve or reject their claim.
          </DialogDescription>
        </DialogHeader>

        {claimDetails ? (
          <div className="space-y-4">
            <div className="flex items-start gap-4">
              <div className="flex-1">
                <h4 className="text-sm font-semibold mb-1">Claimed by</h4>
                <UserHoverCard 
                  userId={claimDetails.user_id} 
                  userName={claimDetails.users.name}
                />
              </div>
              <div>
                <h4 className="text-sm font-semibold mb-1">Reward</h4>
                <span className="text-sm">{rewardAmount} beans</span>
              </div>
            </div>

            <div>
              <h4 className="text-sm font-semibold mb-1">Proof submitted</h4>
              <p className="text-sm text-muted-foreground bg-muted p-3 rounded-md">
                {claimDetails.proof_text}
              </p>
            </div>

            <div className="flex justify-end gap-2 pt-4">
              <Button
                variant="outline"
                onClick={() => handleApproval(false)}
                disabled={loading}
              >
                Reject
              </Button>
              <Button
                onClick={() => handleApproval(true)}
                disabled={loading}
              >
                Approve & Award Beans
              </Button>
            </div>
          </div>
        ) : (
          <div className="py-8 text-center text-muted-foreground">
            Loading claim details...
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ClaimApprovalDialog;