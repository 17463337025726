import { MoreHorizontal } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import NavLinks from "./NavLinks";
import AuthButton from "./AuthButton";
import SettingsDropdown from "./SettingsDropdown";
import UserTypeToggle from "./UserTypeToggle";

interface DesktopMenuProps {
  session: any;
}

const DesktopMenu = ({ session }: DesktopMenuProps) => {
  return (
    <div className="hidden md:flex items-center gap-4">
      {session && <UserTypeToggle />}
      
      <div className="flex items-center gap-2">
        {session ? (
          <>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" size="icon">
                  <MoreHorizontal className="h-5 w-5" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="w-48 bg-white">
                <NavLinks dropdown />
              </DropdownMenuContent>
            </DropdownMenu>

            <SettingsDropdown />
            <AuthButton />
          </>
        ) : (
          <AuthButton />
        )}
      </div>
    </div>
  );
};

export default DesktopMenu;