import { useState } from "react";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { registerUser } from "@/lib/auth/registration";
import EmailField from "./registration/EmailField";
import PasswordFields from "./registration/PasswordFields";
import ProfileFields from "./registration/ProfileFields";

interface RegistrationFormProps {
  onSuccess?: () => void;
}

const RegistrationForm = ({ onSuccess }: RegistrationFormProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userType, setUserType] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const validateForm = () => {
    if (password !== confirmPassword) {
      toast({
        title: "Passwords do not match",
        description: "Please ensure both passwords match.",
        variant: "destructive",
      });
      return false;
    }

    if (password.length < 6) {
      toast({
        title: "Password too short",
        description: "Password must be at least 6 characters long.",
        variant: "destructive",
      });
      return false;
    }

    if (!userType) {
      toast({
        title: "User type required",
        description: "Please select a user type.",
        variant: "destructive",
      });
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      await registerUser({
        email,
        password,
        name,
        companyName,
        userType,
        linkedinUrl,
      });

      toast({
        title: "Registration successful!",
        description: "Please check your email to verify your account.",
      });

      // Clear form
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setName("");
      setCompanyName("");
      setUserType("");
      setLinkedinUrl("");
      
      onSuccess?.();

    } catch (error: any) {
      console.error("Registration error:", error);
      const errorMessage = error.message || "An unexpected error occurred";
      toast({
        title: "Registration failed",
        description: errorMessage,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <EmailField 
        email={email} 
        setEmail={setEmail} 
        disabled={isLoading} 
      />
      
      <PasswordFields 
        password={password}
        setPassword={setPassword}
        confirmPassword={confirmPassword}
        setConfirmPassword={setConfirmPassword}
        disabled={isLoading}
      />

      <ProfileFields 
        name={name}
        setName={setName}
        companyName={companyName}
        setCompanyName={setCompanyName}
        userType={userType}
        setUserType={setUserType}
        linkedinUrl={linkedinUrl}
        setLinkedinUrl={setLinkedinUrl}
        disabled={isLoading}
      />

      <Button 
        type="submit" 
        className="w-full bg-hover-coffee hover:bg-hover-espresso text-white"
        disabled={isLoading}
      >
        {isLoading ? "Registering..." : "Register"}
      </Button>
    </form>
  );
};

export default RegistrationForm;