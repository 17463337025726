import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { Lightbulb, Rocket, Users, Target, ArrowRight } from "lucide-react";

const AcceleratorPartner = () => {
  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <div className="space-y-16">
          {/* Hero Section */}
          <section className="text-center space-y-6">
            <h1 className="text-5xl font-bold text-hover-espresso">
              Accelerate Innovation Together
            </h1>
            <p className="text-xl text-hover-coffee max-w-2xl mx-auto">
              Partner with us to expand your accelerator's reach and connect with promising startups and entrepreneurs.
            </p>
            <div className="flex justify-center gap-4">
              <Button size="lg" className="bg-hover-coffee hover:bg-hover-espresso">
                <Link to="/register">Join Now</Link>
              </Button>
              <Button size="lg" variant="outline" className="border-hover-coffee text-hover-coffee hover:bg-hover-coffee hover:text-white">
                <Link to="/contact">Contact Us</Link>
              </Button>
            </div>
          </section>

          {/* Benefits Section */}
          <section className="grid md:grid-cols-2 gap-8">
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Target className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Deal Flow Access</h3>
              <p className="text-hover-coffee">Connect with pre-vetted startups that match your accelerator's focus and criteria.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Users className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Mentor Network</h3>
              <p className="text-hover-coffee">Access our network of experienced mentors and industry experts for your portfolio companies.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Rocket className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Program Amplification</h3>
              <p className="text-hover-coffee">Promote your accelerator programs to a wider audience of qualified startups.</p>
            </div>
            <div className="space-y-4 p-6 bg-white rounded-2xl shadow-md">
              <div className="w-12 h-12 rounded-xl bg-hover-cream flex items-center justify-center">
                <Lightbulb className="w-6 h-6 text-hover-coffee" />
              </div>
              <h3 className="text-xl font-semibold text-hover-espresso">Resource Sharing</h3>
              <p className="text-hover-coffee">Share and access resources with other accelerators to enhance your program offerings.</p>
            </div>
          </section>

          {/* CTA Section */}
          <section className="text-center space-y-6 bg-hover-coffee text-white p-12 rounded-2xl">
            <h2 className="text-3xl font-bold">Ready to Expand Your Accelerator's Impact?</h2>
            <p className="text-lg opacity-90 max-w-2xl mx-auto">
              Join our premium community today and connect with promising startups, fellow accelerators, and resources to enhance your program.
            </p>
            <Button size="lg" className="bg-white text-hover-coffee hover:bg-hover-cream">
              <Link to="/register" className="flex items-center gap-2">
                Get Started <ArrowRight className="w-4 h-4" />
              </Link>
            </Button>
          </section>
        </div>
      </main>
    </div>
  );
};

export default AcceleratorPartner;