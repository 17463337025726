import { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Download } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

const Install = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const { toast } = useToast();

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (!deferredPrompt) {
      toast({
        title: "Installation not available",
        description: "Your browser doesn't support PWA installation or the app is already installed.",
        variant: "destructive"
      });
      return;
    }

    deferredPrompt.prompt();
    const { outcome } = await deferredPrompt.userChoice;
    
    if (outcome === 'accepted') {
      toast({
        title: "Success!",
        description: "HoverBean has been installed successfully.",
      });
    }
    
    setDeferredPrompt(null);
  };

  return (
    <div className="min-h-screen bg-hover-cream pt-24">
      <main className="container max-w-4xl mx-auto px-4 pb-32">
        <div className="text-center space-y-8">
          <img 
            src="/lovable-uploads/43117a63-1d7c-40ad-8bc7-3645bfbf5871.png" 
            alt="HoverBean"
            className="w-32 h-32 mx-auto"
          />
          <h1 className="text-4xl font-bold text-hover-espresso">
            Install HoverBean
          </h1>
          <p className="text-hover-coffee text-lg max-w-2xl mx-auto">
            Install HoverBean on your device for quick access to your coffee community, music, and podcasts.
          </p>
          <Button
            onClick={handleInstallClick}
            className="bg-hover-coffee hover:bg-hover-espresso text-white px-8 py-6 text-lg"
            disabled={!deferredPrompt}
          >
            <Download className="mr-2 h-6 w-6" />
            Install App
          </Button>
          {!deferredPrompt && (
            <p className="text-hover-coffee/80 text-sm">
              Note: If you can't install the app, it might be already installed or your browser might not support PWA installation.
            </p>
          )}
        </div>
      </main>
    </div>
  );
};

export default Install;