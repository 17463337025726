import ConnectionRequestCard from "../ConnectionRequestCard";
import NetworkMessagesCard from "./NetworkMessagesCard";

interface NetworkFeatureCardsProps {
  requests: any[];
  currentIndex: number;
  showRequests: boolean;
  setShowRequests: (show: boolean) => void;
  previousRequest: () => void;
  nextRequest: () => void;
  handleRequestAction: (requestId: number, status: 'accepted' | 'rejected') => void;
  selectedUserId: string | null;
  setSelectedUserId: (id: string | null) => void;
  showNetwork: boolean;
  setShowNetwork: (show: boolean) => void;
  unreadMessages: Set<string>;
}

const NetworkFeatureCards = ({
  requests,
  currentIndex,
  showRequests,
  setShowRequests,
  previousRequest,
  nextRequest,
  handleRequestAction,
  selectedUserId,
  setSelectedUserId,
  showNetwork,
  setShowNetwork,
  unreadMessages,
}: NetworkFeatureCardsProps) => {
  // This function will be passed down to handle chat selection
  const handleChatSelect = (userId: string) => {
    console.log("[NetworkFeatureCards] Handling chat select for user:", userId);
    setSelectedUserId(userId);
    setShowNetwork(true);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <div className="flex flex-col">
        <ConnectionRequestCard
          requests={requests}
          currentIndex={currentIndex}
          showRequests={showRequests}
          setShowRequests={setShowRequests}
          previousRequest={previousRequest}
          nextRequest={nextRequest}
          handleRequestAction={handleRequestAction}
          onSelectChat={handleChatSelect}
        />
      </div>

      <div className="flex flex-col">
        <NetworkMessagesCard
          selectedUserId={selectedUserId}
          setSelectedUserId={setSelectedUserId}
          showNetwork={showNetwork}
          setShowNetwork={setShowNetwork}
          unreadMessages={unreadMessages}
          showMarketplace={false}
        />
      </div>
    </div>
  );
};

export default NetworkFeatureCards;