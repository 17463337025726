import { TableCell, TableRow } from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import UserAvatar from "@/components/comments/UserAvatar";
import type { User } from "@/types/user";

interface UserTableRowProps {
  user: User;
  isSelected: boolean;
  onSelectUser: (userId: string, checked: boolean) => void;
}

const UserTableRow = ({ user, isSelected, onSelectUser }: UserTableRowProps) => {
  return (
    <TableRow className="hover:bg-hover-cream/50 transition-colors duration-200">
      <TableCell className="w-12">
        <Checkbox
          checked={isSelected}
          onCheckedChange={(checked) => onSelectUser(user.id, !!checked)}
        />
      </TableCell>
      <TableCell className="w-12">
        <div className={`relative ${user.verified ? 'ring-2 ring-green-500 ring-offset-2 rounded-full' : ''}`}>
          <UserAvatar name={user.name} size="sm" />
        </div>
      </TableCell>
      <TableCell className="font-medium">{user.name}</TableCell>
      <TableCell className="text-muted-foreground">{user.email}</TableCell>
      <TableCell>{user.company_name}</TableCell>
      <TableCell>
        <span className="px-2 py-1 rounded-full text-xs font-medium bg-hover-cream text-hover-espresso">
          {user.user_type}
        </span>
      </TableCell>
      <TableCell>
        {user.banned ? (
          <span className="px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-700">Banned</span>
        ) : user.verified ? (
          <span className="px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-700">Verified</span>
        ) : (
          <span className="px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-700">Pending</span>
        )}
      </TableCell>
      <TableCell className="text-muted-foreground">
        {new Date(user.created_at).toLocaleDateString()}
      </TableCell>
    </TableRow>
  );
};

export default UserTableRow;