import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { Calendar, ChevronDown, ChevronUp, Plus, Edit2, Trash2 } from "lucide-react";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import type { EmblaOptionsType } from 'embla-carousel';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useUserRole } from "@/hooks/useUserRole";
import EventDialog from "./events/EventDialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { Event } from "@/types/event";
import { Link } from "react-router-dom";

interface EventsCardProps {
  events: Event[];
}

const EventsCard = ({ events }: EventsCardProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [expandedDescriptions, setExpandedDescriptions] = useState<number[]>([]);
  const { isAdmin } = useUserRole();
  const { toast } = useToast();
  const carouselOptions: EmblaOptionsType = { align: "start", loop: true, slidesToScroll: 1 };

  const handleEditEvent = (event: Event) => {
    setSelectedEvent(event);
    setIsDialogOpen(true);
  };

  const handleDeleteEvent = async (eventId: number) => {
    try {
      const { error } = await supabase
        .from('events')
        .delete()
        .eq('id', eventId);

      if (error) throw error;

      toast({
        title: "Event deleted successfully",
      });
    } catch (error) {
      console.error('Error deleting event:', error);
      toast({
        title: "Error deleting event",
        variant: "destructive",
      });
    }
  };

  const toggleDescription = (eventId: number) => {
    setExpandedDescriptions(prev => 
      prev.includes(eventId) 
        ? prev.filter(id => id !== eventId)
        : [...prev, eventId]
    );
  };

  return (
    <Card className="bg-gradient-to-br from-[#1A1F2C] to-[#2A2F3C] text-white">
      <CardHeader className="p-4">
        <Collapsible open={!isCollapsed} onOpenChange={(open) => setIsCollapsed(!open)}>
          <CollapsibleTrigger className="w-full">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <Calendar className="h-5 w-5 text-[#9b87f5]" />
                <h2 className="text-lg font-medium">
                  {isCollapsed ? "What's Brewing?" : "Events"}
                </h2>
              </div>
              {isCollapsed ? (
                <ChevronDown className="h-4 w-4" />
              ) : (
                <ChevronUp className="h-4 w-4" />
              )}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="pt-4">
              {isAdmin && (
                <div className="mb-4">
                  <Button
                    onClick={() => {
                      setSelectedEvent(null);
                      setIsDialogOpen(true);
                    }}
                    variant="outline"
                    size="sm"
                    className="w-full bg-[#9b87f5] hover:bg-[#8b7355] text-white border-none"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Event
                  </Button>
                </div>
              )}
              
              {events && events.length > 0 ? (
                <Carousel opts={carouselOptions} className="w-full max-w-full">
                  <CarouselContent>
                    {events
                      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                      .map((event) => (
                        <CarouselItem key={event.id} className="basis-full">
                          <div className="rounded-lg overflow-hidden">
                            <img 
                              src={event.image_url} 
                              alt={event.title}
                              className="w-full h-32 object-cover"
                            />
                            <div className="p-3 space-y-2">
                              <h3 className="font-semibold text-sm">{event.title}</h3>
                              <p className="text-xs text-gray-300">{event.date}</p>
                              {event.description && (
                                <div className="mt-2">
                                  <p className="text-xs text-gray-300">
                                    {expandedDescriptions.includes(event.id)
                                      ? event.description
                                      : `${event.description.slice(0, 100)}${event.description.length > 100 ? '...' : ''}`
                                    }
                                  </p>
                                  {event.description.length > 100 && (
                                    <Button
                                      variant="link"
                                      size="sm"
                                      onClick={() => toggleDescription(event.id)}
                                      className="text-[#9b87f5] hover:text-[#8b7355] p-0 h-auto mt-1"
                                    >
                                      {expandedDescriptions.includes(event.id) ? 'Read Less' : 'Read More'}
                                    </Button>
                                  )}
                                </div>
                              )}
                              <div className="flex justify-between items-center mt-2">
                                {event.video_url ? (
                                  <Link to={`/${event.title.toLowerCase().replace(/\s+/g, '-')}`}>
                                    <Button 
                                      variant="outline"
                                      size="sm"
                                      className="text-[#9b87f5] hover:text-white hover:bg-[#9b87f5]"
                                    >
                                      View Highlights
                                    </Button>
                                  </Link>
                                ) : event.registration_link ? (
                                  <a href={event.registration_link} target="_blank" rel="noopener noreferrer">
                                    <Button 
                                      variant="outline"
                                      size="sm"
                                      className="text-[#9b87f5] hover:text-white hover:bg-[#9b87f5]"
                                    >
                                      Register
                                    </Button>
                                  </a>
                                ) : null}
                                {isAdmin && (
                                  <div className="flex gap-2">
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={() => handleEditEvent(event)}
                                      className="text-[#9b87f5] hover:text-white hover:bg-[#9b87f5]"
                                    >
                                      <Edit2 className="h-4 w-4" />
                                    </Button>
                                    <Button
                                      variant="ghost"
                                      size="sm"
                                      onClick={() => handleDeleteEvent(event.id)}
                                      className="text-red-400 hover:text-white hover:bg-red-500"
                                    >
                                      <Trash2 className="h-4 w-4" />
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </CarouselItem>
                    ))}
                  </CarouselContent>
                  <CarouselPrevious className="absolute -left-4 bg-[#9b87f5] hover:bg-[#8b7355] border-none" />
                  <CarouselNext className="absolute -right-4 bg-[#9b87f5] hover:bg-[#8b7355] border-none" />
                </Carousel>
              ) : (
                <div className="text-center py-4 text-gray-400">No upcoming events</div>
              )}
            </div>
          </CollapsibleContent>
        </Collapsible>
      </CardHeader>
      <EventDialog 
        open={isDialogOpen} 
        onOpenChange={setIsDialogOpen}
        event={selectedEvent}
      />
    </Card>
  );
};

export default EventsCard;