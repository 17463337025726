import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";

const BeansInfoCarousel = () => {
  return (
    <Carousel className="w-full mt-4">
      <CarouselContent>
        <CarouselItem>
          <div className="bg-hover-foam/50 p-3 rounded-lg text-sm text-hover-espresso/80 border border-hover-coffee/10">
            <p>You need at least 1 coffee bean to use the platform. Earn beans through activities or buy them to stay connected!</p>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="bg-hover-foam/50 p-3 rounded-lg text-sm text-hover-espresso/80 border border-hover-coffee/10">
            <p>You lose 3 beans per day! 😢</p>
          </div>
        </CarouselItem>
        <CarouselItem>
          <div className="bg-hover-foam/50 p-3 rounded-lg text-sm text-hover-espresso/80 border border-hover-coffee/10">
            <p>Don't worry it's pretty easy to earn / mine beans! 😊</p>
          </div>
        </CarouselItem>
      </CarouselContent>
      <CarouselPrevious />
      <CarouselNext />
    </Carousel>
  );
};

export default BeansInfoCarousel;