import { motion } from 'framer-motion';

export const EmptySuggestions = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="h-[300px] flex flex-col items-center justify-center text-center"
    >
      <p className="text-muted-foreground">No suggestions available</p>
      <p className="text-sm text-muted-foreground">Join more groups to meet new people!</p>
    </motion.div>
  );
};