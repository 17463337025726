import { TooltipProvider, Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import UserHoverCard from "@/components/users/UserHoverCard";
import { Users } from "lucide-react";

interface RequestHeaderProps {
  type: string;
  userId: string;
  userName: string;
  connectionCount: number;
}

const RequestHeader = ({ type, userId, userName, connectionCount }: RequestHeaderProps) => {
  return (
    <div className="flex items-start justify-between">
      <div className="flex-1">
        <span className="inline-block px-3 py-1 text-xs font-medium rounded-full bg-hover-foam text-hover-coffee mb-2">
          {type}
        </span>
        <h3 className="font-semibold text-gray-900">
          <UserHoverCard userId={userId} userName={userName} />
        </h3>
      </div>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="flex items-center gap-1.5 text-gray-500 bg-gray-50 px-2 py-1 rounded-full ml-2">
              <Users className="h-4 w-4" />
              <span className="text-sm font-medium">{connectionCount || 0}</span>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>Number of people connected for this request</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export default RequestHeader;