import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';
import { NearbyUser } from '@/components/myspace/nearby/types';

export const useNearbyUsers = (locationEnabled: boolean) => {
  const [nearbyUsers, setNearbyUsers] = useState<NearbyUser[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();

  const fetchNearbyUsers = async () => {
    if (!locationEnabled) {
      console.log('Location not enabled, skipping nearby users fetch');
      return;
    }
    
    setIsLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.log('No authenticated user found');
        return;
      }

      console.log('Fetching nearby users for user:', user.id);
      const { data, error } = await supabase
        .rpc('find_nearby_users', { p_user_id: user.id, p_radius_km: 5 });

      if (error) {
        console.error('Error from find_nearby_users RPC:', error);
        throw error;
      }

      console.log('Nearby users found:', data);
      setNearbyUsers(data || []);
    } catch (error) {
      console.error('Error fetching nearby users:', error);
      toast({
        title: "Error finding nearby users",
        description: "Please try again later",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log('Location enabled status:', locationEnabled);
    if (locationEnabled) {
      fetchNearbyUsers();
      const interval = setInterval(fetchNearbyUsers, 60000);
      return () => clearInterval(interval);
    }
  }, [locationEnabled]);

  return { nearbyUsers, isLoading, fetchNearbyUsers };
};