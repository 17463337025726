import { Button } from "@/components/ui/button";
import { MessageCircle } from "lucide-react";

interface RequestActionsProps {
  connectionStatus: string;
  currentUserId: string | undefined;
  requestUserId: string;
  onConnect: () => void;
  onChat: (e: React.MouseEvent) => void;
  isAdmin: boolean;
  isOwner: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const RequestActions = ({
  connectionStatus,
  currentUserId,
  requestUserId,
  onConnect,
  onChat,
  isAdmin,
  isOwner,
  onEdit,
  onDelete,
}: RequestActionsProps) => {
  return (
    <div className="flex items-center gap-2 pt-4 mt-4 border-t border-hover-coffee/10">
      {connectionStatus === 'connected' && currentUserId !== requestUserId && (
        <Button
          variant="ghost"
          size="sm"
          onClick={onChat}
          className="hover:bg-hover-cream text-hover-coffee/70 hover:text-hover-coffee transition-colors duration-200"
        >
          <MessageCircle className="w-4 h-4 mr-1" />
          Chat
        </Button>
      )}
      
      {connectionStatus === 'none' && currentUserId !== requestUserId && (
        <Button 
          variant="outline"
          size="sm"
          onClick={onConnect}
          className="bg-hover-cream/50 hover:bg-hover-cream text-hover-coffee border-hover-coffee/20 hover:border-hover-coffee/30 transition-all duration-200"
        >
          Connect
        </Button>
      )}
      {connectionStatus === 'pending' && (
        <Button 
          variant="outline" 
          size="sm"
          disabled
          className="opacity-50"
        >
          Pending
        </Button>
      )}

      {(isAdmin || isOwner) && (
        <>
          <Button
            variant="ghost"
            size="sm"
            onClick={onEdit}
            className="hover:bg-hover-cream hover:text-hover-coffee transition-colors"
          >
            Edit
          </Button>
          <Button
            variant="ghost"
            size="sm"
            onClick={onDelete}
            className="hover:bg-red-50 hover:text-red-600 transition-colors"
          >
            Delete
          </Button>
        </>
      )}
    </div>
  );
};

export default RequestActions;