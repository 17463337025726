import { MessageCircle } from "lucide-react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import CommentItem from "./CommentItem";
import type { ThreadedMarketplaceComment } from "@/types/marketplace";

interface CommentListProps {
  requestId?: number;
  eventId?: number;
}

const CommentList = ({ requestId, eventId }: CommentListProps) => {
  const { data: comments = [], refetch } = useQuery({
    queryKey: ['comments', { requestId, eventId }],
    queryFn: async () => {
      if (requestId) {
        const { data, error } = await supabase
          .from('marketplace_request_comments')
          .select(`
            *,
            user:users (
              id,
              name,
              company_name
            )
          `)
          .eq('request_id', requestId)
          .order('created_at', { ascending: false });

        if (error) throw error;
        return data;
      } else if (eventId) {
        const { data, error } = await supabase
          .from('comments')
          .select(`
            *,
            user:users (
              id,
              name,
              company_name
            )
          `)
          .eq('event_id', eventId)
          .order('created_at', { ascending: false });

        if (error) throw error;
        return data;
      }
      return [];
    },
  });

  // Organize comments into a hierarchical structure
  const organizeComments = () => {
    const commentMap = new Map();
    const rootComments: ThreadedMarketplaceComment[] = [];

    // Initialize the map with all comments
    comments.forEach(comment => {
      commentMap.set(comment.id, {
        ...comment,
        replies: []
      });
    });

    // Organize comments into their hierarchy
    comments.forEach(comment => {
      const threadedComment = commentMap.get(comment.id);
      
      if (!comment.parent_id) {
        // This is a root level comment
        rootComments.push(threadedComment);
      } else {
        // This is a reply - add it to its parent's replies
        const parentComment = commentMap.get(comment.parent_id);
        if (parentComment) {
          parentComment.replies.push(threadedComment);
        }
      }
    });

    // Sort root comments and their replies by creation date (newest first)
    rootComments.sort((a, b) => 
      new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

    rootComments.forEach(comment => {
      comment.replies.sort((a, b) => 
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });

    return rootComments;
  };

  const threadedComments = organizeComments();

  if (comments.length === 0) {
    return (
      <div className="text-center py-8 text-muted-foreground">
        <MessageCircle className="mx-auto h-12 w-12 mb-4 opacity-50" />
        <p>No comments yet. Be the first to share your thoughts!</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {threadedComments.map(comment => (
        <div key={comment.id}>
          <CommentItem comment={comment} onSuccess={refetch} />
          
          {comment.replies && comment.replies.length > 0 && (
            <div className="pl-8 mt-4 space-y-4">
              {comment.replies.map(reply => (
                <div key={reply.id} className="border-l-2 border-hover-coffee/10 pl-4">
                  <CommentItem 
                    comment={reply} 
                    isReply={true}
                    onSuccess={refetch}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CommentList;