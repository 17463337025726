import { motion } from 'framer-motion';
import { Users, MapPin, ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface EnableLocationCardProps {
  onEnable: () => void;
}

export const EnableLocationCard = ({ onEnable }: EnableLocationCardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="h-[420px] flex flex-col items-center justify-center space-y-6 p-8 relative overflow-hidden"
    >
      <motion.div 
        className="absolute top-0 left-0 w-full h-1"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse" }}
        style={{ background: 'linear-gradient(90deg, transparent, var(--hover-accent), transparent)' }}
      />
      
      <motion.div
        animate={{ 
          scale: [1, 1.1, 1],
          opacity: [0.7, 1, 0.7]
        }}
        transition={{ duration: 2, repeat: Infinity }}
        className="relative"
      >
        <motion.div
          className="absolute -left-8 -top-8"
          animate={{ 
            rotate: [0, 10, -10, 0],
            scale: [1, 1.1, 0.9, 1]
          }}
          transition={{ duration: 4, repeat: Infinity }}
        >
          <Users className="h-8 w-8 text-hover-coffee opacity-40" />
        </motion.div>
        
        <MapPin className="h-16 w-16 text-hover-accent" />
        
        <motion.div
          className="absolute -right-8 -bottom-8"
          animate={{ 
            rotate: [0, -10, 10, 0],
            scale: [1, 0.9, 1.1, 1]
          }}
          transition={{ duration: 4, repeat: Infinity, delay: 0.5 }}
        >
          <Users className="h-8 w-8 text-hover-coffee opacity-40" />
        </motion.div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="text-center space-y-3"
      >
        <h3 className="text-2xl font-semibold text-hover-espresso">Connect with Nearby Professionals</h3>
        <p className="text-hover-coffee max-w-sm">
          Enable location services to discover and connect with professionals in your area
        </p>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.4 }}
        className="flex items-center space-x-2"
      >
        <Button 
          onClick={onEnable}
          className="bg-hover-accent hover:bg-hover-coffee transition-colors duration-300"
        >
          Enable Location
          <motion.div
            animate={{ x: [0, 4, 0] }}
            transition={{ duration: 1.5, repeat: Infinity }}
            className="ml-2"
          >
            <ArrowRight className="h-4 w-4" />
          </motion.div>
        </Button>
      </motion.div>

      <motion.div 
        className="absolute bottom-0 left-0 w-full h-1"
        initial={{ scaleX: 0 }}
        animate={{ scaleX: 1 }}
        transition={{ duration: 1.5, repeat: Infinity, repeatType: "reverse", delay: 0.75 }}
        style={{ background: 'linear-gradient(90deg, transparent, var(--hover-accent), transparent)' }}
      />
    </motion.div>
  );
};