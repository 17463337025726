import { supabase } from '@/integrations/supabase/client';
import { toast } from '@/hooks/use-toast';

export const useLocationUpdater = () => {
  const updateLocation = async (position: GeolocationPosition) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    return supabase
      .from('user_locations')
      .upsert({
        user_id: user.id,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
        location_enabled: true,
        last_updated: new Date().toISOString(),
      }, {
        onConflict: 'user_id'
      });
  };

  const startWatchingPosition = (onError?: (error: GeolocationPositionError) => void) => {
    return navigator.geolocation.watchPosition(
      async (newPosition) => {
        try {
          await updateLocation(newPosition);
        } catch (error) {
          console.error('Error updating position:', error);
        }
      },
      onError || ((error) => console.error('Error watching position:', error)),
      { enableHighAccuracy: true }
    );
  };

  return {
    updateLocation,
    startWatchingPosition
  };
};