import { Alert, AlertDescription } from '@/components/ui/alert';
import { X, Users } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface SuggestionsHeaderProps {
  showMessage: boolean;
  setShowMessage: (show: boolean) => void;
}

export const SuggestionsHeader = ({ showMessage, setShowMessage }: SuggestionsHeaderProps) => {
  return (
    <div className="relative">
      <div className="sticky top-0 z-10 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-b py-4 md:py-6 px-4 md:px-6 flex flex-col items-center space-y-3">
        <h3 className="text-lg font-semibold text-hover-espresso tracking-tight">
          Suggested Connections
        </h3>
        <div className="flex items-center gap-2 text-sm text-muted-foreground">
          <Users className="h-4 w-4 flex-shrink-0" />
          <p className="text-center">Connect with people from your shared groups</p>
        </div>
      </div>

      {showMessage && (
        <div className="absolute inset-0 z-50 flex items-start justify-center pt-20 px-4 md:px-6">
          <Alert className="max-w-md animate-fade-in bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/80 shadow-lg border-hover-coffee/20">
            <AlertDescription className="flex flex-col space-y-4 p-3">
              <div className="flex items-start justify-between gap-4">
                <span className="text-sm leading-relaxed">
                  You have no requests at the moment, so we're going to suggest some great people for you to connect with!
                </span>
                <button 
                  onClick={() => setShowMessage(false)}
                  className="text-muted-foreground hover:text-foreground transition-colors flex-shrink-0"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
              <div className="flex justify-end">
                <Button 
                  variant="outline" 
                  size="sm"
                  onClick={() => setShowMessage(false)}
                  className="text-hover-coffee hover:text-hover-espresso hover:bg-hover-cream/50"
                >
                  Got it!
                </Button>
              </div>
            </AlertDescription>
          </Alert>
        </div>
      )}
    </div>
  );
};