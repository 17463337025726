import { useState } from "react";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useUserData } from "@/hooks/useUserData";

interface ConfirmationDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  offerId?: number;
  reward?: number;
  onSuccess?: () => void;
  // New props for general confirmation dialogs
  title?: string;
  description?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  variant?: "default" | "destructive";
  onConfirm?: () => void;
}

const ConfirmationDialog = ({ 
  open, 
  onOpenChange, 
  offerId, 
  reward,
  onSuccess,
  title,
  description,
  confirmLabel = "Submit Proof",
  cancelLabel = "Cancel",
  variant = "default",
  onConfirm
}: ConfirmationDialogProps) => {
  const [proofText, setProofText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { toast } = useToast();
  const { currentUserId } = useUserData();

  const handleSubmit = async () => {
    if (!currentUserId) {
      toast({
        title: "Authentication required",
        description: "Please sign in to submit proof",
        variant: "destructive",
      });
      return;
    }

    if (!proofText.trim() && !onConfirm) {
      toast({
        title: "Proof required",
        description: "Please provide proof of completion",
        variant: "destructive",
      });
      return;
    }

    setIsSubmitting(true);

    try {
      if (onConfirm) {
        await onConfirm();
      } else {
        const { error } = await supabase
          .from('bean_mining_confirmations')
          .insert({
            offer_id: offerId,
            user_id: currentUserId,
            proof_text: proofText,
            status: 'pending'
          });

        if (error) {
          if (error.code === '23505') { // Unique violation error code
            toast({
              title: "Already claimed",
              description: "You have already submitted a claim for this reward",
              variant: "destructive",
            });
          } else {
            throw error;
          }
        } else {
          toast({
            title: "Proof submitted",
            description: "Your proof has been submitted for review",
          });
          onSuccess?.();
          onOpenChange(false);
        }
      }
    } catch (error) {
      console.error('Error submitting proof:', error);
      toast({
        title: "Error",
        description: "Failed to submit proof. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className="bg-white sm:max-w-[425px]">
        <AlertDialogHeader>
          <AlertDialogTitle>
            {title || `Claim ${reward} Coffee Beans`}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {description || "Please provide proof that you've completed the required action to claim this reward."}
          </AlertDialogDescription>
        </AlertDialogHeader>
        {!onConfirm && (
          <div className="grid gap-4 py-4">
            <Textarea
              placeholder="Describe how you completed the task..."
              value={proofText}
              onChange={(e) => setProofText(e.target.value)}
              className="min-h-[100px]"
            />
          </div>
        )}
        <AlertDialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isSubmitting}
          >
            {cancelLabel}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className={variant === "destructive" ? "bg-red-500 hover:bg-red-600 text-white" : "bg-hover-coffee hover:bg-hover-espresso text-white"}
          >
            {confirmLabel}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default ConfirmationDialog;