import { useState } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Briefcase, Paintbrush, Megaphone } from "lucide-react";

const UserTypeToggle = () => {
  const [selectedType, setSelectedType] = useState<'business' | 'creator' | 'marketer'>('business');
  const { toast } = useToast();

  const handleTypeChange = (type: 'business' | 'creator' | 'marketer') => {
    setSelectedType(type);
    if (type !== 'business') {
      toast({
        title: "Coming Soon",
        description: `The ${type} portal is under development`,
        className: "backdrop-blur-sm bg-white/80",
      });
    }
  };

  return (
    <div className="flex items-center gap-1 bg-hover-foam p-1 rounded-lg mx-auto">
      <Button
        variant="ghost"
        size="sm"
        onClick={() => handleTypeChange('business')}
        className={`flex items-center gap-2 transition-all ${
          selectedType === 'business' 
            ? 'bg-white text-hover-coffee shadow-sm' 
            : ''
        }`}
      >
        <Briefcase className="h-4 w-4" />
        <span className="text-sm">Business</span>
      </Button>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => handleTypeChange('creator')}
        className={`flex items-center gap-2 transition-all ${
          selectedType === 'creator' 
            ? 'bg-white text-hover-coffee shadow-sm' 
            : ''
        }`}
      >
        <Paintbrush className="h-4 w-4" />
        <span className="text-sm">Creator</span>
      </Button>
      <Button
        variant="ghost"
        size="sm"
        onClick={() => handleTypeChange('marketer')}
        className={`flex items-center gap-2 transition-all ${
          selectedType === 'marketer' 
            ? 'bg-white text-hover-coffee shadow-sm' 
            : ''
        }`}
      >
        <Megaphone className="h-4 w-4" />
        <span className="text-sm">Marketer</span>
      </Button>
    </div>
  );
};

export default UserTypeToggle;