import { useState, useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";

export const useUserData = () => {
  const [currentUserId, setCurrentUserId] = useState<string | null>(null);

  useEffect(() => {
    const getCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (userData) {
        setCurrentUserId(userData.id);
      }
    };

    getCurrentUser();
  }, []);

  return { currentUserId };
};