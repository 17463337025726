import { useState } from "react";
import { Card } from "@/components/ui/card";
import { Users, CheckSquare, Ban } from "lucide-react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import UserTable from "./UserTable";
import { useUserRole } from "@/hooks/useUserRole";

const UserManagementCard = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { isAdmin } = useUserRole();

  if (!isAdmin) return null;

  return (
    <Card className="bg-white border border-hover-coffee/10 transition-all duration-300 hover:shadow-md">
      <Collapsible
        open={!isCollapsed}
        onOpenChange={(open) => setIsCollapsed(!open)}
      >
        <CollapsibleTrigger asChild>
          <div className="w-full flex items-center justify-between text-hover-coffee py-6 px-4 md:py-8 md:px-6 cursor-pointer hover:bg-hover-cream/50 transition-colors duration-200">
            <div className="flex items-center gap-2 md:gap-3">
              <div className="flex gap-2 md:gap-3">
                <Users className="h-5 w-5 transition-transform duration-200 hover:scale-110" />
                <CheckSquare className="h-5 w-5 transition-transform duration-200 hover:scale-110" />
                <Ban className="h-5 w-5 transition-transform duration-200 hover:scale-110" />
              </div>
              <span className="font-semibold text-base md:text-lg">User Management</span>
            </div>
            <span className="text-sm text-hover-coffee/60 hover:text-hover-coffee transition-colors duration-200">
              {isCollapsed ? "Show" : "Hide"}
            </span>
          </div>
        </CollapsibleTrigger>
        <CollapsibleContent className="animate-slide-up">
          <div className="px-4 md:px-6 pb-6">
            <div className="relative border border-hover-coffee/10 rounded-md bg-hover-cream/20 shadow-sm">
              <ScrollArea className="h-[400px] w-full">
                <div className="min-w-[800px]">
                  <UserTable />
                </div>
                <ScrollBar orientation="horizontal" />
                <ScrollBar orientation="vertical" />
              </ScrollArea>
            </div>
          </div>
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
};

export default UserManagementCard;