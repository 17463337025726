import { Link, useLocation } from "react-router-dom";
import { Calendar, Users, Mail, Home } from "lucide-react";
import { Button } from "@/components/ui/button";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";

const links = [
  { to: "/", label: "Home", icon: Home },
  { to: "/live-events", label: "Live Events", icon: Calendar },
  { to: "/partner", label: "Partner with Us", icon: Users },
  { to: "/contact", label: "Contact Us", icon: Mail },
];

interface NavLinksProps {
  dropdown?: boolean;
}

const NavLinks = ({ dropdown = false }: NavLinksProps) => {
  const location = useLocation();
  
  if (dropdown) {
    return (
      <>
        {links.map(({ to, label, icon: Icon }) => (
          <DropdownMenuItem key={to} asChild className="bg-white">
            <Link to={to} className="flex items-center gap-2 w-full">
              <Icon className="h-4 w-4" />
              {label}
            </Link>
          </DropdownMenuItem>
        ))}
      </>
    );
  }

  return (
    <>
      {links.map(({ to, label, icon: Icon }) => (
        <Link key={to} to={to}>
          <Button
            variant="ghost"
            className={`flex items-center gap-2 ${
              location.pathname === to ? "bg-hover-coffee/10 text-hover-coffee" : ""
            }`}
          >
            <Icon className="h-4 w-4" />
            {label}
          </Button>
        </Link>
      ))}
    </>
  );
};

export default NavLinks;