import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { supabase } from "@/integrations/supabase/client";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import CoffeeBeansHeader from "./coffee-beans/CoffeeBeansHeader";
import CoffeeBeansBalance from "./coffee-beans/CoffeeBeansBalance";
import BeansInfoCarousel from "./coffee-beans/BeansInfoCarousel";
import HowToEarnDialog from "./coffee-beans/HowToEarnDialog";
import BeansPurchaseSection from "./coffee-beans/BeansPurchaseSection";

const CoffeeBeansCard = () => {
  const [coffeeBeansCount, setCoffeeBeansCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const fetchCoffeeBeans = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: userData, error } = await supabase
        .from('users')
        .select('coffee_beans')
        .eq('email', user.email)
        .single();

      if (error) {
        console.error('Error fetching coffee beans:', error);
        return;
      }

      if (userData) {
        setCoffeeBeansCount(userData.coffee_beans);
      }
    };

    fetchCoffeeBeans();

    // Subscribe to realtime changes
    const channel = supabase
      .channel('coffee-beans-changes')
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'users',
        filter: `email=eq.${supabase.auth.getUser().then(({ data }) => data.user?.email)}`
      }, (payload: any) => {
        if (payload.new.coffee_beans !== undefined) {
          setCoffeeBeansCount(payload.new.coffee_beans);
        }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, []);

  return (
    <Card className="bg-white border border-hover-coffee/10">
      <CardHeader className="p-4">
        <Collapsible open={!isCollapsed} onOpenChange={(open) => setIsCollapsed(!open)}>
          <CollapsibleTrigger className="w-full">
            <CoffeeBeansHeader isCollapsed={isCollapsed} />
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="pt-4">
              <CoffeeBeansBalance coffeeBeansCount={coffeeBeansCount} />
              <BeansInfoCarousel />
              <HowToEarnDialog open={open} setOpen={setOpen} />
              <BeansPurchaseSection 
                coffeeBeansCount={coffeeBeansCount}
                updateBeansCount={setCoffeeBeansCount}
              />
            </div>
          </CollapsibleContent>
        </Collapsible>
      </CardHeader>
    </Card>
  );
};

export default CoffeeBeansCard;