import { useState } from "react";
import DirectChat from "../chat/DirectChat";
import DirectMessageList from "../chat/DirectMessageList";
import NetworkTabs from "../NetworkTabs";
import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { supabase } from "@/integrations/supabase/client";
import UserAvatar from "@/components/comments/UserAvatar";

interface NetworkMessagesCardProps {
  selectedUserId: string | null;
  setSelectedUserId: (id: string | null) => void;
  showNetwork: boolean;
  setShowNetwork: (show: boolean) => void;
  unreadMessages: Set<string>;
  showMarketplace: boolean;
}

interface SearchResult {
  id: string;
  name: string;
  company_name: string;
  verified: boolean;
}

const NetworkMessagesCard = ({
  selectedUserId,
  setSelectedUserId,
  showNetwork,
  setShowNetwork,
  unreadMessages,
  showMarketplace,
}: NetworkMessagesCardProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    
    if (!query.trim()) {
      setSearchResults([]);
      setShowDropdown(false);
      return;
    }

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: connections } = await supabase
        .from('user_connections')
        .select(`
          connected_user_id,
          users!user_connections_connected_user_id_fkey (
            id,
            name,
            company_name,
            verified
          )
        `)
        .eq('user_id', user.id);

      if (connections) {
        const formattedResults = connections
          .map(conn => ({
            id: conn.users?.id || '',
            name: conn.users?.name || '',
            company_name: conn.users?.company_name || '',
            verified: conn.users?.verified || false
          }))
          .filter(result => 
            result.name.toLowerCase().includes(query.toLowerCase()) ||
            result.company_name.toLowerCase().includes(query.toLowerCase())
          );
        
        setSearchResults(formattedResults);
        setShowDropdown(true);
      }
    } catch (error) {
      console.error('Error searching connections:', error);
    }
  };

  const handleSelectUser = (userId: string) => {
    setSelectedUserId(userId);
    setSearchQuery("");
    setSearchResults([]);
    setShowDropdown(false);
  };

  return (
    <div className="bg-white rounded-lg border border-hover-coffee/10">
      <div className="p-4">
        <div className="relative mb-4">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            type="text"
            placeholder="Search connections..."
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={() => setShowDropdown(true)}
            className="pl-8 w-full"
          />
          {showDropdown && searchResults.length > 0 && (
            <div className="absolute z-[100] w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200">
              <ul className="py-1">
                {searchResults.map((result) => (
                  <li
                    key={result.id}
                    onClick={() => handleSelectUser(result.id)}
                    className="px-4 py-2 hover:bg-hover-cream transition-colors cursor-pointer flex items-center gap-3"
                  >
                    <UserAvatar 
                      name={result.name}
                      size="sm"
                      isVerified={result.verified}
                    />
                    <div>
                      <p className="font-medium text-hover-espresso">{result.name}</p>
                      <p className="text-sm text-muted-foreground">{result.company_name}</p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className={`${selectedUserId ? 'h-full flex flex-col' : ''}`}>
          <NetworkTabs
            showNetwork={showNetwork}
            onTabChange={setShowNetwork}
            showMarketplace={showMarketplace}
            onMarketplaceChange={() => {}}
            unreadCount={unreadMessages.size}
            hideButtons={!!selectedUserId}
            showMessagesOnly
          />
          {selectedUserId ? (
            <DirectChat
              recipientId={selectedUserId}
              onBack={() => setSelectedUserId(null)}
            />
          ) : (
            <DirectMessageList
              onUserSelect={setSelectedUserId}
              unreadMessages={unreadMessages}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NetworkMessagesCard;