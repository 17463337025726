import { useState, useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { ConnectionStatus } from "../types";

export const useConnectionStatus = (userId: string) => {
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('none');

  const { data: currentUser } = useQuery({
    queryKey: ['current-user'],
    queryFn: async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return null;
      return user;
    }
  });

  const { data: connections } = useQuery({
    queryKey: ['user-connections', currentUser?.id],
    queryFn: async () => {
      if (!currentUser) return [];
      const { data, error } = await supabase
        .from('user_connections')
        .select('connected_user_id')
        .eq('user_id', currentUser.id);

      if (error) throw error;
      return data.map(connection => connection.connected_user_id);
    },
    enabled: !!currentUser
  });

  const { data: pendingRequests } = useQuery({
    queryKey: ['pending-connection-requests', currentUser?.id],
    queryFn: async () => {
      if (!currentUser) return [];
      const { data, error } = await supabase
        .from('connection_requests')
        .select('recipient_id, requester_id')
        .eq('status', 'pending');

      if (error) throw error;
      return data;
    },
    enabled: !!currentUser
  });

  useEffect(() => {
    const checkStatus = async () => {
      if (!currentUser) {
        setConnectionStatus('none');
        return;
      }

      if (connections?.includes(userId)) {
        setConnectionStatus('connected');
        return;
      }

      const hasPending = pendingRequests?.some(
        request => 
          (request.requester_id === currentUser.id && request.recipient_id === userId) ||
          (request.recipient_id === currentUser.id && request.requester_id === userId)
      );

      setConnectionStatus(hasPending ? 'pending' : 'none');
    };

    checkStatus();
  }, [currentUser, connections, pendingRequests, userId]);

  return { connectionStatus };
};