import React from "react";
import { Link } from "lucide-react";
import UserAvatar from "@/components/comments/UserAvatar";
import { Button } from "@/components/ui/button";
import { Check, X } from "lucide-react";

interface RequestDetailsProps {
  request: any;
  onAction: (requestId: number, status: 'accepted' | 'rejected') => void;
}

const RequestDetails = ({ request, onAction }: RequestDetailsProps) => {
  return (
    <div className="bg-white p-6 rounded-lg shadow-sm space-y-4 animate-fade-in">
      <div className="flex items-start gap-4">
        <UserAvatar 
          name={request?.requester?.name} 
          size="md"
        />
        <div className="flex-1">
          <h3 className="font-semibold">
            {request?.requester?.name}
          </h3>
          <p className="text-sm text-muted-foreground">
            {request?.requester?.company_name}
          </p>
        </div>
      </div>

      {request?.requester?.biography && (
        <p className="text-sm text-muted-foreground">
          {request?.requester?.biography}
        </p>
      )}

      {request?.requester?.linkedin_url && (
        <a
          href={request?.requester?.linkedin_url}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 text-sm text-blue-600 hover:underline"
        >
          <Link className="h-4 w-4" />
          LinkedIn Profile
        </a>
      )}

      <div className="flex justify-end gap-3 pt-4">
        <Button
          variant="outline"
          size="sm"
          onClick={() => onAction(request.id, 'rejected')}
          className="bg-white hover:bg-red-50 hover:text-red-600 hover:border-red-200 transition-colors duration-200"
        >
          <X className="h-4 w-4 mr-1" />
          Decline
        </Button>
        <Button
          size="sm"
          onClick={() => onAction(request.id, 'accepted')}
          className="bg-hover-accent text-white hover:bg-hover-coffee transition-colors duration-200"
        >
          <Check className="h-4 w-4 mr-1" />
          Accept
        </Button>
      </div>
    </div>
  );
};

export default RequestDetails;