import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface EmailFieldProps {
  email: string;
  setEmail: (value: string) => void;
  disabled?: boolean;
}

const EmailField = ({ email, setEmail, disabled }: EmailFieldProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="email">Email</Label>
      <Input
        id="email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        className="bg-white"
        disabled={disabled}
      />
    </div>
  );
};

export default EmailField;