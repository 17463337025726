import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";

export type ClaimStatus = 'unclaimed' | 'pending' | 'approved' | null;

export const useClaimStatus = (offerId: number, userId: string | null) => {
  const [claimStatus, setClaimStatus] = useState<ClaimStatus>(null);

  useEffect(() => {
    const checkClaimStatus = async () => {
      if (!userId) return;

      try {
        const { data, error } = await supabase
          .from('bean_mining_confirmations')
          .select('status')
          .eq('offer_id', offerId)
          .eq('user_id', userId)
          .maybeSingle();

        if (error) {
          console.error('Error checking claim status:', error);
          return;
        }

        if (data) {
          setClaimStatus(data.status as 'pending' | 'approved');
        }
      } catch (error) {
        console.error('Error in checkClaimStatus:', error);
      }
    };

    checkClaimStatus();

    // Subscribe to real-time updates
    const channel = supabase
      .channel(`claim-status-${offerId}-${userId}`)
      .on('postgres_changes', {
        event: 'UPDATE',
        schema: 'public',
        table: 'bean_mining_confirmations',
        filter: `offer_id=eq.${offerId}&user_id=eq.${userId}`
      }, (payload) => {
        setClaimStatus(payload.new.status as 'pending' | 'approved');
      })
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  }, [userId, offerId]);

  return claimStatus;
};