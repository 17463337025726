import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import UserHoverCard from "@/components/users/UserHoverCard";
import type { DirectMessage } from "./types";

interface MessageProps {
  message: DirectMessage;
}

const Message = ({ message }: MessageProps) => {
  const [isCurrentUser, setIsCurrentUser] = useState(false);

  useEffect(() => {
    const checkCurrentUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;
      
      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();
      
      if (userData) {
        setIsCurrentUser(userData.id === message.sender.id);
      }
    };

    checkCurrentUser();
  }, [message.sender.id]);

  return (
    <div className={`flex flex-col ${isCurrentUser ? 'items-end' : 'items-start'} mb-4 animate-fade-in`}>
      <div
        className={`
          max-w-[80%] rounded-2xl px-4 py-2 text-sm
          ${isCurrentUser 
            ? 'bg-hover-coffee text-white' 
            : 'bg-hover-cream text-hover-espresso'
          }
        `}
      >
        {message.sender && (
          <div className="mb-1">
            <UserHoverCard 
              userId={message.sender.id} 
              userName={message.sender.name}
              trigger={
                <span className={`font-bold cursor-pointer hover:underline text-sm ${
                  isCurrentUser ? 'text-white' : 'text-hover-espresso'
                }`}>
                  {message.sender.name}
                </span>
              }
            />
          </div>
        )}
        <p className="break-words mb-2">{message.content}</p>
        <div className="text-right">
          <span className={`text-xs ${isCurrentUser ? 'text-white/80' : 'text-hover-espresso/60'}`}>
            {new Date(message.created_at).toLocaleTimeString()}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Message;