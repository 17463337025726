import UserAvatar from "@/components/comments/UserAvatar";
import { Badge } from "@/components/ui/badge";
import { Card } from "@/components/ui/card";
import { PublicUserProfile } from "@/types/user";

interface ProfileHeaderProps {
  profile: PublicUserProfile;
}

const ProfileHeader = ({ profile }: ProfileHeaderProps) => {
  return (
    <Card className="p-6 mb-6">
      <div className="flex items-start gap-4">
        <UserAvatar 
          name={profile.name} 
          size="lg" 
          isVerified={profile.verified}
          imageUrl={profile.profile_image_url}
        />
        <div className="flex-1">
          <div className="flex items-center gap-2">
            <h1 className="text-2xl font-bold text-hover-espresso">
              {profile.name}
            </h1>
            {profile.verified && (
              <Badge variant="default" className="bg-hover-coffee">
                Verified
              </Badge>
            )}
          </div>
          <p className="text-lg text-muted-foreground">{profile.company_name}</p>
          {profile.biography && (
            <p className="mt-2 text-muted-foreground">{profile.biography}</p>
          )}
          {profile.linkedin_url && (
            <a
              href={profile.linkedin_url}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block mt-2 text-blue-600 hover:underline"
            >
              LinkedIn Profile
            </a>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProfileHeader;