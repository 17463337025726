import { useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";
import type { DirectMessage } from "../types";
import type { RealtimePostgresChangesPayload } from "@supabase/supabase-js";

interface MessagePayload {
  id: number;
  sender_id: string;
  recipient_id: string;
  content: string;
  read: boolean;
  created_at: string;
}

function isMessagePayload(payload: unknown): payload is MessagePayload {
  return (
    typeof payload === 'object' &&
    payload !== null &&
    'id' in payload &&
    'sender_id' in payload &&
    'recipient_id' in payload &&
    'content' in payload
  );
}

function isRelevantMessage(message: MessagePayload, currentUserId: string, recipientId: string): boolean {
  return (
    (message.sender_id === currentUserId && message.recipient_id === recipientId) ||
    (message.sender_id === recipientId && message.recipient_id === currentUserId)
  );
}

export const useRealtimeChat = (
  currentUserId: string | null,
  recipientId: string,
  onNewMessage: (message: DirectMessage) => void
) => {
  useEffect(() => {
    if (!currentUserId || !recipientId) {
      console.log("[useRealtimeChat] Missing required IDs:", { currentUserId, recipientId });
      return;
    }

    console.log("[useRealtimeChat] Setting up real-time chat subscription:", {
      currentUserId,
      recipientId,
    });

    // Create a unique channel name for this conversation
    const channelName = `chat:${[currentUserId, recipientId].sort().join(':')}`;

    const channel = supabase
      .channel(channelName)
      .on(
        'postgres_changes',
        {
          event: '*', // Listen to all events (INSERT, UPDATE, DELETE)
          schema: 'public',
          table: 'direct_messages'
        },
        async (payload: RealtimePostgresChangesPayload<MessagePayload>) => {
          console.log("[useRealtimeChat] Received payload:", {
            event: payload.eventType,
            new: payload.new,
            old: payload.old
          });

          // Validate payload
          if (!payload.new || !isMessagePayload(payload.new)) {
            console.error("[useRealtimeChat] Invalid payload structure:", payload);
            return;
          }

          // Check if message is relevant to this conversation
          if (!isRelevantMessage(payload.new, currentUserId, recipientId)) {
            console.log("[useRealtimeChat] Message not relevant to this conversation:", {
              messageIds: {
                sender: payload.new.sender_id,
                recipient: payload.new.recipient_id
              },
              conversationIds: {
                currentUser: currentUserId,
                recipient: recipientId
              }
            });
            return;
          }

          try {
            // Fetch complete message data including sender details
            const { data: messageData, error } = await supabase
              .from('direct_messages')
              .select(`
                *,
                sender:users!direct_messages_sender_id_fkey(
                  id,
                  name,
                  email,
                  company_name,
                  user_type,
                  profile_image_url,
                  created_at
                )
              `)
              .eq('id', payload.new.id)
              .single();

            if (error) {
              console.error("[useRealtimeChat] Error fetching message details:", {
                error,
                messageId: payload.new.id
              });
              return;
            }

            if (messageData) {
              console.log("[useRealtimeChat] Processing message:", {
                id: messageData.id,
                sender: messageData.sender.name,
                timestamp: messageData.created_at
              });
              onNewMessage(messageData as DirectMessage);
            }
          } catch (err) {
            console.error("[useRealtimeChat] Error processing message:", {
              error: err,
              messageId: payload.new.id
            });
          }
        }
      )
      .subscribe((status) => {
        console.log("[useRealtimeChat] Channel status:", {
          status,
          channel: channelName
        });
      });

    // Cleanup function
    return () => {
      console.log("[useRealtimeChat] Cleaning up subscription:", {
        channel: channelName
      });
      supabase.removeChannel(channel);
    };
  }, [currentUserId, recipientId, onNewMessage]);
};