import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import Registration from "@/components/sections/Registration";
import SignIn from "@/components/sections/SignIn";

const AuthButton = () => {
  const { toast } = useToast();
  const [session, setSession] = useState<any>(null);
  const [isSignInOpen, setIsSignInOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isValidatingSession, setIsValidatingSession] = useState(true);

  useEffect(() => {
    validateAndSetSession();
  }, []);

  const validateAndSetSession = async () => {
    try {
      const { data: { session }, error } = await supabase.auth.getSession();
      
      if (error) {
        throw error;
      }

      if (session) {
        // Verify the session is still valid by making a test query
        const { error: testError } = await supabase
          .from('users')
          .select('id')
          .limit(1)
          .single();

        if (testError?.code === 'PGRST301') {  // This is the code for invalid auth
          console.error('Session invalid:', testError);
          await cleanupSession();
          return;
        }
      }

      setSession(session);
    } catch (error) {
      console.error('Session validation error:', error);
      await cleanupSession();
    } finally {
      setIsValidatingSession(false);
    }
  };

  const cleanupSession = async () => {
    // Clear local session state first
    setSession(null);
    
    // Clear all Supabase-related items from localStorage
    for (const key of Object.keys(localStorage)) {
      if (key.startsWith('sb-')) {
        localStorage.removeItem(key);
      }
    }

    // Clear any specific session tokens
    localStorage.removeItem('supabase.auth.token');
    
    try {
      // Attempt to kill the session on Supabase's end
      await supabase.auth.signOut({ scope: 'local' });
    } catch (error) {
      console.error('Error during sign out:', error);
      // We ignore this error since we've already cleaned up locally
    }

    toast({
      title: "Session expired",
      description: "Please sign in again.",
      variant: "destructive",
    });
  };

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (_event, session) => {
      if (_event === 'SIGNED_OUT') {
        setSession(null);
      } else if (_event === 'SIGNED_IN' || _event === 'TOKEN_REFRESHED') {
        setSession(session);
      }
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      // Clear local state first
      setSession(null);
      
      // Clear all Supabase-related items from localStorage
      for (const key of Object.keys(localStorage)) {
        if (key.startsWith('sb-')) {
          localStorage.removeItem(key);
        }
      }
      
      // Attempt local signout only
      const { error } = await supabase.auth.signOut({ scope: 'local' });
      if (error) throw error;
      
      toast({
        title: "Logged out successfully",
      });
    } catch (error) {
      console.error("Error logging out:", error);
      toast({
        title: "Logged out",
        description: "Your local session has been cleared.",
      });
    }
  };

  if (isValidatingSession) {
    return null; // Don't show any auth buttons while validating
  }

  if (session) {
    return (
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button className="bg-hover-coffee hover:bg-hover-espresso text-white ml-2">
            Logout
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to logout?</AlertDialogTitle>
            <AlertDialogDescription>
              You will need to login again to access your account.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleLogout}>Logout</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  }

  return (
    <div className="flex gap-2">
      <Dialog open={isSignInOpen} onOpenChange={setIsSignInOpen}>
        <DialogTrigger asChild>
          <Button variant="outline" className="border-hover-coffee text-hover-coffee hover:bg-hover-coffee hover:text-white">
            Sign In
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md backdrop-blur-sm bg-transparent border-none shadow-none">
          <SignIn onSuccess={() => setIsSignInOpen(false)} />
        </DialogContent>
      </Dialog>

      <Dialog open={isRegisterOpen} onOpenChange={setIsRegisterOpen}>
        <DialogTrigger asChild>
          <Button className="bg-hover-coffee hover:bg-hover-espresso text-white">
            Join the Community
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-md backdrop-blur-sm bg-transparent border-none shadow-none">
          <Registration onSuccess={() => setIsRegisterOpen(false)} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AuthButton;