import { Search } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { CheckSquare, Ban } from "lucide-react";

interface UserTableHeaderProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  selectedUsers: string[];
  onVerifyUsers: () => void;
  onBanUsers: () => void;
}

const UserTableHeader = ({
  searchTerm,
  setSearchTerm,
  selectedUsers,
  onVerifyUsers,
  onBanUsers,
}: UserTableHeaderProps) => {
  return (
    <div className="flex items-center justify-between px-4 py-4">
      <div className="flex items-center gap-4">
        <div className="relative w-64">
          <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
          <Input
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="pl-8"
          />
        </div>
        <div className="flex gap-2">
          <Button
            size="sm"
            variant="outline"
            onClick={onVerifyUsers}
            disabled={selectedUsers.length === 0}
            className="flex items-center gap-2"
          >
            <CheckSquare className="h-4 w-4" />
            Verify Selected
          </Button>
          <Button
            size="sm"
            variant="outline"
            onClick={onBanUsers}
            disabled={selectedUsers.length === 0}
            className="flex items-center gap-2"
          >
            <Ban className="h-4 w-4" />
            Ban Selected
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UserTableHeader;