import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

interface UserTypeSelectProps {
  value: string;
  onValueChange: (value: string) => void;
  disabled?: boolean;
}

export const UserTypeSelect = ({ value, onValueChange, disabled }: UserTypeSelectProps) => {
  return (
    <div className="space-y-2">
      <Label htmlFor="userType">User Type</Label>
      <Select value={value} onValueChange={onValueChange} required disabled={disabled}>
        <SelectTrigger className="bg-white">
          <SelectValue placeholder="Select user type" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="vc">VC</SelectItem>
          <SelectItem value="vb">VB</SelectItem>
          <SelectItem value="accelerator">Accelerator</SelectItem>
          <SelectItem value="startup">Startup</SelectItem>
          <SelectItem value="service">Service Business</SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};