import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { PublicUserProfile } from "@/types/user";
import { MarketplaceRequest } from "@/types/marketplace";
import ProfileHeader from "@/components/profile/ProfileHeader";
import RequestList from "@/components/profile/RequestList";

const PublicProfile = () => {
  const { userId } = useParams();
  const { toast } = useToast();
  const [profile, setProfile] = useState<PublicUserProfile | null>(null);
  const [requests, setRequests] = useState<MarketplaceRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [openComments, setOpenComments] = useState<number[]>([]);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data: userData, error: userError } = await supabase
          .from('users')
          .select('*')
          .eq('id', userId)
          .single();

        if (userError) throw userError;

        const { data: requestsData, error: requestsError } = await supabase
          .from('marketplace_requests')
          .select('*')
          .eq('user_id', userId)
          .eq('status', 'active')
          .order('created_at', { ascending: false });

        if (requestsError) throw requestsError;

        setProfile(userData);
        setRequests(requestsData || []);
      } catch (error) {
        console.error('Error fetching profile:', error);
        toast({
          title: "Error",
          description: "Failed to load profile",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchProfile();
    }
  }, [userId, toast]);

  const handleCommentsToggle = (requestId: number) => {
    setOpenComments(prev =>
      prev.includes(requestId)
        ? prev.filter(id => id !== requestId)
        : [...prev, requestId]
    );
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-hover-cream pt-16 md:pt-24">
        <div className="container max-w-4xl mx-auto px-4">
          <div className="animate-pulse space-y-4">
            <div className="h-48 bg-white rounded-lg"></div>
            <div className="h-32 bg-white rounded-lg"></div>
          </div>
        </div>
      </div>
    );
  }

  if (!profile) {
    return (
      <div className="min-h-screen bg-hover-cream pt-16 md:pt-24">
        <div className="container max-w-4xl mx-auto px-4">
          <div className="text-center text-hover-espresso">
            Profile not found
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-hover-cream pt-16 md:pt-24 pb-16">
      <div className="container max-w-4xl mx-auto px-4">
        <ProfileHeader profile={profile} />
        <RequestList
          requests={requests}
          openComments={openComments}
          onCommentsToggle={handleCommentsToggle}
        />
      </div>
    </div>
  );
};

export default PublicProfile;