import { Coffee } from "lucide-react";

interface OfferRewardBadgeProps {
  reward: number;
}

const OfferRewardBadge = ({ reward }: OfferRewardBadgeProps) => {
  return (
    <div className="absolute top-0 right-0 bg-gradient-to-bl from-amber-100 to-amber-50/50 p-3 rounded-bl-xl">
      <div className="flex items-center gap-1 text-hover-coffee">
        <Coffee className="w-4 h-4" />
        <span className="font-semibold">{reward}</span>
      </div>
    </div>
  );
};

export default OfferRewardBadge;