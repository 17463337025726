import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from './use-toast';

export const useConnectionRequests = () => {
  const [requests, setRequests] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { toast } = useToast();

  const fetchRequests = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('connection_requests')
        .select(`
          id,
          status,
          created_at,
          requester:requester_id (
            id,
            name,
            company_name,
            biography,
            linkedin_url
          )
        `)
        .eq('recipient_id', user.id)
        .eq('status', 'pending')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setRequests(data || []);
    } catch (error: any) {
      console.error('Error fetching requests:', error);
      toast({
        title: "Failed to load connection requests",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  const nextRequest = () => {
    if (currentIndex < requests.length - 1) {
      setCurrentIndex(prev => prev + 1);
    }
  };

  const previousRequest = () => {
    if (currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const handleRequestAction = async (requestId: number, status: 'accepted' | 'rejected') => {
    try {
      const { error } = await supabase
        .from('connection_requests')
        .update({ status })
        .eq('id', requestId);

      if (error) throw error;

      toast({
        title: `Request ${status}`,
        description: status === 'accepted' ? "You are now connected!" : "Request declined",
      });

      // Remove the request from the local state
      setRequests(prev => prev.filter(r => r.id !== requestId));
      if (currentIndex >= requests.length - 1) {
        setCurrentIndex(Math.max(0, requests.length - 2));
      }
    } catch (error: any) {
      console.error('Error updating request:', error);
      toast({
        title: "Failed to update request",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  return {
    requests,
    currentIndex,
    nextRequest,
    previousRequest,
    handleRequestAction,
  };
};