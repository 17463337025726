import { ChevronLeft } from "lucide-react";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import UserHoverCard from "@/components/users/UserHoverCard";

interface ChatHeaderProps {
  recipientId: string;
  recipientName: string;
  companyName: string;
  onBack: () => void;
}

const ChatHeader = ({ recipientName, recipientId, companyName, onBack }: ChatHeaderProps) => {
  // Get first letter of name for avatar fallback, with a default value
  const nameInitial = recipientName ? recipientName.charAt(0).toUpperCase() : '?';

  return (
    <div className="border-b p-4 flex items-center gap-3">
      <button
        onClick={onBack}
        className="hover:bg-gray-100 p-2 rounded-full transition-colors"
      >
        <ChevronLeft className="h-5 w-5" />
      </button>

      <Avatar>
        <AvatarFallback>{nameInitial}</AvatarFallback>
      </Avatar>

      <div className="flex-1 min-w-0">
        <UserHoverCard
          userId={recipientId}
          userName={recipientName}
          trigger={
            <div className="font-semibold hover:underline cursor-pointer truncate">
              {recipientName || 'Loading...'}
            </div>
          }
        />
        <div className="text-sm text-muted-foreground truncate">
          {companyName || 'Loading...'}
        </div>
      </div>
    </div>
  );
};

export default ChatHeader;