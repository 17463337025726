import { useState } from "react";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import UserAvatar from "@/components/comments/UserAvatar";
import { Link, MessageCircle, Check, X } from "lucide-react";

interface UserHoverCardProps {
  userId: string;
  userName: string;
  trigger?: React.ReactNode;
}

const UserHoverCard = ({ userId, userName, trigger }: UserHoverCardProps) => {
  const [userProfile, setUserProfile] = useState<any>(null);
  const [connectionStatus, setConnectionStatus] = useState<string | null>(null);
  const { toast } = useToast();

  const fetchUserProfile = async () => {
    try {
      const { data: profile } = await supabase
        .from('users')
        .select('*')
        .eq('id', userId)
        .single();

      if (profile) {
        setUserProfile(profile);
        
        // Check connection status
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const { data: userData } = await supabase
            .from('users')
            .select('id')
            .eq('email', user.email)
            .single();

          if (userData) {
            const { data: connectionRequest } = await supabase
              .from('connection_requests')
              .select('status')
              .or(`requester_id.eq.${userData.id},recipient_id.eq.${userData.id}`)
              .or(`requester_id.eq.${userId},recipient_id.eq.${userId}`)
              .maybeSingle();

            setConnectionStatus(connectionRequest?.status || null);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user profile:', error);
    }
  };

  const handleConnect = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        toast({
          title: "Please login to connect with users",
          variant: "destructive",
        });
        return;
      }

      const { data: userData } = await supabase
        .from('users')
        .select('id')
        .eq('email', user.email)
        .single();

      if (!userData) {
        toast({
          title: "Error sending connection request",
          description: "Could not find your user profile",
          variant: "destructive",
        });
        return;
      }

      if (userData.id === userId) {
        toast({
          title: "Cannot connect with yourself",
          variant: "destructive",
        });
        return;
      }

      const { error } = await supabase
        .from('connection_requests')
        .insert({
          requester_id: userData.id,
          recipient_id: userId,
        });

      if (error) throw error;

      toast({
        title: "Connection request sent!",
        description: "We'll notify you when they respond.",
      });
      setConnectionStatus('pending');
    } catch (error: any) {
      toast({
        title: "Error sending connection request",
        description: error.message,
        variant: "destructive",
      });
    }
  };

  return (
    <HoverCard onOpenChange={(open) => open && fetchUserProfile()}>
      <HoverCardTrigger asChild>
        {trigger || <span className="cursor-pointer text-hover-coffee hover:underline">{userName}</span>}
      </HoverCardTrigger>
      <HoverCardContent className="w-80 bg-white/100">
        {userProfile && (
          <div className="flex flex-col gap-4">
            <div className="flex items-start gap-4">
              <UserAvatar name={userProfile.name} />
              <div className="space-y-1">
                <h4 className="text-sm font-semibold">{userProfile.name}</h4>
                <p className="text-sm text-muted-foreground">{userProfile.company_name}</p>
              </div>
            </div>
            
            {userProfile.biography && (
              <p className="text-sm text-muted-foreground">
                {userProfile.biography.length > 100 
                  ? `${userProfile.biography.substring(0, 100)}...` 
                  : userProfile.biography}
              </p>
            )}

            <div className="flex gap-2">
              {userProfile.linkedin_url && (
                <Button variant="outline" size="sm" asChild>
                  <a href={userProfile.linkedin_url} target="_blank" rel="noopener noreferrer">
                    <Link className="h-4 w-4 mr-2" />
                    LinkedIn
                  </a>
                </Button>
              )}
              
              {connectionStatus === null && (
                <Button size="sm" onClick={handleConnect}>
                  <MessageCircle className="h-4 w-4 mr-2" />
                  Connect
                </Button>
              )}
              {connectionStatus === 'pending' && (
                <Button size="sm" variant="outline" disabled>
                  <MessageCircle className="h-4 w-4 mr-2" />
                  Pending
                </Button>
              )}
              {connectionStatus === 'accepted' && (
                <Button size="sm" variant="outline" disabled>
                  <Check className="h-4 w-4 mr-2" />
                  Connected
                </Button>
              )}
              {connectionStatus === 'rejected' && (
                <Button size="sm" variant="outline" disabled>
                  <X className="h-4 w-4 mr-2" />
                  Rejected
                </Button>
              )}
            </div>
          </div>
        )}
      </HoverCardContent>
    </HoverCard>
  );
};

export default UserHoverCard;
