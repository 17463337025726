import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';

export const useLocationPermission = () => {
  const [locationEnabled, setLocationEnabled] = useState(false);

  useEffect(() => {
    const checkLocationPermission = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data: locationData } = await supabase
        .from('user_locations')
        .select('location_enabled')
        .eq('user_id', user.id)
        .maybeSingle();

      setLocationEnabled(!!locationData?.location_enabled);
    };

    checkLocationPermission();
  }, []);

  return { locationEnabled, setLocationEnabled };
};