import { motion, AnimatePresence } from "framer-motion";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Avatar } from "@/components/ui/avatar";
import { UserPlus, Link as LinkIcon } from "lucide-react";
import { NearbyUser } from "./types";

interface NearbyUserListProps {
  users: NearbyUser[];
  onConnect: (userId: string) => void;
}

export const NearbyUserList = ({ users, onConnect }: NearbyUserListProps) => {
  return (
    <AnimatePresence mode="popLayout">
      <div className="space-y-4 overflow-auto max-h-[300px] pr-2 scrollbar-thin scrollbar-thumb-hover-coffee/50 scrollbar-track-transparent">
        {users.map((user) => (
          <motion.div
            key={user.user_id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="group"
          >
            <Card className="bg-white/80 backdrop-blur-sm hover:bg-white/90 
                           transition-all duration-300 hover:shadow-md border-hover-coffee/5 
                           hover:border-hover-coffee/10">
              <div className="p-4 flex flex-col h-full">
                <div className="flex items-start gap-4 mb-3">
                  <Avatar className="h-12 w-12 border-2 border-hover-cream shrink-0 ring-2 ring-hover-coffee/5">
                    <img
                      src={user.profile_image_url || '/placeholder.svg'}
                      alt={user.name}
                      className="object-cover"
                    />
                  </Avatar>
                  <div className="flex-1 min-w-0">
                    <h4 className="font-medium text-hover-espresso truncate mb-1">
                      {user.name}
                    </h4>
                    <p className="text-sm text-hover-coffee/70 truncate mb-1">
                      {user.company_name}
                    </p>
                    <p className="text-xs text-hover-coffee/60">
                      {user.distance_km.toFixed(1)}km away
                    </p>
                    {user.linkedin_url && (
                      <a
                        href={user.linkedin_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center gap-1 text-xs text-blue-600 hover:text-blue-700 mt-1"
                      >
                        <LinkIcon className="h-3 w-3" />
                        LinkedIn Profile
                      </a>
                    )}
                  </div>
                </div>
                
                <Button
                  size="sm"
                  variant="outline"
                  className="w-full mt-auto bg-hover-cream text-hover-espresso 
                           border-hover-coffee/20 hover:bg-hover-coffee 
                           hover:text-white transition-all duration-200"
                  onClick={() => onConnect(user.user_id)}
                >
                  <UserPlus className="h-4 w-4 mr-2" />
                  Connect
                </Button>
              </div>
            </Card>
          </motion.div>
        ))}
      </div>
    </AnimatePresence>
  );
};