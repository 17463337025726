import { Button } from '@/components/ui/button';
import { MapPin } from 'lucide-react';

interface NearbyHeaderProps {
  onDisableLocation: () => void;
}

export const NearbyHeader = ({ onDisableLocation }: NearbyHeaderProps) => {
  return (
    <div className="flex flex-col items-center gap-6 mb-6 pb-4 border-b border-hover-coffee/10">
      <div className="text-center w-full">
        <h3 className="text-lg md:text-xl font-semibold text-hover-espresso 
                       tracking-tight mb-2">
          Nearby Professionals
        </h3>
        <p className="text-sm text-hover-coffee/70">
          Within 5km of your location
        </p>
      </div>
      <Button 
        variant="outline" 
        size="sm" 
        onClick={onDisableLocation}
        className="shrink-0 min-w-[140px] border-hover-coffee/20 text-hover-coffee 
                   hover:bg-hover-cream hover:text-hover-espresso transition-all 
                   duration-200 group"
      >
        <MapPin className="h-4 w-4 mr-2 group-hover:scale-110 transition-transform" />
        Disable Location
      </Button>
    </div>
  );
};