import { Users, Network, ArrowRight } from "lucide-react";

interface EmptyConversationStateProps {
  onStartExploring: () => void;
}

const EmptyConversationState = ({ onStartExploring }: EmptyConversationStateProps) => {
  return (
    <div className="flex flex-col h-full items-center justify-center p-8 space-y-6 bg-gradient-to-b from-slate-50 to-slate-100">
      <div className="relative w-full max-w-xs">
        <div className="absolute -left-8 -top-8 animate-[float_3s_ease-in-out_infinite]">
          <Users className="h-8 w-8 text-slate-600 opacity-40 transform rotate-[-15deg]" />
        </div>
        <div className="absolute -right-8 -bottom-8 animate-[float_3s_ease-in-out_infinite_0.5s]">
          <Network className="h-8 w-8 text-slate-600 opacity-40 transform rotate-[15deg]" />
        </div>
        <h2 className="text-2xl font-bold text-slate-800 text-center animate-fade-in">
          Build Your Network
        </h2>
      </div>
      
      <div className="max-w-xs text-center space-y-3 animate-fade-in" style={{ animationDelay: "0.2s" }}>
        <p className="text-slate-600">
          Join groups that align with your interests and connect with like-minded professionals
        </p>
        <button 
          onClick={onStartExploring}
          className="flex items-center justify-center space-x-2 text-slate-500 hover:text-slate-700 transition-colors"
        >
          <span className="text-sm">Start exploring</span>
          <ArrowRight className="h-4 w-4 animate-[bounce_2s_ease-in-out_infinite]" />
        </button>
      </div>
      
      <div className="relative w-full max-w-xs h-1 mt-6 overflow-hidden rounded-full animate-fade-in" style={{ animationDelay: "0.4s" }}>
        <div className="absolute inset-0 bg-gradient-to-r from-slate-200 via-slate-400 to-slate-200 opacity-20"></div>
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-slate-400 to-transparent animate-[shimmer_2s_linear_infinite]"></div>
      </div>
    </div>
  );
};

export default EmptyConversationState;