interface RequestContentProps {
  description: string;
  title: string;
}

const RequestContent = ({ description, title }: RequestContentProps) => {
  return (
    <div className="space-y-2.5">
      <p className="text-sm text-gray-600 leading-relaxed">
        {description}
      </p>
      <p className="text-xs text-hover-coffee/70 font-medium">
        {title}
      </p>
    </div>
  );
};

export default RequestContent;