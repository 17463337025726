import { MessageSquare, ShoppingBag } from "lucide-react";
import RequestCountBadge from "./RequestCountBadge";

interface NetworkTabsProps {
  showNetwork: boolean;
  onTabChange: (show: boolean) => void;
  showMarketplace: boolean;
  onMarketplaceChange: (show: boolean) => void;
  unreadCount?: number;
  hideButtons?: boolean;
  showMessagesOnly?: boolean;
}

const NetworkTabs = ({ 
  showNetwork, 
  onTabChange, 
  showMarketplace, 
  onMarketplaceChange,
  unreadCount = 0,
  hideButtons = false,
  showMessagesOnly = false
}: NetworkTabsProps) => {
  if (hideButtons) return null;

  return (
    <div className="flex mb-4">
      <button
        onClick={() => {
          onTabChange(true);
          onMarketplaceChange(false);
        }}
        className={`relative flex items-center gap-2 px-4 py-2 text-sm font-medium transition-colors rounded-full mr-2 ${
          showNetwork && !showMarketplace
            ? "bg-hover-coffee text-white"
            : "text-muted-foreground hover:bg-hover-coffee/10"
        }`}
      >
        <MessageSquare className="h-4 w-4" />
        <span>Messages</span>
        {unreadCount > 0 && (
          <RequestCountBadge count={unreadCount} />
        )}
      </button>
      {!showMessagesOnly && (
        <button
          onClick={() => {
            onTabChange(true);
            onMarketplaceChange(true);
          }}
          className={`flex items-center gap-2 px-4 py-2 text-sm font-medium transition-colors rounded-full ${
            showNetwork && showMarketplace
              ? "bg-hover-coffee text-white"
              : "text-muted-foreground hover:bg-hover-coffee/10"
          }`}
        >
          <ShoppingBag className="h-4 w-4" />
          <span>Marketplace</span>
        </button>
      )}
    </div>
  );
};

export default NetworkTabs;