import { Card, CardContent } from "@/components/ui/card";
import GroupSearch from "@/components/groups/GroupSearch";
import GroupList from "@/components/groups/GroupList";
import ChatContainer from "./conversations/ChatContainer";
import { useRef, useState, useEffect } from "react";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { Users2, ChevronDown, ChevronUp } from "lucide-react";

interface ConversationsCardProps {
  groups: any[];
  selectedGroupId: number | null;
  setSelectedGroupId: (id: number | null) => void;
  unreadGroups: Set<number>;
  messages: any[];
  loading: boolean;
  hasMore: boolean;
  loadMoreMessages: () => void;
  onGroupJoined: () => void;
  isNetworkCollapsed: boolean;
}

const ConversationsCard = ({
  groups,
  selectedGroupId,
  setSelectedGroupId,
  unreadGroups,
  messages,
  loading,
  hasMore,
  loadMoreMessages,
  onGroupJoined,
  isNetworkCollapsed,
}: ConversationsCardProps) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (!isNetworkCollapsed) {
      setIsCollapsed(true);
    }
  }, [isNetworkCollapsed]);

  const focusSearch = () => {
    searchInputRef.current?.focus();
  };

  return (
    <Card className="bg-white border border-hover-coffee/10">
      <CardContent className="p-0">
        <Collapsible open={!isCollapsed} onOpenChange={(open) => setIsCollapsed(!open)}>
          <CollapsibleTrigger className="w-full">
            <div className="flex items-center justify-between py-6 px-6 cursor-pointer">
              <div className="flex items-center gap-3">
                <Users2 className="h-5 w-5 text-hover-coffee" />
                <span className="font-semibold text-lg text-hover-espresso">
                  {isCollapsed ? "Communities" : "Conversations"}
                </span>
              </div>
              {isCollapsed ? (
                <ChevronDown className="h-5 w-5 text-hover-coffee" />
              ) : (
                <ChevronUp className="h-5 w-5 text-hover-coffee" />
              )}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent>
            <div className="space-y-4 pb-4">
              <div className="px-6 space-y-4">
                <GroupSearch 
                  onSearch={(query) => console.log('Search:', query)} 
                  onGroupJoined={onGroupJoined}
                  ref={searchInputRef}
                />
                <GroupList
                  groups={groups}
                  selectedGroupId={selectedGroupId}
                  onGroupSelect={setSelectedGroupId}
                  unreadGroups={unreadGroups}
                />
              </div>
              <ChatContainer
                selectedGroupId={selectedGroupId}
                messages={messages}
                loading={loading}
                hasMore={hasMore}
                onLoadMore={loadMoreMessages}
                onStartExploring={focusSearch}
              />
            </div>
          </CollapsibleContent>
        </Collapsible>
      </CardContent>
    </Card>
  );
};

export default ConversationsCard;