import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export const useNearbyConnections = () => {
  const { toast } = useToast();

  const handleConnect = async (userId: string) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // First check if a connection request already exists
      const { data: existingRequest } = await supabase
        .from('connection_requests')
        .select('id, status')
        .or(`and(requester_id.eq.${user.id},recipient_id.eq.${userId}),and(requester_id.eq.${userId},recipient_id.eq.${user.id})`)
        .maybeSingle();

      if (existingRequest) {
        if (existingRequest.status === 'pending') {
          toast({
            title: "Request already exists",
            description: "You already have a pending connection request with this user.",
          });
        } else if (existingRequest.status === 'accepted') {
          toast({
            title: "Already connected",
            description: "You are already connected with this user.",
          });
        }
        return;
      }

      // If no existing request, create a new one
      const { error } = await supabase
        .from('connection_requests')
        .insert({
          requester_id: user.id,
          recipient_id: userId,
        });

      if (error) throw error;

      toast({
        title: "Connection request sent!",
        description: "We'll notify you when they respond",
      });
    } catch (error) {
      console.error('Error sending connection request:', error);
      toast({
        title: "Error sending request",
        description: "Please try again later",
        variant: "destructive",
      });
    }
  };

  return { handleConnect };
};