import { ChevronDown, ChevronUp } from "lucide-react";
import { CardTitle } from "@/components/ui/card";

interface CoffeeBeansHeaderProps {
  isCollapsed: boolean;
}

const CoffeeBeansHeader = ({ isCollapsed }: CoffeeBeansHeaderProps) => {
  return (
    <div className="flex items-center justify-between space-y-0 pb-2">
      <CardTitle className="text-lg font-medium text-hover-espresso flex items-center gap-2">
        {isCollapsed ? "What the beans?" : "Coffee Beans"}
        {isCollapsed ? <ChevronDown className="h-4 w-4" /> : <ChevronUp className="h-4 w-4" />}
      </CardTitle>
      <img 
        src="/lovable-uploads/e82b1634-13ba-4bf6-bea1-b619790e086a.png" 
        alt="Coffee Bean"
        className="h-10 w-10 text-hover-coffee rotate-12"
      />
    </div>
  );
};

export default CoffeeBeansHeader;