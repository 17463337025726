import { useEffect, useRef, useState } from "react";
import Message from "./Message";
import { useMessages } from "./context/MessageContext";
import { Button } from "@/components/ui/button";
import { Loader2 } from "lucide-react";

interface MessageListProps {
  hasMore: boolean;
  loading: boolean;
  onLoadMore: () => void;
}

const MessageList = ({ hasMore, loading, onLoadMore }: MessageListProps) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const lastMessageRef = useRef<HTMLDivElement>(null);
  const isInitialLoadRef = useRef(true);
  const [showLoadMore, setShowLoadMore] = useState(false);
  const { messages } = useMessages();

  // Function to scroll to bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  // Effect for handling scrolling on messages update
  useEffect(() => {
    if (!chatContainerRef.current) return;

    const container = chatContainerRef.current;
    const isNearBottom = container.scrollHeight - container.scrollTop - container.clientHeight < 100;

    console.log("[MessageList] Messages updated:", { 
      count: messages.length,
      isNearBottom,
      isInitialLoad: isInitialLoadRef.current,
      lastMessage: messages[messages.length - 1],
      scrollHeight: container.scrollHeight,
      scrollTop: container.scrollTop,
      clientHeight: container.clientHeight
    });

    // Always scroll to bottom on initial load or new message
    if (isInitialLoadRef.current || isNearBottom) {
      requestAnimationFrame(scrollToBottom);
      isInitialLoadRef.current = false;
    }
  }, [messages]);

  // Effect for handling scrolling when chat is opened from another card
  useEffect(() => {
    const timeoutId = setTimeout(scrollToBottom, 100);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const container = e.currentTarget;
    const isAtTop = container.scrollTop <= 50;
    
    if (hasMore) {
      setShowLoadMore(isAtTop);
    }
  };

  if (loading && messages.length === 0) {
    return (
      <div className="flex items-center justify-center h-full text-muted-foreground">
        <Loader2 className="h-6 w-6 animate-spin mr-2" />
        Loading messages...
      </div>
    );
  }

  return (
    <div className="flex flex-col h-full max-h-[calc(100vh-12rem)]">
      {hasMore && (
        <div 
          className={`p-2 flex justify-center sticky top-0 bg-white/95 backdrop-blur-sm z-10 border-b transition-opacity duration-200 ${
            showLoadMore ? 'opacity-100' : 'opacity-0 pointer-events-none'
          }`}
        >
          <Button
            variant="outline"
            onClick={onLoadMore}
            disabled={loading}
            className="text-hover-espresso hover:text-hover-espresso/80"
          >
            {loading ? (
              <div className="flex items-center gap-2">
                <Loader2 className="h-4 w-4 animate-spin" />
                Loading earlier messages...
              </div>
            ) : (
              "Load Earlier Messages"
            )}
          </Button>
        </div>
      )}
      <div
        ref={chatContainerRef}
        onScroll={handleScroll}
        className="flex-1 overflow-y-auto px-4 py-2 min-h-0 max-h-[calc(100vh-16rem)] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent scroll-smooth"
      >
        {messages.length > 0 ? (
          messages.map((message, index) => (
            <div
              key={`${message.id}-${index}`}
              ref={index === messages.length - 1 ? lastMessageRef : null}
              className="mb-4 animate-fade-in"
            >
              <Message message={message} />
            </div>
          ))
        ) : (
          <div className="flex items-center justify-center h-full text-muted-foreground">
            No messages yet. Start a conversation!
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageList;