import { useRef } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Send } from "lucide-react";

interface MessageInputProps {
  onSendMessage: (content: string) => void;
}

const MessageInput = ({ onSendMessage }: MessageInputProps) => {
  const messageRef = useRef<HTMLTextAreaElement>(null);

  const handleSend = () => {
    if (!messageRef.current?.value.trim()) return;
    onSendMessage(messageRef.current.value.trim());
    messageRef.current.value = "";
  };

  return (
    <div className="border-t border-slate-200 p-4 bg-white/50 backdrop-blur-sm">
      <div className="flex gap-2">
        <Textarea
          ref={messageRef}
          placeholder="Type your message..."
          className="min-h-[60px] bg-white border-slate-200 focus:border-slate-400 focus:ring-slate-400"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <Button 
          onClick={handleSend}
          className="bg-hover-coffee hover:bg-hover-espresso text-white"
        >
          <Send className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default MessageInput;